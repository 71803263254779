import {put, takeEvery} from "redux-saga/effects";
import {
    REQUEST_TASK_ACTIONS,
    REQUEST_TASK_ACTIONS_ACTION,
    REQUEST_TASK_HISTORY,
    REQUEST_TASK_HISTORY_ACTION
} from "../types";
import {setTaskActions, setTaskActionsLoading, setTaskHistoryList, setTaskHistoryListLoading} from "./actions";
import {ResponseGenerator} from "../../types";
import {TGetHistoryList, TGetTaskActions} from "../types.data";
import {instance} from "../../../../store/api";
import {checkError, isError} from "../../../../store/api/helpers";

export function* applicationsWatcher() {
    yield takeEvery(REQUEST_TASK_HISTORY, taskHistoryWorker)
    yield takeEvery(REQUEST_TASK_ACTIONS, taskActionWorker)
}

function* taskHistoryWorker(action: REQUEST_TASK_HISTORY_ACTION) {
    yield put(setTaskHistoryListLoading(true))
    try {
        const res: ResponseGenerator = yield taskHistoryAsync(action.payload).next().value
        yield put(setTaskHistoryList({
            history: res.data?.data?.value,
            count:res.data?.data?.count
        }))
    } catch (e) {
        isError(e)
        checkError(e)
        
    }
    yield put(setTaskHistoryListLoading(false))
}

function* taskHistoryAsync(body: TGetHistoryList) {
    yield instance().close().getTaskHistory(body)
}


function* taskActionWorker(action: REQUEST_TASK_ACTIONS_ACTION) {
    yield put(setTaskActionsLoading(true))
    try {
        const res: ResponseGenerator = yield taskActionsAsync(action.payload).next().value
        yield put(setTaskActions({
            count: res.data?.data?.count,
            list: res.data?.data?.value
        }))
    } catch (e) {
        isError(e)
        checkError(e)
        
    }
    yield put(setTaskActionsLoading(false))
}

function* taskActionsAsync(body: TGetTaskActions) {
    yield instance().close().getTaskActions(body)
}