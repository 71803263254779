import {makeAutoObservable, runInAction} from "mobx";
import {instance} from "../api";
import {INotification, INotificationList} from "./types";
import {checkError, isError} from "../api/helpers";

class Header {
    notificationsLoading: boolean = false
    checkedLoading: boolean = false
    notificationsList: INotificationList | null = null
    notificationsTotalCount: number = 0
    notifications: INotification[] = []
    downloadMore: number = 1



    constructor() {
        makeAutoObservable(this)
    }


    setDownloadMore(num: number) {
        this.downloadMore = num
    }

    addNotification = (notification: INotification) => {
        runInAction(() => {
            this.notifications = [...this.notifications, notification]
            this.notificationsTotalCount = this.notificationsTotalCount + 1
        })
    }

    getMyNotification = async (offset: number, count: number) => {
        runInAction(() => this.notificationsLoading = true)
        try {
            const res = await instance().close().getMyNotifications(offset, count)
            this.notificationsList = res.data?.data || {}
            this.notificationsTotalCount = res.data?.data?.new_count
        } catch (err) {
            checkError(err)
            isError(err)
        }
        this.notificationsLoading = false
    }

    requestCheckedNotification = async (onSuccess?: () => void) => {
        this.checkedLoading = true
        try {
            const res = await instance().close().checkedNotifications()
            onSuccess && onSuccess()
        } catch (e) {
            checkError(e)
            isError(e)
        }
        this.checkedLoading = false
    }

}

export const HeaderPageStore = new Header()