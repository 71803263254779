import React, { useEffect } from 'react'
import { Card, List, Space,  Table,  Tooltip } from 'antd';
import pdf from '../../../icons/pdficon.svg'
import { formatIsoToDate, formatIsoToTime } from '../../helpers/utils';

interface IMobileSettings {
    items : any
    // onItemClick : (element : any) => void
    loading: any
    setOpenModal: Function
    setDataInfo: Function
  }

export const MobileSettings: React.FC<IMobileSettings> = ({items, loading, setOpenModal, setDataInfo}) => {

  const titleCard = (name: string) => {
    return (
        <Tooltip trigger="hover" placement="topLeft" title={typeof name === 'string' ? name : ''}>
            {name ? name : '-'}
        </Tooltip>
    )
}

console.log(items)
    const onCliclFunc = (element: any) => {
        setOpenModal({open: true, flag: 'post'}) 
        setDataInfo(element)
    }

    const onCliclFuncDel = (element: any) => {
        setOpenModal({open: true, flag: 'delete'}) 
        setDataInfo(element)
    }

  return (
    <Space direction="vertical" size={16} style={{ width: '100%' }}>
     <List pagination={{ position: "both", defaultPageSize: 25 }}
            dataSource = {items ? items : []}
            loading = {loading}
            renderItem={(element: any) => {
                const onClick = () => {

                }
                return(
                <Card loading={loading} 
                actions={[<div onClick={() => onCliclFunc(element)}>Редактировать</div>, <div onClick={() => onCliclFuncDel(element)}>Удалить</div>]} style={{margin: '15px 0 15px 0'}} key={element.id} title={titleCard(element.panel)} size='default'>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            {/* <div style={{ fontWeight: 'bold' }}>ГБР :</div> */}
                            <div style={{ fontWeight: 'bold' }}>Панель :</div>
                        <div>
                            {element?.panel}
                        </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            {/* <div style={{ fontWeight: 'bold' }}>ГБР :</div> */}
                            <div style={{ fontWeight: 'bold' }}>Исполнитель :</div>
                        <div>
                            {element?.performerName}
                        </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            {/* <div style={{ fontWeight: 'bold' }}>ГБР :</div> */}
                            <div style={{ fontWeight: 'bold' }}>Источник :</div>
                        <div>
                            {element?.sourceName}
                        </div>
                        </div>

                </Card>
            )}}
            />
    </Space>
  )
}