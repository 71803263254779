import React from 'react'
import clsx from "clsx";
import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';

const classes = require('./classes.module.scss')

interface IFallbackProps {
    withLoader?: boolean
    children?: string | React.ReactNode
    color?: string

    [key: string]: any
}

export const Fallback: React.FC<IFallbackProps> = ({
                                                       withLoader = true,
                                                       children,
                                                       color,
                                                       ...props
                                                   }) => {
    const antIcon = <LoadingOutlined style={{fontSize: 24}} spin/>;
    return (
        <div {...props} className={clsx(classes.fallback_wrap, props.className)}>
            {children}
            <div className={classes.fallback_loader}>
                <Spin style={{color: color || 'rgb(0, 62, 140)'}} size={"large"} indicator={antIcon}/>
            </div>
        </div>
    )
}