import {useEffect, useState} from 'react'
import { openNotification } from '../notification';

// FileList , File

let BASE64_MARKER = ';base64,';

export const convertDataURIToBinary =(dataURI:any) => {
  
  let raw = atob(dataURI.split(BASE64_MARKER)[1]);

  let rawLength = raw.length;
  let array = new Uint8Array(new ArrayBuffer(rawLength));

  for(let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}


export const ArrayBufferToString = (buffer: ArrayBuffer) => {
    return BinaryToString(String.fromCharCode.apply(null, Array.prototype.slice.apply(new Uint8Array(buffer))));
}

const BinaryToString =(binary:string) => {
    let error;

    try {
        return decodeURIComponent(escape(binary));
    } catch (_error) {
        error = _error;
        if (error instanceof URIError) {
            return binary;
        } else {
            throw error;
        }
    }
}

const calcProgressBySize = (total: number, loaded: number) => {
    return +(loaded / total * 100).toFixed();
}

const getFileExtension = (file: File) => {
    return `${file.name.split('.')[file.name.split('.').length - 1]}` || ''
}

export const fileToObject = (file: File) => {
    return Object({
        lastModified: file.lastModified,
        name: file.name,
        size: file.size,
        type: file.type
    })
}
export const fileListToArray = (files: FileList): File[] => {
    return Array.from(files);
}

export interface IProgressFileItem {
    id: number | string,
    state: TFileStatus
    progress: number
    file: IFileItem
    jsFile?: File
    order: number
    folderId?: string
    folderName?:string
}

export interface IFileItem {
    base64: string | null
    name: string
    extension: string
    bytes?:string | ArrayBuffer | null
}

export const useFilesLoaderState = (files?: IProgressFileItem[]) => {
    const [processed, setProcessed] = useState<(number | string)[]>([])
    const [state, setState] = useState<IProgressFileItem[]>(files || []);
    const [loaded, setLoaded] = useState<boolean>(false);


    useEffect(() => {
        state.length && load();
    }, [state])

    const addFiles = (files: FileList, selectedFolder?: IFolder, size?: number) => {
        if (size) {
            const _files: IProgressFileItem[] = fileListToArray(files).filter(i=>i?.size < (size *1024 * 1024)).map((item, index) => {
                        const id = +getRandomNum(10000)
                
                        // const byteArray = new Blob(item)

                        return {
                            jsFile: item,
                            id,
                            state: 'progress',
                            progress: 0,
                            file: {
                                base64: null,
                                name: item.name.replace( /^([^\.]*\.)|\./g, '$1' ),
                                extension: getFileExtension(item),
                                bytes:null
                            },
                            order: !!state.find(image => image.order >= 0)
                                ? Math.max.apply(null, state.map(item => item.order)) + 1 + index
                                : index,
                            folderId: selectedFolder?.id,
                            folderName: selectedFolder?.name
                        }
                    })
        const filteredExistsFiles = _files.filter(item => !isFileExists(item?.jsFile?.name));
        setState((state) => [...state, ...filteredExistsFiles]);
        } else {
            const _files: IProgressFileItem[] = fileListToArray(files).map((item, index) => {
            const id = +getRandomNum(10000)
            return {
                jsFile: item,
                id,
                state: 'progress',
                progress: 0,
                file: {
                    base64: null,
                    name: item.name.replace( /^([^\.]*\.)|\./g, '$1' ),
                    extension: getFileExtension(item),
                    bytes:null
                },
                order: !!state.find(image => image.order >= 0)
                    ? Math.max.apply(null, state.map(item => item.order)) + 1 + index
                    : index,
                folderId: selectedFolder?.id,
                folderName:selectedFolder?.name
            }
        })
        const filteredExistsFiles = _files.filter(item => !isFileExists(item?.jsFile?.name));
        setState((state) => [...state, ...filteredExistsFiles]);
        }
        
    }
    const isFileExists = (name?: string) => {
        return state.some(item => item?.jsFile?.name === name)
    }
    const fileProcessed = (id: number | string) => {
        return processed.includes(id)
    }
    const addProcessed = (id: number | string) => {
        setProcessed([...processed, id])
    }
    const changeProgress = (id: number | string, progress: number) => {
        setState(state => state.map(item => {
            if (item.id === id) {
                return {
                    ...item,
                    state: 'progress',
                    progress
                }
            } else {
                return item;
            }
        }))
    }
    const changeFile = (id: number | string, base64: string, bytes: string | ArrayBuffer | null) => {
        setState(state => state.map(item => {
            if (item.id === id) {
                return {
                    ...item,
                    progress: 100,
                    file: {
                        ...item.file,
                        base64,
                        bytes
                    },
                    state: 'success'
                }
            } else {
                return item;
            }
        }))
        setLoaded(!loaded)
    }
    const setError = (id: number | string) => {
        // setState(state => state.map(item => {
        //     if (item.id === id) {
        //         return {
        //             ...item,
        //             state: 'error'
        //         }
        //     } else {
        //         return item;
        //     }
        // }))
        setState(state => state.filter(item => item.id !== id))
    }

    const load = (_files?: IProgressFileItem[]) => {
        (_files || state || []).forEach(({jsFile, id, state}) => {
            if (!fileProcessed(id)) {
                // TODO тут утечка памяти может быть если удалить со стейта загружающийся файл
                const reader = new FileReader();
                // reader.onabort = (e) => {
                //     openNotification({type: 'error', message: 'Ошибка при загрузке файла'})
                //     setError(id)
                // }
                reader.onerror = (e) => {
                    openNotification({type: 'error', message: `Ошибка при загрузке файла ${jsFile?.name}`})
                    setError(id)
                }
                reader.onloadend = (e) => {
                    
                    let bytes = reader.result
                    reader.result && changeFile(id, reader.result.toString(), bytes)
                }
                reader.onprogress = (e) => {
                    const progress = calcProgressBySize(e.total, e.loaded);
                    changeProgress(id, progress);
                }
                //jsFile && reader.readAsArrayBuffer(jsFile)
                
                jsFile && reader.readAsDataURL(jsFile)
                
                addProcessed(id)
            }
        })
    }

    return {load, addFiles, state, setState, loaded};
}


export const getRandomNum = (maxNum = 1000) => {
    return (Math.random() * maxNum).toFixed()
}
export type TFileStatus = 'success' | 'error' | 'progress';

export interface IFolder {
    id: string
    name: string
    filesCount: number
    parentId?: string
    orderNum: number
}