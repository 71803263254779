import React, { useEffect, useState } from 'react'
import { observer } from "mobx-react-lite";
import { SimpleInput } from "../../helpers/Inputs/inputs";
import { SimpleTextArea } from "../../helpers/Inputs/Textarea";
import { ArrowIcon } from "../../../icons/icons";
import { Link, useHistory } from "react-router-dom";
import { ROUTE_PATH } from "../../../routers/paths.main";
import { ListItem } from "@material-ui/core";
import { openNotification } from "../../helpers/notification";
import { DirectoryStore } from "../../../store/directory/DirectoryStore";
import { IDeviceListItem } from "../../../store/directory/types";
import { SimpleSelect } from "../../helpers/Selects/SimpleSelect";
import { ApplicationsStore } from "../../../store/applications/Applications";

const s = require('./style.module.scss')

interface IIudDevicesProps {
    flag: 'I' | 'U',
}

export const IudDevice: React.FC<IIudDevicesProps> = observer(({
    flag,
}) => {

    const router = useHistory()

    const isMobile: boolean = window.outerWidth <= 425

    const [name, setName] = useState<string>('')
    const [address, setAddress] = useState<string>('')
    const [number, setNumber] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [system, setSystem] = useState<string>('')

    const [disabledButton, setDisabledButton] = useState<boolean>(false)

    const { systemItemFullInfo, setSystemFullInfo } = DirectoryStore
    const { requestIudSystems } = ApplicationsStore

    const iudHandler = () => {
        requestIudSystems({
            flag,
            name,
            alias: description,
            id: systemItemFullInfo?.id
        }, () => {
            openNotification({
                type: 'success',
                message: 'Выполнено успешно!'
            })
            router.push('/directory/devices')
        })
    }

    useEffect(() => {
        
        if (flag === 'U') {
            setName(systemItemFullInfo?.name || '')
            setDescription(systemItemFullInfo?.alias || '')

            /*  Это все же если понадобиться восстановить страничку редактирования устройств
                        setNumber(`${deviceItemFullInfo?.number}` || '')
                        setAddress(deviceItemFullInfo?.address || '')
                        setSystem(`${deviceItemFullInfo?.system?.id}` || '')*/
        }
    }, [systemItemFullInfo])

    return <div className={s.iudDevice}>
        <Link to={ROUTE_PATH.extraPage.devices}>
            <div onClick={() => setSystemFullInfo(null)} style={{ display: 'flex', alignItems: "baseline" }}>
                <ArrowIcon style={{
                    transform: 'rotate(180deg)'
                }} />
                <div style={{ color: 'black', fontWeight: 600, fontSize: 24, marginLeft: 6 }}>
                    НАЗАД
                </div>
            </div>
        </Link>
        <div className={s.item}>
            <div className={s.title}>
                Наименование:
            </div>
            <SimpleInput
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
            />
        </div>
        {/*

        Раньше это был пультовый номер, с какого-то фига поступила команда поменять эту вкладку на вкладку изменения системы,
        вдруг понадобится восстановить вкладку изменения устройства и эта часть понадобиться нам!)
        <div className={s.item}>
            <div className={s.title}>
                Номер:
            </div>
            <SimpleInput
                value={number}
                onChange={(e) => {
                    if (!isNaN(+e.currentTarget.value)) {
                        setNumber(e.currentTarget.value)
                    }
                }}
            />
        </div>
        <div className={s.item}>
            <div className={s.title}>
                Система:
            </div>
            <SimpleSelect
                items={systems || []}
                loading={systemsLoading}
                value={system}
                onChange={setSystem}
            />
        </div>
        <div className={s.item}>
            <div className={s.title}>
                Адрес:
            </div>
            <SimpleInput
                value={address}
                onChange={(e) => setAddress(e.currentTarget.value)}
            />
        </div>*/}

        <div className={s.item}>
            <div className={s.title}>
                Описание:
            </div>
            <SimpleTextArea
                value={description}
                onChange={(e) => setDescription(e.currentTarget.value)}
            />
        </div>
        <div className={s.divider}>
        </div>

        <div className={s.btns}>

            <ListItem
                style={{
                    width: isMobile ? '100%' : 104,
                    height: 37,
                    background: 'black',
                    border: '1px solid #212529',
                    borderRadius: 5,
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    cursor: disabledButton ? 'not-allowed' : 'pointer'
                }}
                button
                disabled={disabledButton}
                onClick={iudHandler}
            >
                Сохранить
            </ListItem>
        </div>
    </div>
})