import {
    ICategoryFullInfo,
    IChangeRolesList,
    IDirectoryCategory,
    IDirectoryGroup,
    IDirectoryLoading,
    IDirectoryRole,
    IDirectoryRolesList, IGroupFormView,
    IGroupFullInfo,
    IRoleGroup,
    IRoleListItem,
    TIudCategory,
    TIudRole
} from "./types.data";
import { IApplicationItem } from "../../../store/applications/types";
import { TPaginationReq } from "../../types";
import { IInitialPage } from "../../../store/extraPage/types";
import { TGetApplicationList } from "../../../store/api/bodies/types";
import { TIudGroup } from "../../../store/WorkersPage/types";
import { TEnumItem } from "../../../components/helpers/utils";
import { ISimpleTreeSelectItem } from "../../../components/helpers/Selects/types";

export type TDirectoryReducer = {
    groups: IDirectoryGroup[] | null
    groupsCount: number
    categories: IDirectoryCategory[] | null
    categoriesCount: number
    templates: IApplicationItem[] | null
    templatesCount: number
    //Список ролей, которые приходят нам в справочнике, вместе с формвью
    rolesList: IDirectoryRolesList | null
    loadings: IDirectoryLoading
    initialPages: IInitialPage[]
    groupFullInfo: IDirectoryGroup | null,
    roleFullInfo: IDirectoryRole | null,
    categoryFullInfo: IDirectoryCategory | null,
    iudFLag: 'addGroup' | 'editGroup' | 'addCategory' | 'editCategory' | 'addRole' | 'editRole',
    chosenGroupId: number | null,
    priorityList: TEnumItem[],
    appOrTemplateFlag: 'addApp' | 'addTemplate' | 'editApp' | 'editTemplate',

    subGroupParentId: number | null

    taskFiltersDate: {
        startDate: string
        endDate: string
    } | null

    //Так же для заявки, снова сори)
    applicationReduxId: string | number

    //формвью
    formviews: TEnumItem[]

    //Полный список ролей
    fullRolesList: IRoleListItem[]

    //Видимыйы функционал
    visiblePages: ISimpleTreeSelectItem[]
    visiblePagesLoading: boolean
}


export const REQUEST_DIRECTORY_GROUPS = 'REQUEST_DIRECTORY_GROUPS'
export type REQUEST_DIRECTORY_GROUPS_ACTION = {
    type: typeof REQUEST_DIRECTORY_GROUPS,
    payload: TPaginationReq,
    onSuccess?: () => void,
    onError?: () => void,
}
export const SET_DIRECTORY_GROUPS = 'SET_DIRECTORY_GROUPS'
export type SET_DIRECTORY_GROUPS_ACTION = {
    type: typeof SET_DIRECTORY_GROUPS,
    payload: IDirectoryGroup[]
}
export const SET_DIRECTORY_GROUPS_COUNT = 'SET_DIRECTORY_GROUPS_COUNT'
export type SET_DIRECTORY_GROUPS_COUNT_ACTION = {
    type: typeof SET_DIRECTORY_GROUPS_COUNT,
    payload: number
}
export const SET_DIRECTORY_GROUPS_LOADING = 'SET_DIRECTORY_GROUPS_LOADING'
export type SET_DIRECTORY_GROUPS_LOADING_ACTION = {
    type: typeof SET_DIRECTORY_GROUPS_LOADING,
    payload: boolean
}


export const REQUEST_DIRECTORY_CATEGORIES = 'REQUEST_DIRECTORY_CATEGORIES'
export type REQUEST_DIRECTORY_CATEGORIES_ACTION = {
    type: typeof REQUEST_DIRECTORY_CATEGORIES,
    payload: TPaginationReq,
    onSuccess?: () => void,
    onError?: () => void,
}
export const SET_DIRECTORY_CATEGORIES = 'SET_DIRECTORY_CATEGORIES'
export type SET_DIRECTORY_CATEGORIES_ACTION = {
    type: typeof SET_DIRECTORY_CATEGORIES,
    payload: IDirectoryCategory[]
}
export const SET_DIRECTORY_CATEGORIES_COUNT = 'SET_DIRECTORY_CATEGORIES_COUNT'
export type SET_DIRECTORY_CATEGORIES_COUNT_ACTION = {
    type: typeof SET_DIRECTORY_CATEGORIES_COUNT,
    payload: number
}
export const SET_DIRECTORY_CATEGORIES_LOADING = 'SET_DIRECTORY_CATEGORIES_LOADING'
export type SET_DIRECTORY_CATEGORIES_LOADING_ACTION = {
    type: typeof SET_DIRECTORY_CATEGORIES_LOADING,
    payload: boolean
}


export const REQUEST_DIRECTORY_TEMPLATES = 'REQUEST_DIRECTORY_TEMPLATES'
export type REQUEST_DIRECTORY_TEMPLATES_ACTION = {
    type: typeof REQUEST_DIRECTORY_TEMPLATES,
    payload: TGetApplicationList,
    onSuccess?: () => void,
    onError?: () => void,
}
export const SET_DIRECTORY_TEMPLATES = 'SET_DIRECTORY_TEMPLATES'
export type SET_DIRECTORY_TEMPLATES_ACTION = {
    type: typeof SET_DIRECTORY_TEMPLATES,
    payload: IApplicationItem[]
}
export const SET_DIRECTORY_TEMPLATES_COUNT = 'SET_DIRECTORY_TEMPLATES_COUNT'
export type SET_DIRECTORY_TEMPLATES_COUNT_ACTION = {
    type: typeof SET_DIRECTORY_TEMPLATES_COUNT,
    payload: number
}
export const SET_DIRECTORY_TEMPLATES_LOADING = 'SET_DIRECTORY_TEMPLATES_LOADING'
export type SET_DIRECTORY_TEMPLATES_LOADING_ACTION = {
    type: typeof SET_DIRECTORY_TEMPLATES_LOADING,
    payload: boolean
}

export const SET_CURRENT_INITIAL_PAGE = 'SET_CURRENT_INITIAL_PAGE'
export type SET_CURRENT_INITIAL_PAGE_ACTION = {
    type: typeof SET_CURRENT_INITIAL_PAGE,
    payload: number
}

export const SET_IUD_FLAG = 'SET_IUD_FLAG'
export type SET_IUD_FLAG_ACTION = {
    type: typeof SET_IUD_FLAG
    payload: 'addGroup' | 'editGroup' | 'addCategory' | 'editCategory' | 'addSubgroup' | 'addRole' | 'editRole'
}

export const SET_CHOSEN_GROUP_ID = 'SET_CHOSEN_GROUP_ID'
export type SET_CHOSEN_GROUP_ID_ACTION = {
    type: typeof SET_CHOSEN_GROUP_ID
    payload: number
}

export const IUD_GROUP = 'IUD_GROUP'
export type IUD_GROUP_ACTION = {
    type: typeof IUD_GROUP
    payload: TIudGroup
    onSuccess?: () => void
}

export const REQUEST_PRIORITY = 'REQUEST_PRIORITY'
export type REQUEST_PRIORITY_ACTION = {
    type: typeof REQUEST_PRIORITY
    payload: TPaginationReq
    onSuccess?: (list: TEnumItem[]) => void
}

export const SET_PRIORITY = 'SET_PRIORITY'
export type SET_PRIORITY_ACTION = {
    type: typeof SET_PRIORITY
    payload: TEnumItem[]
}
export const SET_PRIORITY_LOADING = 'SET_PRIORITY_LOADING'
export type SET_PRIORITY_LOADING_ACTION = {
    type: typeof SET_PRIORITY_LOADING
    payload: boolean
}

export const IUD_CATEGORY = 'IUD_CATEGORY'
export type IUD_CATEGORY_ACTION = {
    type: typeof IUD_CATEGORY
    payload: TIudCategory
    onSuccess?: () => void
}


//Чтобы с помощью 4 флагов сделать 1 компоненту переиспользуемой
export const SET_NEW_APPLICATION_OR_TEMPLATE_FLAG = 'SET_NEW_APPLICATION_OR_TEMPLATE_FLAG'
export type SET_NEW_APPLICATION_OR_TEMPLATE_FLAG_ACTION = {
    type: typeof SET_NEW_APPLICATION_OR_TEMPLATE_FLAG
    payload: 'addApp' | 'addTemplate' | 'editApp' | 'editTemplate' | 'addSubgroup' | 'addRole' | 'editRole'
}

//Сохранение id Заявки, сори, что тут )
export const SET_APPLICATION_ID = 'SET_APPLICATION_ID'
export type SET_APPLICATION_ID_ACTION = {
    type: typeof SET_APPLICATION_ID
    payload: string | number
}

//Сохранение отцовского id для подгруппы в справочниках, нужно для iud-a

export const SET_SUBGROUP_PARENT_ID = 'SET_SUBGROUP_PARENT_ID'
export type SET_SUBGROUP_PARENT_ID_ACTION = {
    type: typeof SET_SUBGROUP_PARENT_ID
    payload: number | null
}

export const SET_DATES_FOR_TASK_FILTER = 'SET_DATES_FOR_TASK_FILTER'
export type SET_DATES_FOR_TASK_FILTER_ACTION = {
    type: typeof SET_DATES_FOR_TASK_FILTER
    payload: {
        startDate: string
        endDate: string
    } | null
}

//Справочник ролей
export const REQUEST_DIRECTORY_ROLES = 'REQUEST_DIRECTORY_ROLES'
export type REQUEST_DIRECTORY_ROLES_ACTION = {
    type: typeof REQUEST_DIRECTORY_ROLES
    payload: {}
}

export const SET_DIRECTORY_ROLES = 'SET_DIRECTORY_ROLES'
export type SET_DIRECTORY_ROLES_ACTION = {
    type: typeof SET_DIRECTORY_ROLES
    payload: IDirectoryRolesList
}

export const SET_DIRECTORY_ROLE_INFO = 'SET_DIRECTORY_ROLE_INFO'
export type SET_DIRECTORY_ROLE_INFO_ACTION = {
    type: typeof SET_DIRECTORY_ROLE_INFO
    payload: IDirectoryRole
}

export const SET_DIRECTORY_ROLES_LOADING = 'SET_DIRECTORY_ROLES_LOADING'
export type SET_DIRECTORY_ROLES_LOADING_ACTION = {
    type: typeof SET_DIRECTORY_ROLES_LOADING
    payload: boolean
}

export const IUD_DIRECTORY_ROLE = 'IUD_DIRECTORY_ROLE'
export type IUD_DIRECTORY_ROLE_ACTION = {
    type: typeof IUD_DIRECTORY_ROLE
    payload: TIudRole
    onSuccess?: () => void
}

//Функция сохранения измененной роли(изменяется количество formview для групп у данной роли)

export const CHANGE_ROLES_LIST = 'CHANGE_ROLES_LIST'
export type CHANGE_ROLES_LIST_ACTION = {
    type: typeof CHANGE_ROLES_LIST
    payload: IChangeRolesList
}


//Общий перечень формвью
export const REQUEST_FORMVIEWS = 'REQUEST_FORMVIEWS'
export type REQUEST_FORMVIEWS_ACTION = {
    type: typeof REQUEST_FORMVIEWS
    payload: {
        typeId?: number
    }
}

export const SET_FORMVIEWS = 'SET_FORMVIEWS'
export type SET_FORMVIEWS_ACTION = {
    type: typeof SET_FORMVIEWS
    payload: TEnumItem[]
}

export const SET_FORMVIEWS_LOADING = 'SET_FORMVIEWS_LOADING'
export type SET_FORMVIEWS_LOADING_ACTION = {
    type: typeof SET_FORMVIEWS_LOADING
    payload: boolean
}
export const SET_VISIBLE_PAGES = 'SET_VISIBLE_PAGES'
export type SET_VISIBLE_PAGES_ACTION = {
    type: typeof SET_VISIBLE_PAGES
    payload: ISimpleTreeSelectItem[]
}

export const SET_VISIBLE_PAGES_LOADING = 'SET_VISIBLE_PAGES_LOADING'
export type SET_VISIBLE_PAGES_LOADING_ACTION = {
    type: typeof SET_VISIBLE_PAGES_LOADING
    payload: boolean
}

//Получение списка ролей
export const REQUEST_ROLES_LIST = 'REQUEST_ROLES_LIST'
export type REQUEST_ROLES_LIST_ACTION = {
    type: typeof REQUEST_ROLES_LIST
    payload: {}
}

export const SET_ROLES_LIST = 'SET_ROLES_LIST'
export type SET_ROLES_LIST_ACTION = {
    type: typeof SET_ROLES_LIST
    payload: IRoleListItem[]
}

export const SET_ROLES_LIST_LOADING = 'SET_ROLES_LIST_LOADING'
export type SET_ROLES_LIST_LOADING_ACTION = {
    type: typeof SET_ROLES_LIST_LOADING
    payload: boolean
}

export const SET_ROLE_GROUP = 'SET_ROLE_GROUP'
export type SET_ROLE_GROUP_ACTION = {
    type: typeof SET_ROLE_GROUP,
    payload: IRoleGroup
}

export const SET_GROUP_FORMVIEWS = 'SET_GROUP_FORMVIEWS'
export type SET_GROUP_FORMVIEWS_ACTION = {
    type: typeof SET_GROUP_FORMVIEWS,
    payload: IGroupFormView
}

//Чистка ролей 
export const CLEAR_SUBGROUPS_ROLES = 'CLEAR_SUBGROUPS_ROLES'
export type CLEAR_SUBGROUPS_ROLES_ACTION = {
    type: typeof CLEAR_SUBGROUPS_ROLES,
    payload: number
}

export type DIRECTORY_ACTIONS =
    | SET_DIRECTORY_GROUPS_ACTION
    | SET_DIRECTORY_GROUPS_LOADING_ACTION
    | SET_DIRECTORY_CATEGORIES_ACTION
    | SET_DIRECTORY_CATEGORIES_LOADING_ACTION
    | SET_DIRECTORY_TEMPLATES_ACTION
    | SET_DIRECTORY_TEMPLATES_LOADING_ACTION
    | SET_CURRENT_INITIAL_PAGE_ACTION
    | SET_IUD_FLAG_ACTION
    | SET_CHOSEN_GROUP_ID_ACTION
    | SET_PRIORITY_ACTION
    | SET_PRIORITY_LOADING_ACTION
    | SET_NEW_APPLICATION_OR_TEMPLATE_FLAG_ACTION
    | SET_APPLICATION_ID_ACTION
    | SET_SUBGROUP_PARENT_ID_ACTION
    | SET_DATES_FOR_TASK_FILTER_ACTION
    | SET_DIRECTORY_ROLES_ACTION
    | SET_DIRECTORY_ROLES_LOADING_ACTION
    | SET_FORMVIEWS_ACTION
    | SET_FORMVIEWS_LOADING_ACTION
    | SET_DIRECTORY_ROLE_INFO_ACTION
    | SET_ROLES_LIST_ACTION
    | SET_ROLES_LIST_LOADING_ACTION
    | CHANGE_ROLES_LIST_ACTION
    | SET_ROLE_GROUP_ACTION
    | SET_GROUP_FORMVIEWS_ACTION
    | SET_DIRECTORY_GROUPS_COUNT_ACTION
    | SET_DIRECTORY_CATEGORIES_COUNT_ACTION
    | SET_DIRECTORY_TEMPLATES_COUNT_ACTION
    | SET_VISIBLE_PAGES_LOADING_ACTION
    | SET_VISIBLE_PAGES_ACTION
    | CLEAR_SUBGROUPS_ROLES_ACTION

