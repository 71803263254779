import {put, takeEvery} from "redux-saga/effects";
import {GET_MY_INFO, GET_MY_INFO_ACTION} from "../types";
import {setMyInfo, setMyInfoLoading} from "./actions";
import {instance} from "../../../../store/api";
import {ResponseGenerator} from "../../types";
import {checkError, isError} from "../../../../store/api/helpers";

export function* authWatcher() {
    yield takeEvery(GET_MY_INFO, getMyInfoWorker)
}

export function* getMyInfoWorker(action: GET_MY_INFO_ACTION) {
    yield put(setMyInfoLoading(true))
    try {
        const res: ResponseGenerator = yield getMyInfoAsync().next().value
        yield put(setMyInfo(res.data?.data?.value))
    } catch (e) {
        // isError(e)
        checkError(e)
    }
    yield put(setMyInfoLoading(false))
}

export function* getMyInfoAsync() {
    return instance().close().getMyInfo()
}