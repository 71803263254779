
import React from "react";
import { IMultipleTreeSelectProps } from "./types";
import { Select, TreeSelect } from "antd";
import clsx from "clsx";
import { ArrowIcon } from "../../../icons/icons";

const classes = require('./selects.module.scss')

const tagRender = (count: number) => {
    return (
        <div className={classes.text}>
            {
                `Выбрано: ${count}`
            }
        </div>
    )
}


export const MultipleTreeSelect: React.FC<IMultipleTreeSelectProps> = ({
    items,
    onChange,
    width,
    size = 'lg',
    placeholder = 'Выбрано: 0',
    values,
    ...props
}) => {
    const { error, invalid, value } = props;

    const classNames = clsx(
        classes.simple_select,
        size === 'lg' && classes.lg,
        size === 'md' && classes.md,
        size === 'xs' && classes.xs
    )
    return (
        <TreeSelect
            allowClear
            getPopupContainer={trigger => trigger.parentNode}
            value={values}
            treeData={items}
            treeDataSimpleMode
            className={classNames}
            //@ts-ignore
            popupClassName={classes.dropdown}
            treeCheckable={false}
            multiple
            suffixIcon={!props?.loading
                ? <ArrowIcon style={{ transform: 'rotate(90deg)', width: 6, height: 10 }} />
                : undefined
            }
            placeholder={placeholder}
            //optionfilterprop="children"
            onChange={onChange}
            style={{ width: width, border: invalid ? '1px solid #DF0E0F' : '', borderRadius: 4 }}
            showArrow={false}
            showSearch={false}
            maxTagCount={0}
            tagRender={() => tagRender(values && values.length || 0)}
            {...props}
        />
    )
}