import React, { useState } from "react";
import { TEnumItem } from "../../helpers/utils";
import { SimpleCheckbox } from "../../common/CheckBox/CheckBox";
import { openNotification } from "../../helpers/notification";
import { useDispatch } from "react-redux";
import { setGroupFormView } from "../../../redux/reducers/directory/actions/actions";
import { ISetRoleGroup } from "../../../redux/reducers/directory/actions/types";

const s = require('./FormViewsItem.module.scss')

interface IFormViewsItemProps {
    item: TEnumItem
    bol: boolean
    setRoleFunctions: any
    roleFunctions: { id: number }[]
    isGroupChosen: boolean
    rolesGroupId?: number
    rolesGroupName: string
    setRoleGroup: ISetRoleGroup
}

export const FormViewsItem: React.FC<IFormViewsItemProps> = ({
    item,
    bol,
    roleFunctions,
    setRoleFunctions,
    isGroupChosen,
    rolesGroupId,
    rolesGroupName,
    setRoleGroup
}) => {

    const [checked, setChecked] = useState<boolean>(bol)

    const dispatch = useDispatch()

    const checkHandler = (value: boolean, id: number) => {


        if (roleFunctions.length === 0) {
            setRoleFunctions([...roleFunctions, {
                id
            }])
        } else if (value) {
            setRoleFunctions([...roleFunctions, {
                id
            }])
        } else {
            setRoleFunctions(roleFunctions.filter(item => item.id !== id))
        }
        setChecked(value)
        rolesGroupId && dispatch(setGroupFormView({
            idGroup: +rolesGroupId,
            formview: item,
            nameGroup: rolesGroupName
        }))
    }


    return <div className={s.container}>
        <span onClick={() => {
            !isGroupChosen && openNotification({
                type: 'warning',
                message: 'Для начала выберите группу'
            })
        }}>
            <SimpleCheckbox
                disabled={!isGroupChosen}
                value={checked}
                onChange={() => checkHandler(!checked, +item.id)}
            />
        </span>
        <div style={{ marginLeft: 16 }}>
            {item.name}
        </div>
    </div>
}