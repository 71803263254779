import { Divider } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { CrossIcon, PencilIcon } from '../../../icons/icons'
import { ROUTE_PATH } from '../../../routers/paths.main'
import { DirectoryStore } from '../../../store/directory/DirectoryStore'
import { ICelebrationDayItem } from '../../../store/directory/types'
import { DeleteModal } from '../../helpers/Modals/DeleteModal'
import { openNotification } from '../../helpers/notification'

const s = require('./style.module.scss')

interface ICelebrationDaysItemProps {
    item: ICelebrationDayItem
    searchHandler: () => void
}

export const CelebrationDaysItem: React.FC<ICelebrationDaysItemProps> = observer(({
    item,
    searchHandler
}) => {

    const { requestIudCelebrationDay, setIudFlag, setCelebrationDayInfo } = DirectoryStore

    const [deleteModal, setDeleteMode] = useState<boolean>(false)

    const deleteHandler = () => {
        requestIudCelebrationDay({
            flag: 'D',
            id: item?.id
        }, () => {
            openNotification({
                type: 'success',
                message: 'Удалено !'
            })
            searchHandler()
        })
    }

    return <div className={s.dayItem}>
        <div className={s.row}>
            <div className={s.item}>
                {item?.name}
            </div>
            <div className={s.item}>
                <Link
                    to={ROUTE_PATH.extraPage.iudCelebrationDay}
                    style={{
                        width: 14,
                        height: 16,
                        marginRight: 8
                    }}
                >
                    <PencilIcon
                        onClick={() => {
                            setIudFlag('U')
                            setCelebrationDayInfo(item)
                        }}
                        style={{ cursor: 'pointer' }} />
                </Link>
                <CrossIcon onClick={() => setDeleteMode(true)} style={{ cursor: 'pointer' }} />

                <DeleteModal
                    title={'данный нерабочий день'}
                    closeHandler={() => setDeleteMode(false)}
                    open={deleteModal}
                    request={deleteHandler}
                />
            </div>
        </div>
    </div>
})