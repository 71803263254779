import React, { useEffect, useState } from 'react';
import { Header } from '../common/Header/Header';
import { HeadTitle } from '../common/HeadTitle/PageTitle';
import { SimpleCheckbox } from '../common/CheckBox/CheckBox';
import { WorkerListItem } from "./WorkerListItem/WorkerListItem";
import { key } from "../../store/api";
import { TopSidebarButton } from "../TopSidebarButton/TopSidebarButton";
import { Fallback } from "../helpers/Fallback/Fallback";
import { NoData } from "../common/Nodata/NoData";
import { observer } from "mobx-react-lite";
import { ListItem } from "@material-ui/core";
import WorkersPageStore from "../../store/WorkersPage/WorkersStore";
import { useDispatch } from "react-redux";
import { getFullRolesList } from "../../redux/reducers/directory/actions/actions";
import { MyPagination } from "../helpers/MyPagination/MyPagination";
import { ApplicationsStore } from "../../store/applications/Applications";
import { WorkerListItemCard } from './WorkerListItem/WorkersListItemCard';
import { Dropdown, Table, TableColumnsType } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import { EditWorkerModal } from './WorkerListItem/editModal/EditWorkerModal';
import { EditIcon } from '../../icons/icons';

const s = require('./style.module.scss');

interface IWorkersComponentProps {

    [key: string]: any
}

export const Workers: React.FC<IWorkersComponentProps> = observer(({ }) => {


    const PAGE_SIZE = 50
    const [page, setPage] = useState<number>(1)

    // const isMobile: boolean = window.innerWidth <= 1024
    const [widthOfScreen, setWidthOfScreen] = useState<number>(window.innerWidth)
    const [isMobile, setIsMobile] = useState<boolean>(widthOfScreen <= 1024 ? true : false)

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        widthOfScreen <= 1024 ? setIsMobile(true) : setIsMobile(false)
    }, [widthOfScreen, window.innerWidth])

    const handleResize = () => {
        setWidthOfScreen(window.innerWidth);
    };

    

    const [selectedWorkers, setSelectedWorkers] = useState<{ workerId: any }[]>([])


    const { requestWorkersListOrItem, workersLoading, workers, workersCount } = WorkersPageStore

    const [loadCount, setLoadCount] = useState<number>(10)

    const scroll: { x?: number | string; y?: number | string } = { x: 1000, y: 700 };

    const [searchValue, setSearchValue] = useState<string>('')

    const [editMode, setEditMode] = useState<boolean>(false)
    
    const [worker, setWorker] = useState<any>(null)


    const requestHandler = (searchValue: string) => {
        requestWorkersListOrItem({
            id: null,
            offset: PAGE_SIZE * (page - 1),
            count: PAGE_SIZE,
            searchValue: searchValue === '' ? null : searchValue,
            flag: 'withoutGroups'
        })
    }

    useEffect(() => {
        requestHandler(searchValue)
    }, [page])

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getFullRolesList({}))
        if (!localStorage.getItem(key.main)) {
            // window.location.href = '/authorization'
        } else {
            requestHandler(searchValue)
        }
    }, [])

    const [checked, setChecked] = useState<boolean>(false)

    //For Table
    interface DataType {
        key: React.Key
        "fio": string
        "groupSolo": string,
        "phone": string,
        "work": string,
        "overdue": string
    }
    const data: DataType[] = workers?.map((element:any) => {
        return (
            {   
                key: element.id,
                "fio": element.name,
                "groupSolo": element?.group?.name,
                "phone": element?.phone?.value,
                "work": element?.work,
                "overdue": element?.overdue,
                "formviews": element?.formviews,
                "group": element?.group,
                "roles": element?.roles
            }
        )
    })
    const headers = [
        {
            key: "fio", 
            title: "ФИО", 
            dataIndex: "fio",
        },
        {
            key: "groupSolo", 
            title: "Группа", 
            dataIndex: "groupSolo",
        },
        {
            key: "phone", 
            title: "Телефон ", 
            dataIndex: "phone",
            render: (text: string) => <a href={`tel:${text}`}>{text}</a>,
        },
        {
            key: "work", 
            title: "В работе ", 
            dataIndex: "work",
        },
        {
            key: "overdue", 
            title: "Просрочено ", 
            dataIndex: "overdue",
        }
        ]
    const columns: TableColumnsType<DataType> = headers.map((element: any, index: number) => {
        return ({
            key: element.key,
            title: element.title,
            dataIndex: element.dataIndex,
            render: element?.render,
            ellipsis: {
                showTitle: false,
            },
            width: 180,
        });
    }) || [];

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const onSelectChange = (newSelectedRowKeys: any[], selectedRows: any[]) => {
        console.log('selectedRowKeys changed: ',newSelectedRowKeys, selectedRows);
        setSelectedWorkers(newSelectedRowKeys)
        setSelectedRowKeys(newSelectedRowKeys);
      };
    
      const rowSelection: TableRowSelection<any> = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
        ]
      };

    return (
        <>
            {editMode && <EditWorkerModal
                searchHandler={() => requestHandler(searchValue)}
                worker={worker}
                closeHandler={() => setEditMode(false)}
                title={'Редактирование сотрудника'}
                open={editMode}
            />}
            
        <div className={s.main_page_wrap}>
            <Header searchValue={searchValue} setSearchValue={setSearchValue}
                request={(value) => requestHandler(value)} />
            <div className={s.title_wrap}>
                <HeadTitle title={'Сотрудники'} />
                <div className={s.title_wrap}>
                    <TopSidebarButton requestHandler={() => requestHandler(searchValue)} selectedWorkers={selectedWorkers} flag={'employees'} />
                </div>
            </div>
            {workersLoading
                ? <div style={{ display: 'flex', alignItems: 'center', height: 750 }}>
                    <Fallback>Пожалуйста подождите, идёт загрузка сотрудников</Fallback>
                </div>
                : <div className={s.items}>
                    {
                    //Workers
                 
                    <Table 
                        style={{cursor: 'pointer'}}
                        dataSource={data} 
                        columns={
                            columns ?
                            [...columns.map(element => ({
                                ...element
                              })),
                              {
                                title: 'Действия',
                                key: 'action',
                                align: 'center',
                                render: (_, record) => {
                                  return (
                                    <div onClick={(event) => {
                                        setWorker(record)
                                        setEditMode(true)
                                    }}>
                                        <EditIcon/>
                                    </div>
                                  )
                                },
                                width: 100
                              },
                            ]
                            : []
                        } 
                        bordered
                        loading={workersLoading}
                        scroll={scroll}
                        rowSelection={rowSelection}
                        pagination={{
                            position: ['topLeft', 'bottomLeft'],
                            pageSizeOptions: ['25', '50', '75', '100'],
                        }}
                    /> 
                    
                    //Workers
                    }
                    {/* <div className={s.btn_download}>
                        <ListItem
                            button
                            style={{
                                width: 150,
                                border: '1px solid #CED4DA',
                                background: '#fff',
                                borderRadius: 5,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            onClick={() => {
                                setLoadCount(loadCount + 5)
                                requestWorkersListOrItem({
                                    id: null,
                                    offset: 0,
                                    count: loadCount,
                                    searchValue: null,
                                    flag: 'withoutGroups'
                                })
                            }}
                        >
                            Загрузить ещё
                        </ListItem>
                    </div>*/}
                    {/* <MyPagination
                        onChange={setPage}
                        total={workersCount || 0}
                        currentPage={page}
                        pageSize={PAGE_SIZE}
                    /> */}
                </div>
            }

        </div>
        </>
    )
})
