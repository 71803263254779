import React, { useEffect, useState } from "react";
import { formatIsoToDate } from "../../helpers/utils";
import { IHistoryTableItem } from "./types";
import { observer } from "mobx-react-lite";

const s = require('./style.module.scss')

interface IHistoryTableItemProps {
    item: IHistoryTableItem
}

export const HistoryTableItem: React.FC<IHistoryTableItemProps> = observer(({
    item
}) => {


    return <div className={s.row}>
        <div className={s.item}
            style={{ flex: '0 0 8%' }}>{formatIsoToDate(item?.date) + ' ' + item?.date[11] + item?.date[12] + item?.date[13] + item?.date[14] + item?.date[15]}</div>
        <div className={s.item} style={{ flex: '0 0 10%' }}>{item?.author}</div>
        <div className={s.item} style={{ flex: '0 0 10%' }}>{item?.changingPlace}</div>
        <div className={s.item}
            style={{ flex: '0 0 60%' }}>{item?.beforeValue && item?.beforeValue + ' ' + '➡'} {item?.afterValue}</div>
    </div>
})

export const HistoryTableCardItem: React.FC<IHistoryTableItemProps> = observer(({
    item
}) => {
    return <div className={s.card}>
        <div className={s.item}>
            <div className={s.title}>
                Дата
            </div>
            <div className={s.value}>
                {formatIsoToDate(item?.date) + ' ' + item?.date[11] + item?.date[12] + item?.date[13] + item?.date[14] + item?.date[15]}
            </div>
        </div>
        <div className={s.item}>
            <div className={s.title}>
                Автор
            </div>
            <div className={s.value}>
                {item?.author}
            </div>
        </div>
        <div className={s.item}>
            <div className={s.title}>
                Где изменилось
            </div>
            <div className={s.value}>
                {item?.changingPlace}
            </div>
        </div>
        <div className={s.item}>
            <div className={s.title}>
                Изменения
            </div>
            <div className={s.value}>
                {item?.beforeValue && item?.beforeValue + ' ' + '➡'} {item?.afterValue}
            </div>
        </div>
    </div>
})