// @ts-nocheck
import React from 'react';
import { DatePicker, Space, TreeSelect } from 'antd';
import 'antd/dist/antd.css';
import moment, { Moment } from "moment";
import { on } from "cluster";

const s = require('./style.module.scss');

interface IDatePickerComponentProps {
    onChange: any
    value: Moment | Date | undefined

    placeholder?: string

    width?: number | string
    showTime?: boolean

    [key: string]: any
}

export const DatePickerComponent: React.FC<IDatePickerComponentProps> = ({
    onChange,
    value,
    placeholder,
    width,
    showTime = true
}) => {


    return (
        <div className={s.main_page_wrap} style={{ width: '100%' }}>
            <DatePicker onChange={(value) => {
                console.log(value)
                if (value === null) onChange(undefined)
                else onChange(value)
            }}
                value={value !== undefined ? moment(value) : value}
                format={showTime ? 'DD.MM.YYYY HH:mm:ss' : 'DD.MM.YYYY '}
                style={{
                    width: width || '679px',
                    height: '37px',
                    padding: '9px 18px',
                    marginTop: '18px',
                    color: '#212529',
                }}
                locale={{
                    lang: {
                        "locale": "ru_RU",
                        "placeholder": "Выберите дату",
                        "rangePlaceholder": ["Start date", "End date"],
                        "today": "Сегодня",
                        "now": "Now",
                        "backToToday": "Back to today",
                        "ok": "OK",
                        "clear": "Clear",
                        "month": "Месяц",
                        "year": "Год",
                        "timeSelect": "Select time",
                        "dateSelect": "Select date",
                        "monthSelect": "Choose a month",
                        "yearSelect": "Choose a year",
                        "decadeSelect": "Choose a decade",
                        "yearFormat": "YYYY",
                        "dateFormat": "M/D/YYYY",
                        "dayFormat": "D",
                        "dateTimeFormat": "M/D/YYYY HH:mm:ss",
                        "monthFormat": "MMMM",
                        "monthBeforeYear": true,
                        "previousMonth": "Previous month (PageUp)",
                        "nextMonth": "Next month (PageDown)",
                        "previousYear": "Last year (Control + left)",
                        "nextYear": "Next year (Control + right)",
                        "previousDecade": "Last decade",
                        "nextDecade": "Next decade",
                        "previousCentury": "Last century",
                        "nextCentury": "Next century"
                    },
                    "timePickerLocale": {
                        "placeholder": "Select time"
                    },
                    "dateFormat": "DD-MM-YYYY",
                    "dateTimeFormat": "DD-MM-YYYY HH:mm:ss",
                    "weekFormat": "YYYY-wo",
                    "monthFormat": "YYYY-MM"
                }}
                showTime
                showHour
                showMinute
                allowClear={true}
                placeholder={placeholder || 'Введите дату...'}
            />
        </div>
    );
};

export const RangeDatePicker: React.FC<IDatePickerComponentProps> = ({
    value,
    onChange,
    width,
    mobile
}) => {

    //console.log(width)

    const { RangePicker } = DatePicker;

    return <Space direction="vertical" style={{ width }}>
        <RangePicker onChange={onChange}
            placeholder={['Дата от', 'Дата до']}
            format={'DD.MM.YYYY'}
            style={{
                width: width ? '100%' : '679px',
                height: mobile ? '38px' : '32px',
                padding: '9px 18px',
                color: '#212529',
            }}
            locale={{
                lang: {
                    "locale": "ru_RU",
                    "placeholder": "Выберите дату",
                    "rangePlaceholder": ["Start date", "End date"],
                    "today": "Сегодня",
                    "now": "Now",
                    "backToToday": "Back to today",
                    "ok": "OK",
                    "clear": "Clear",
                    "month": "Месяц",
                    "year": "Год",
                    "timeSelect": "Select time",
                    "dateSelect": "Select date",
                    "monthSelect": "Choose a month",
                    "yearSelect": "Choose a year",
                    "decadeSelect": "Choose a decade",
                    "yearFormat": "YYYY",
                    "dateFormat": "M/D/YYYY",
                    "dayFormat": "D",
                    "dateTimeFormat": "M/D/YYYY HH:mm:ss",
                    "monthFormat": "MMMM",
                    "monthBeforeYear": true,
                    "previousMonth": "Previous month (PageUp)",
                    "nextMonth": "Next month (PageDown)",
                    "previousYear": "Last year (Control + left)",
                    "nextYear": "Next year (Control + right)",
                    "previousDecade": "Last decade",
                    "nextDecade": "Next decade",
                    "previousCentury": "Last century",
                    "nextCentury": "Next century"
                },
                "timePickerLocale": {
                    "placeholder": "Select time"
                },
                "dateFormat": "DD-MM-YYYY",
                "dateTimeFormat": "DD-MM-YYYY HH:mm:ss",
                "weekFormat": "YYYY-wo",
                "monthFormat": "YYYY-MM"
            }}
            allowClear
        />
    </Space>
}