import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import CloseIcon from "@material-ui/icons/Close";

const s = require('./FullTaskInfo.module.scss')

interface IFullTaskInfoProps {
    listData: { type: string, content: string, background: string, color: string }[]
    closeHandler: () => void
    date: string
    day: string
}

export const FullTaskInfo: React.FC<IFullTaskInfoProps> = observer(({listData, closeHandler, date, day}) => {

    const [month] = useState<{ count: string, name: string }[]>([
        {
            count: '01',
            name: 'Января'
        },
        {
            count: '02',
            name: 'Февраля'
        },
        {
            count: '03',
            name: 'Марта'
        },
        {
            count: '04',
            name: 'Апреля'
        },
        {
            count: '05',
            name: 'Мая'
        },
        {
            count: '06',
            name: 'Июня'
        },
        {
            count: '07',
            name: 'Июля'
        },
        {
            count: '08',
            name: 'Августа'
        },
        {
            count: '09',
            name: 'Сентября'
        },
        {
            count: '10',
            name: 'Октября'
        },
        {
            count: '11',
            name: 'Ноября'
        },
        {
            count: '12',
            name: 'Декабря'
        },
    ])
    const [curMonth, setCurMonth] = useState<{ count: string, name: string } | undefined | null>()
    useEffect(() => {
        setCurMonth(month.find((i) => i.count === (date[3] + date[4])))
    }, [])
    return <div className={s.container} onMouseLeave={closeHandler}>
        <div className={s.header}>
            <div className={s.date}>
                {day},
                {date[0] !== '0' && date[0] + date[1]} {curMonth?.name.toLowerCase()}
            </div>

        </div>
       <div className={s.items}>
           {listData && listData.length > 0 && listData.map(i => <div className={s.item}>
               {i?.content}
           </div>)}
       </div>
    </div>
})