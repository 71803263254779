import React, {useState} from 'react'
import {IMarksListItem} from "../../../store/directory/types";
import {Link} from "react-router-dom";
import {ROUTE_PATH} from "../../../routers/paths.main";
import {CrossIcon, PencilIcon} from "../../../icons/icons";
import {observer} from "mobx-react-lite";
import {DirectoryStore} from "../../../store/directory/DirectoryStore";
import {DeleteModal} from "../../helpers/Modals/DeleteModal";
import {openNotification} from "../../helpers/notification";
import {useSelector} from "react-redux";
import {TRootState} from "../../../redux/store";
import {formviewAvailabilityFunc} from "../../helpers/fromviewHelpers/formviewAvailability";

const s = require('./style.module.scss')

interface IMarksItemProps {
    item: IMarksListItem
    searchHandler: () => void
}

export const MarksItem: React.FC<IMarksItemProps> = observer(({
                                                                  item,
                                                                  searchHandler
                                                              }) => {

    const [deleteModal, setDeleteMode] = useState<boolean>(false)

    const {requestIudMark, requestMarksList} = DirectoryStore


    const formviews = useSelector((state:TRootState)=>state.authReducer.myInfo?.formviews)

    const deleteHandler = () => {
        requestIudMark({
            flag: 'D',
            id: item?.id
        }, () => {
            openNotification({
                type: 'success',
                message: 'Метка удалена'
            })
            searchHandler()
        })
    }

    return <div className={s.marksItem}>
        <div className={s.row}>
            <div className={s.item}>
                {item.name}
            </div>
            <div className={s.item}>
                {formviewAvailabilityFunc(formviews || [], 'edit_tags') && <Link
                    to={ROUTE_PATH.extraPage.iudMarks}
                    style={{
                        width: 14,
                        height: 16,
                        marginRight: 8
                    }}
                >
                    <PencilIcon
                        onClick={() => {
                            DirectoryStore.setMarkItemFullInfo(item)
                            DirectoryStore.setIudFlag('U')
                        }}
                        style={{cursor: 'pointer'}}/>
                </Link>}
                {formviewAvailabilityFunc(formviews || [], 'delete_tags') &&<CrossIcon onClick={() => {
                    setDeleteMode(true)
                }} style={{cursor: 'pointer'}}/>}

                <DeleteModal
                    title={'данную метку'}
                    closeHandler={() => setDeleteMode(false)}
                    open={deleteModal}
                    request={deleteHandler}
                />
            </div>
        </div>
    </div>
})
