import React, { useEffect, useState } from 'react'
import { Fade } from "@material-ui/core";
import { SimpleCheckbox } from "../../common/CheckBox/CheckBox";
import { ITableHeaderItem } from "./types";
import { TasksTableItem } from "./TasksTableItem";
import { IApplicationItem } from "../../../store/applications/types";
import { useDispatch, useSelector } from "react-redux";
import { clearCheckers, setActiveCount, setCheckers } from "../../../redux/reducers/applications/actions/actions";
import { TRootState } from "../../../redux/store";

const s = require('./style.module.scss')

interface ITableProps {
    tableData: any
    fullWidth: number | string
    totalCount: number
    request?: () => void
    flag: string
}

export const Table: React.FC<ITableProps> = ({
    tableData,
    fullWidth,
    totalCount,
    request,
    flag
}) => {

    const checkers = useSelector((state: TRootState) => state.applicationsReducer.checkers)

    const [checked, setChecked] = useState<boolean>(false)

    const dispatch = useDispatch()

    useEffect(() => {
        checked && tableData && tableData.length > 0 && tableData.map((i: IApplicationItem) => {
            dispatch(setCheckers({
                id: i.id
            }))
        })
    }, [checked, tableData])

    return <Fade in timeout={500}>
        <div className={s.container} style={{ width: fullWidth }}>
            <div className={s.headers}>
                {/* <div style={{ flex: '0 0 3%' }}>
                    <SimpleCheckbox
                        value={checked}
                        onChange={() => {
                            setChecked(!checked)
                            if (!checked) {
                                dispatch(setActiveCount(totalCount))

                            } else {
                                dispatch(setActiveCount(0))
                                dispatch(clearCheckers([]))
                            }
                        }}
                    />
                </div> */}
                <div style={{ flex: '0 0 35%' }}>Название</div>
                <div style={{ flex: '0 0 10%' }}>Номер заявки</div>
                <div style={{ flex: '0 0 12%' }}>Крайний срок</div>
                <div style={{ flex: '0 0 25%' }}>Исполнитель</div>
                <div style={{ flex: '0 0 10%' }}>Дата постановки</div>
                <div style={{ flex: '0 0 8%' }}></div>
            </div>
            <div className={s.body}>
                {tableData && tableData.length > 0 && tableData.map((i: IApplicationItem) => <TasksTableItem item={i}
                    request={request}
                    setAllChecked={setChecked}
                    allChecked={checked}
                    flag={flag} />)}
            </div>
        </div>
    </Fade>
}