import React, {useEffect, useState} from "react";
import {IModal} from "../../../../store/sidebar/types.data";
import {observer} from "mobx-react-lite";
import {Dialog, ListItem} from "@material-ui/core";
import {ModalHeader} from "../../../common/Header/ModalHeader/ModalHeader";
import {SimpleSelect} from "../../Selects/SimpleSelect";
import {TEnumItem} from "../../utils";
import {SimpleTextArea} from "../../Inputs/Textarea";
import {ApplicationsStore} from "../../../../store/applications/Applications";
import {openNotification} from "../../notification";

const s = require('./ChangeApplicationStatusModal.module.scss')

interface IChangeApplicationStatusModalProps extends IModal {
    statuses: TEnumItem[]
    appId: number
}

export const ChangeApplicationStatusModal: React.FC<IChangeApplicationStatusModalProps> = observer(({
                                                                                                        title,
                                                                                                        open,
                                                                                                        closeHandler,
                                                                                                        statuses,
                                                                                                        appId
                                                                                                    }) => {

    const {requestIudApplication, requestApplications} = ApplicationsStore

    const [statusId, setStatusId] = useState<number | null>(null)


    return <Dialog open={open}>
        <ModalHeader title={title} closeHandler={closeHandler}/>
        <div className={s.body}>
            <div className={s.item}>
                <div className={s.title}>
                    Выберите категорию :
                </div>
                <div className={s.value}>
                    <SimpleSelect
                        items={statuses || []}
                        onChange={setStatusId}
                        size={'xs'}
                    />
                </div>
            </div>
            <div className={s.item}>
                <div className={s.title}>
                    Причина :
                </div>
                <div className={s.value}>
                    <SimpleTextArea
                        onChange={() => {
                        }}
                    />
                </div>
            </div>
        </div>
        <div className={s.footer}>
            <div className={s.btns}>
                <ListItem
                    button
                    onClick={() => {
                        closeHandler()
                    }}
                    style={{
                        background: 'white',
                        color: 'black',
                        border: '1px solid black',
                        borderRadius: 4,
                        display: 'flex',
                        justifyContent: 'center',
                        width: 88
                    }}
                >Отмена</ListItem>
                <ListItem
                    button
                    onClick={() => {
                        statusId ?
                            requestIudApplication({
                                id: appId,
                                flag: 'status',
                                status: {
                                    id: statusId
                                }
                            }, () => {
                                openNotification({
                                    type: 'success',
                                    message: 'Статус заявки успешно изменен'
                                })
                                    //TODO Добавить перезапрос сюда
                                requestApplications({})
                                closeHandler()
                            })
                            : openNotification({
                                type: 'warning',
                                message: 'Выберите статус заявки'
                            })
                    }}
                    style={{
                        background: 'black',
                        color: 'white',
                        borderRadius: 4,
                        display: 'flex',
                        justifyContent: 'center',
                        width: 96
                    }}
                >Завершить</ListItem>
            </div>
        </div>
    </Dialog>
})