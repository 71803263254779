import {all} from "redux-saga/effects";
import {directoryWatcher} from "./reducers/directory/actions";
import {authWatcher} from "./reducers/auth/actions";
import {applicationsWatcher} from "./reducers/applications/actions";

export function* rootSaga() {
    yield all([
        directoryWatcher(),
        authWatcher(),
        applicationsWatcher()
    ])
}