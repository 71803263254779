import React, { FC, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { LaunchStore } from "../store/launch/launch";
import { Suspense } from "react";
import { ROUTE_PATH } from "./paths.main";
import { Sidebar } from "../components/Sidebar/Sidebar";
import { MyBidsSwitch } from "./myBids/Switch";
import { Workers } from "../components/Workes/Workers";
import { CalendarPage } from "../components/Calendar/CalendarPage";
import { Authorization } from "../components/Authorization/Authorization";
import { ApplicationInfo } from "../components/ApplicationInfo/ApplicationInfo";
import { MyApplicationSwitch } from "./applications/Switch";
import { MyApplication } from "../components/MyApplication/MyApplication";
import { HistoryPage } from "../components/History/History";
import { ExtraPageSwitch } from "./directory/Switch";
import { useDispatch } from "react-redux";
import { getMyInfo } from "../redux/reducers/auth/actions/actions";
import { Test } from "../test/Test";
import { Reports } from "../components/Reports/Reports";
import { Settings } from "../components/Settings/Settings";


export const RouterIndex: FC = observer(({ }) => {
       const { appLoading } = LaunchStore
       const dispatch = useDispatch()
       const Loader = <div>Загрузка...</div>
       if (appLoading) {
              return Loader
       }



       useEffect(() => {
              // dispatch(getMyInfo({}))
       }, [])


       return (
              <BrowserRouter>
                     <Suspense fallback={Loader}>
                            <Switch>
                                   <Route exact
                                          path={'/'}
                                          component={() => <Redirect to={ROUTE_PATH.authorization} />}
                                   />
                                   <Route exact
                                          path={ROUTE_PATH.login}
                                          component={() => <div>Логин</div>}
                                   />
                                   <Sidebar>
                                          <MyBidsSwitch />
                                          <Route exact
                                                 path={ROUTE_PATH.favourite}
                                                 component={() => <MyApplication title={'Избранное'} flag={'favourite'} />}

                                          />

                                          <Route exact
                                                 path={ROUTE_PATH.archive}
                                                 component={() => <MyApplication title={'Архив'} flag={'archive'} />}
                                          />

                                          <Route exact
                                                 path={ROUTE_PATH.deleted}
                                                 component={() => <MyApplication title={'Удаленные'} flag={'deleted'} />}

                                          />

                                          <Route exact
                                                 path={ROUTE_PATH.calendar}
                                                 component={() => <CalendarPage />}
                                          />
                                          <Route exact
                                                 path={ROUTE_PATH.workers}
                                                 component={() => <Workers />}
                                          />
                                          <Route exact
                                                 path={ROUTE_PATH.history}
                                                 component={() => <HistoryPage />}
                                          />
                                          <MyApplicationSwitch />
                                          <Route exact
                                                 path={ROUTE_PATH.authorization}
                                                 component={() => <Authorization />}
                                          />
                                          <Route path={`${ROUTE_PATH.applicationInfo}/:id/:flag`}
                                                 component={() => <ApplicationInfo />}
                                          />
                                          <Route path={ROUTE_PATH.reports}
                                                 component={() => <Reports />}
                                          />
                                            <Route path={ROUTE_PATH.settings}
                                                 component={() => <Settings />}
                                          />

                                          <ExtraPageSwitch />
                                   </Sidebar>
                            </Switch>
                     </Suspense>
              </BrowserRouter>
       )
})