import React, {FC, useEffect, useState} from "react";
import {TRootState} from "../../../redux/store";
import {connect, useSelector} from "react-redux";
import {getDirectoryCategories, setChosenGroupId, setIudFlag} from "../../../redux/reducers/directory/actions/actions";
import {IGetDirectoryCategories, ISetChosenGroupId, ISetIudFlag} from "../../../redux/reducers/directory/actions/types";
import {IDirectoryCategory} from "../../../redux/reducers/directory/types.data";
import {Fallback} from "../../helpers/Fallback/Fallback";
import {DirectoryItem} from "../Groups/DirectoryItem/DirectoryItem";
import {MyPagination} from "../../helpers/MyPagination/MyPagination";

const s = require('../Groups/groups.module.scss')

interface ICategoryProps {
    categories: IDirectoryCategory[] | null
    loading: boolean
    getDirectoryCategories: IGetDirectoryCategories
    setChosenGroupId: ISetChosenGroupId
    setIudFlag: ISetIudFlag
    categoriesCount: number
}

const Category: FC<ICategoryProps> = ({
                                          getDirectoryCategories,
                                          categories,
                                          loading,
                                          setChosenGroupId,
                                          setIudFlag,
                                          children,
                                          categoriesCount
                                      }) => {
    const PAGE_SIZE: number = 15

    const [currentPage, setCurrentPage] = useState<number>(1)
    const myInfo = useSelector((state: TRootState) => state.authReducer.myInfo)
    const href = window.location.href.split('/')[window.location.href.split('/').length - 1]                               


    const searchHandler = () => {
        getDirectoryCategories({
            offset: PAGE_SIZE * (currentPage - 1),
            count: PAGE_SIZE
        })
    }


    useEffect(() => {
        searchHandler()
    }, [currentPage])

    // useEffect который выкидывает со страницы если нет прав на таковую
    useEffect(() => {
        if (myInfo) {
            let formviewsPages = myInfo?.formviews?.filter(element => element?.type_id === 2).map(element => element?.mnemo)
            if (formviewsPages?.includes('directory') && href === 'categories') {
                return
            }
            else {
                window.location.href = '/'
            }
        }
    }, [href, myInfo])

    return <div className={s.container}>
        {loading ? <Fallback/> : <div className={s.main}>
            {categories && categories.length > 0 && categories.map((i) => <DirectoryItem
                searchHandler={searchHandler}
                setIudFlag={setIudFlag}
                setChosenGroupId={setChosenGroupId} flag={'C'} category={i}/>)}
        </div>}

        <MyPagination
            onChange={setCurrentPage}
            currentPage={currentPage}
            pageSize={PAGE_SIZE}
            total={categoriesCount}
        />
    </div>
}

const MapStateToProps = (state: TRootState) => ({
    categories: state.directoryReducer?.categories,
    categoriesCount: state.directoryReducer?.categoriesCount,
    loading: state.directoryReducer?.loadings?.categoryLoading
})

export const CategoriesContainer = connect(MapStateToProps, {
    getDirectoryCategories,
    setChosenGroupId,
    setIudFlag
})(Category)