const BurgerMenuIcon = (props) => {
  const color = props?.color;
  return (
    <svg
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      enable-background="new 0 0 32 32"
      id="Filled_Line"
    >
      <line
        fill="none"
        id="XMLID_837_"
        stroke={color || "#200F60"}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="2"
        x1="7"
        x2="25"
        y1="16"
        y2="16"
      />
      <line
        fill="none"
        id="XMLID_836_"
        stroke={color || "#200F60"}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="2"
        x1="7"
        x2="25"
        y1="25"
        y2="25"
      />
      <line
        fill="none"
        id="XMLID_835_"
        stroke={color || "#200F60"}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="2"
        x1="7"
        x2="25"
        y1="7"
        y2="7"
      />
    </svg>
  );
};

export default BurgerMenuIcon;
