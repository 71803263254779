import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { ListItem } from "@material-ui/core";
import { IFilterButtonItem } from "../../helpers/utils";
import { Lines } from "../../../icons/icons";
import { SidebarStore } from "../../../store/sidebar/sidebarMobx";
import { Filters } from "../../common/Header/Filters/Filters";
import { CreateGroupModal } from "../../common/Header/CreateGroup/CreateGroupModal";
import { ApplicationsStore } from "../../../store/applications/Applications";
import { AddWorkersToGroupModal } from "../Modal/AddWorkersToGroupModal";
import { VerifyDeleteWorkersModal } from "../Modal/VerifyDeleteWorkersModal";

const s = require('./FilterButtonsItem.module.scss')

interface IFilterButtonsItemProps {
    item: IFilterButtonItem
    flag: 'employees' | 'myApplications' | 'history'
    selectedWorkers?: any
    requestHandler?: (value?: string) => void
    request?: (value?: string) => void

}

export const FilterButtonsItem: React.FC<IFilterButtonsItemProps> = observer(({
    item,
    flag,
    selectedWorkers,
    requestHandler,
    request
}) => {

    const { applications, requestApplications, setActiveFlag, activeFlag } = ApplicationsStore

    const { icon, name, width } = item

    const [showFilters, setShowFilters] = useState<boolean>(false)
    const [addGroup, setAddGroup] = useState<boolean>(false)
    const [count, setCount] = useState<number | null>(null)
    const [flagState, setFlagState] = useState<string | null>(null)
    const [active, setActive] = useState<boolean>(false)
    const [deleteWorkers, setDeleteWorkers] = useState<boolean>(false)

    //-------------------------------------------------> Окно добавления в группу
    const [addToGroup, setAddToGroup] = useState<boolean>(false)
    const [check, setChecked] = useState<boolean>(false)


    useEffect(() => {
        if (applications) switch (name) {
            case 'Все':
                setCount(applications?.all)
                setFlagState('all')
                return;
            case 'Новые':
                setCount(applications?.new)
                setFlagState('new')
                return;
            case 'Просроченные':
                setCount(applications?.dismissed)
                setFlagState('dismissed')
                return;
            case 'В работе':
                setCount(applications?.inWork)
                setFlagState('inWork')
                return;
            case 'Назначенные':
                setCount(applications?.designated)
                setFlagState('designated')
                return;
            case 'Наблюдаемые':
                setCount(applications?.observed)
                setFlagState('observed')
                return;
            case 'Ждёт ответа':
                setCount(applications?.waitingAnswer)
                setFlagState('waitingAnswer')
                return;
            default:
                return;
        }
    }, [applications])

    useEffect(() => {
        setActive(activeFlag === item?.flag)
        
    }, [activeFlag])



    return <div>
        <ListItem button
            disabled={!!(flag === 'employees' && selectedWorkers && selectedWorkers.length < 1) || check}
            style={active ? {
                width: window.outerWidth <= 425 ? '100%' : width,
                height: 38,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '14px',
                fontWeight: 400,
                background: '#E5F3FF',
                border: '1px solid #0085FF',
                borderRadius: '5px',
                color: '#0085FF'
            } : {
                width: window.outerWidth <= 425 ? '100%' : width,
                height: 38,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '14px',
                fontWeight: 400,
                border: '1px solid #CED4DA',
                borderRadius: '5px',
            }}
            onClick={() => {
                if (!check) {
                    active ? setActiveFlag(null) : setActiveFlag(item?.flag || null)
                    icon && setShowFilters(true)
                    
                    switch (name) {
                        case 'Создать группу':
                            setAddGroup(true)
                            return
                        case 'Добавить в группу':
                            console.log('a')
                            setChecked(true)
                            setAddToGroup(true)
                            return;
                        case 'Удалить выбранных сотрудников':
                            setChecked(true)
                            setDeleteWorkers(true)
                            return;
                        default:
                            request && request(active ? undefined : item?.flag || undefined)
                            if (item?.flag) {
                                
                                active ? localStorage.removeItem('flag')
                                    : localStorage.setItem('flag', item?.flag)
                            }
                            return;
                    }
                }
            }}
        >
            {icon ? <Lines /> : name}
            <span style={{ marginLeft: 4 }}>{count && `(${count})`}</span>
            {showFilters && <Filters
                flag={flag}
                title={'Фильтрация'}
                open={showFilters}
                closeHandler={() => setShowFilters(false)}
            />}
            {addGroup && <CreateGroupModal
                title={'Создать группу'}
                open={addGroup}
                closeHandler={() => setAddGroup(false)}
            />}
            {addToGroup && <AddWorkersToGroupModal
                requestHandler={requestHandler}
                selectedWorkers={selectedWorkers}
                title={'Добавить сотрудников в группу'}
                open={addToGroup}
                closeHandler={() => {
                    setChecked(false)
                    setAddToGroup(false)
                }}
            />}
            {deleteWorkers && <VerifyDeleteWorkersModal
                requestHandler={requestHandler}
                selectedWorkers={selectedWorkers}
                title={'Вы уверены, что хотите удалить сотрудников?'}
                open={deleteWorkers}
                closeHandler={() => {
                    setChecked(false)
                    setDeleteWorkers(false)
                }}
            />}

        </ListItem>
    </div >
})