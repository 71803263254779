import { makeAutoObservable, runInAction } from "mobx";
import { instance } from "../api";
import { checkError, isError } from "../api/helpers";

class SettingsStore {
    settings: any 
    settingsCount: any
    settingsLoading: boolean = false
    usersLoading: boolean = false
    sourcesLoading: boolean= false
    users: any
    sources: any


    constructor() {
        makeAutoObservable(this)
    }

    requestSettings = async () => {
        this.settingsLoading = true
        try {
            const res = await instance().close().getSettings()
            this.settings = res.data
            
        } catch (e) {
            checkError(e)
            isError(e)
        }
        this.settingsLoading = false
    }

    editSettings = async (data: any) => {
        this.settingsLoading = true
        try {
            await instance().close().postSettings(data)
        } catch (e) {
            checkError(e)
            isError(e)
        }
        this.settingsLoading = false
    }

    createSettings = async (data: any) => {
        this.settingsLoading = true
        try {
            await instance().close().putSettings(data)
        } catch (e) {
            checkError(e)
            isError(e)
        }
        this.settingsLoading = false
    }

    deleteSettings = async (data: any) => {
        this.settingsLoading = true
        try {
            await instance().close().deleteSettings(data)
        } catch (e) {
            checkError(e)
            isError(e)
        }
        this.settingsLoading = false
    }

    requestSources = async () => {
        this.sourcesLoading = true
        try {
            const res = await instance().close().getSourcess()
            this.sources = res.data
            
        } catch (e) {
            checkError(e)
            isError(e)
        }
        this.sourcesLoading = false
    }

    requestUsers = async (data: any) => {
        this.usersLoading = true
        try {
            const res = await instance().close().getUserss(data)
            this.users = res.data
            
        } catch (e) {
            checkError(e)
            isError(e)
        }
        this.usersLoading = false
    }
}

const SettingsPageStore = new SettingsStore()

export default SettingsPageStore;