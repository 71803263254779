import React from 'react';

export const itemRender = (
    page: number,
    type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
    originalElement: React.ReactNode
): React.ReactNode => {
    if (type === 'prev') {
        return (
            <span>
                Предыдущая
            </span>
        );
    }
    if (type === 'next') {
        return (
            <span>
                Следующая
            </span>
        );
    }
    return originalElement;
};