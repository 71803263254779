import React, {FC} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {ROUTE_PATH} from "../paths.main";

export const MyBidsSwitch: FC = () => {
    return (
        <Route exact={false}
               path={ROUTE_PATH.myBids.main}>
            <Switch>
                <Route exact
                       path={ROUTE_PATH.myBids.main}
                       component={() => <Redirect to={ROUTE_PATH.myBids.newBids}/>}
                />

                <Route exact
                       path={ROUTE_PATH.myBids.newBids}
                       component={() => <div>Мои заявки - новые</div>}
                />

                <Route exact
                       path={ROUTE_PATH.myBids.overdueBids}
                       component={() => <div>Мои заявки - просроченные</div>}
                />

                <Route exact
                       path={ROUTE_PATH.myBids.inProgressBids}
                       component={() => <div>Мои заявки - в работе</div>}
                />

                <Route exact
                       path={ROUTE_PATH.myBids.assignedBids}
                       component={() => <div>Мои заявки - назначенные</div>}
                />

                <Route exact
                       path={ROUTE_PATH.myBids.observedBids}
                       component={() => <div>Мои заявки - наблюдаемые</div>}
                />

                <Route exact
                       path={ROUTE_PATH.myBids.pendingBids}
                       component={() => <div>Мои заявки - ждет ответа</div>}
                />

            </Switch>
        </Route>
    )
}