import {Pagination} from 'antd';
import React from 'react'
import {itemRender} from './itemRender';
import {IPagination} from "./types";
import clsx from "clsx";

const classes = require('./classes.module.scss')


export const MyPagination: React.FC<IPagination> = ({
                                                        total,
                                                        onChange,
                                                        pageSize,
                                                        currentPage,
                                                        paddingLeft,
                                                        ...props
                                                    }) => {
    return (
        <div className={clsx(classes.pagination, props.className)}
             style={paddingLeft ? {paddingLeft: paddingLeft} : {}}>
            <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={total}
                onChange={onChange}
                itemRender={itemRender}
                showSizeChanger={false}
                {...props}
            />
        </div>
    )
}