import React, { useEffect, useState } from "react";
import { TRootState } from "../../../redux/store";
import { connect } from "react-redux";
import { observer } from "mobx-react-lite";
import { Link, useHistory } from "react-router-dom";
import { ROUTE_PATH } from "../../../routers/paths.main";
import { ArrowIcon } from "../../../icons/icons";
import { SimpleInput } from "../../helpers/Inputs/inputs";
import { SimpleTextArea } from "../../helpers/Inputs/Textarea";
import { ListItem } from "@material-ui/core";
import { SimpleColorPicker } from "../../helpers/ColorPicker/ColorPicker";
import { DirectoryStore } from "../../../store/directory/DirectoryStore";
import { SimpleSelect } from "../../helpers/Selects/SimpleSelect";
import { openNotification } from "../../helpers/notification";


const s = require('./style.module.scss')

interface IIudMarkProps {

}

export const IudMark: React.FC<IIudMarkProps> = observer(({ }) => {

    const router = useHistory()

    const [name, setName] = useState<string>('#')
    const [description, setDescription] = useState<string>('')
    const [textColor, setTextColor] = useState<string>('')
    const [textColorValue, setTextColorValue] = useState<string>('')
    const [backgroundColor, setBackgroundColor] = useState<string>('')
    const [backgroundColorValue, setBackgroundColorValue] = useState<string>('')

    const [disabledButton, setDisabledButton] = useState<boolean>(false)

    const { requestColors, colors, colorsLoading, markItemFullInfo, iudFlag, requestIudMark, colorsList } = DirectoryStore

    const isMobile = window.outerWidth <= 425


    useEffect(() => {
        requestColors()
    }, [])

    useEffect(() => {
        if (iudFlag === 'U') {
            setName(markItemFullInfo?.name || '')
            setTextColor(markItemFullInfo?.colorId || '')
            setBackgroundColor(markItemFullInfo?.backgroundId || '')
        }
    }, [markItemFullInfo])

    const iudHandler = () => {
        switch (iudFlag) {
            case "I":
                requestIudMark({
                    background_color: backgroundColorValue,
                    flag: 'I',
                    id: markItemFullInfo?.id,
                    name,
                    description,
                    text_color: textColorValue,
                    colorId: textColor,
                    backgroundId: backgroundColor
                }, () => {
                    openNotification({
                        type: 'success',
                        message: 'Создание прошло успешно'
                    })
                    router.push('/directory/marks')
                })
                return
            case "U":
                requestIudMark({
                    background_color: backgroundColorValue,
                    flag: 'U',
                    id: markItemFullInfo?.id,
                    name,
                    description,
                    text_color: textColorValue,
                    colorId: textColor,
                    backgroundId: backgroundColor
                }, () => {
                    openNotification({
                        type: 'success',
                        message: 'Редактирование прошло успешно'
                    })
                    router.push('/directory/marks')
                })
                return;
            default:
                return;
        }
    }

    return <div className={s.iudMark}>
        <Link to={ROUTE_PATH.extraPage.marks}>
            <div style={{ display: 'flex', alignItems: "baseline" }}>
                <ArrowIcon style={{
                    transform: 'rotate(180deg)'
                }} />
                <div style={{ color: 'black', fontWeight: 600, fontSize: 24, marginLeft: 6 }}>
                    НАЗАД
                </div>
            </div>
        </Link>
        <div className={s.item}>
            <div className={s.title}>
                Наименование:
            </div>
            <SimpleInput
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
            />
        </div>

        <div className={s.item}>
            <div className={s.title}>
                Цвет текста:
            </div>
            {/*
            Нужно поработать с onChange
            <SimpleColorPicker />
            */}
            <SimpleSelect
                items={colors}
                loading={colorsLoading}
                onChange={(value, option) => {
                    setTextColor(option.value)
                    setTextColorValue(colorsList.find(colorListItem => colorListItem.name === option.children)?.value || '')
                }}
                value={textColor}
            />
        </div>
        <div className={s.item}>
            <div className={s.title}>
                Цвет области:
            </div>
            <SimpleSelect
                items={colors}
                loading={colorsLoading}
                onChange={(value, option) => {
                    setBackgroundColor(option.value)
                    setBackgroundColorValue(colorsList.find(colorListItem => colorListItem.name === option.children)?.value || '')
                }}
                value={backgroundColor}
            />
        </div>


        {/* <div className={s.item}>
            <div className={s.title}>
                Описание:
            </div>
            <SimpleTextArea
                onChange={(e) => setDescription(e.currentTarget.value)}
            />
        </div>*/}
        <div className={s.divider}>
        </div>

        <div className={s.btns}>
            <ListItem
                style={{
                    width: isMobile ? '100%' : 104,
                    height: 37,
                    background: 'black',
                    border: '1px solid #212529',
                    borderRadius: 5,
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    cursor: disabledButton ? 'not-allowed' : 'pointer'
                }}
                button
                disabled={disabledButton}
                onClick={iudHandler}
            >
                Сохранить
            </ListItem>
        </div>
    </div>
})