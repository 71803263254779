import React, { useEffect, useState } from 'react'
import { Header } from '../common/Header/Header';
import { HeadTitle } from '../common/HeadTitle/PageTitle';
import { TopSidebarButton } from '../TopSidebarButton/TopSidebarButton';
import { SimpleCheckbox } from '../common/CheckBox/CheckBox';
import ReportPageStore from '../../store/reportsPage/ReportsStore';
import { RangeDatePicker } from '../NewApplication/DatePicker/DatePickerComponent';
import moment, { Moment } from "moment";
import { formatIsoToDate } from '../helpers/utils';
import { ListItem } from "@material-ui/core";
import Table, { ColumnsType } from 'antd/lib/table';
import { ConfigProvider, Tooltip } from 'antd';
import { observer } from "mobx-react-lite";
import ruRu from 'antd/lib/locale/ru_RU';

const s = require('./style.module.scss');

interface IReportProps {

}

export const Reports: React.FC<IReportProps> = observer(({ }) => {

    const isMobile: boolean = window.outerWidth <= 425
    const { 
            requestReportsListOrItem, 
            reports,
            reportsLoading,
            reportsCount,
            getReportXlSX
        } = ReportPageStore
    //Таблицы
    const scroll: { x?: number | string; y?: number | string } = { x: 1000, y: 700 };
    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(25)

    const [searchValue, setSearchValue] = useState<string>('')

    const [checked, setChecked] = useState<boolean>(false)

    const [startDate, setStartDate] = useState<string | undefined>(undefined)
    const [endDate, setEndDate] = useState<string | undefined>(undefined)

    const requestReportsHandler = () => {
        requestReportsListOrItem({
            startDate: startDate ? startDate.substring(0, startDate.length - 9) : undefined,
            endDate: endDate ? endDate.substring(0, endDate.length - 9) : undefined
        }).then((res) => {
            console.log(res)
        })
    }

    const onClickGetXLSX = () => {
        getReportXlSX({
            "reportName": "TicketReport",
            "fileExtension": "xlsx",
            "paramList": [
                {
                    "name": "request",
                    "value": {
                        "startDate": startDate ? startDate.substring(0, startDate.length - 9) : undefined,
                        "endDate": endDate ? endDate.substring(0, endDate.length - 9) : undefined
                    }
                }
            ]
        })
    }
    

    useEffect(() => {
        requestReportsHandler()
    }, [])

    interface DataType {
        "task": string
        "isComplete": string,
        "userExecutor": string,
        "userCompleted": string
    }

    interface DataTypeTotal {
        "processed": string,
        "completed": string

    }
    const countData = [reportsCount]

    console.log(countData)
    
    const totalData: DataTypeTotal[] = countData?.map((element: 
        {
        processed: any, 
        completed: any
        }) => {
        return(
            {
                "processed": element?.processed.toString(),
                "completed": element?.completed.toString()
            }
        )
    })



    const data: DataType[] = reports?.tickets?.map((element: 
        {
            task: string, 
            isComplete: boolean, 
            userExecutor: string, 
            userCompleted: string
        }) => {
        return (
            {
                "task": element.task,
                "isComplete": element.isComplete.toString() == 'true' ? 'Завершено' : 'В работе',
                "userExecutor": element.userExecutor,
                "userCompleted": element.userCompleted,
            }
        )
    })
    console.log(data)

    const totalHeaders = [
        {
            key: "processed", 
            title: "В работе", 
            dataIndex: "processed",
        },
        {
            key: "completed", 
            title: "Завершено", 
            dataIndex: "completed",
        }
    ]

    const headers = [
    {
        key: "task", 
        title: "Задача", 
        dataIndex: "task",
    },
    {
        key: "isComplete", 
        title: "В работе/завершена", 
        dataIndex: "isComplete",
    },
    {
        key: "userExecutor", 
        title: "Текущий исполнитель", 
        dataIndex: "userExecutor",
    },
    {
        key: "userCompleted", 
        title: "Завершил ", 
        dataIndex: "userCompleted",
    }
    ]

    const totalColumns: ColumnsType<DataTypeTotal> = totalHeaders.map((element: any, index: number) => {
        return ({
            key: element.key,
            title: element.title,
            dataIndex: element.dataIndex,
            ellipsis: {
                showTitle: false,
            },
            width: 180,
            render: (address: any) => {
                return (
                    <Tooltip trigger="hover" placement="topLeft" title={typeof address === 'string' ? address : ''}>
                        {typeof address === 'string' ? address : ''}
                    </Tooltip>
                )
            },
        });
    }) || [];

    const columns: ColumnsType<DataType> = headers.map((element: any, index: number) => {
        return ({
            key: element.key,
            title: element.title,
            dataIndex: element.dataIndex,
            ellipsis: {
                showTitle: false,
            },
            width: 180,
        });
    }) || [];

    return (
        <ConfigProvider locale={ruRu}>
        <div className={s.main_page_wrap}>
            <Header searchValue={searchValue} setSearchValue={setSearchValue}
                request={(value) => { }} />
            <div className={s.title_wrap}>
                <HeadTitle title={'Количество заявок'} />
            </div>
            <div className={s.items}>
                <div className={s.title_content}>
                    <Table 
                        dataSource={totalData} 
                        columns={totalColumns} 
                        pagination={false} 
                        bordered
                        loading={reportsLoading}
                        /> 
                </div>
            </div>
            <div className={s.title_wrap}>
                <HeadTitle title={'Отчеты'} />
            </div>
            <div className={s.filters}>
            <RangeDatePicker
                width={isMobile ? '100%' : '80%'}
                onChange={(value: any) => {
                    if (value?.length === 2) {
                        setStartDate(moment(value[0]).format())
                        setEndDate(moment(value[1]).format())
                    } else {
                        setStartDate(undefined)
                        setEndDate(undefined)
                    }
                }}
                value={undefined}
            />
            <ListItem
                button
                onClick={() => requestReportsHandler()}
                style={{
                    width: isMobile ? '100%' : 90,
                    marginTop: isMobile ? 4 : 'unset',
                    height: 37,
                    borderRadius: 4,
                    background: 'black',
                    color: 'white',
                    marginLeft: 6,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            > Поиск</ListItem>

            <ListItem
                button
                onClick={() => onClickGetXLSX()}
                style={{
                    width: isMobile ? '100%' : 90,
                    marginTop: isMobile ? 4 : 'unset',
                    height: 37,
                    borderRadius: 4,
                    background: 'black',
                    color: 'white',
                    marginLeft: 6,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >Отчет</ListItem>
            </div>
            <div className={s.items}>
                <div className={s.title_content}>
                    <Table 
                    // size='large'
                    dataSource={data} 
                    columns={columns} 
                    bordered
                    loading={reportsLoading}
                    scroll={scroll}
                    pagination={{
                    position: ['topLeft', 'bottomLeft'],
                    pageSizeOptions: ['25', '50', '75', '100'],
                    // defaultPageSize: 25,
                    }}
                    /> 
                </div>
            </div>
        </div>
        </ConfigProvider>
    )
})

