import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { IModal } from "../../../store/sidebar/types.data";
import { Dialog, ListItem } from "@material-ui/core";
import { ModalHeader } from "../../common/Header/ModalHeader/ModalHeader";
import { formatIsoToDate, formatIsoToTime } from "../../helpers/utils";
import { SimpleCheckbox } from "../../common/CheckBox/CheckBox";
import useDebouncedFunction from "../../helpers/useDebounceFunction";
import { debug } from "console";
import SettingsPageStore from "../../../store/settingsPage/SettingsStore";
import { Input, Select, TreeSelect } from "antd";
import { SimpleTreeSelect } from "../../helpers/Selects/SimpleTreeSelect";

const s = require('./style.module.scss')

interface IInfoModal extends IModal {
    flag: string
    requestHandler: Function
    sorces: any
    users: any
    pults: any
    data: any
}

interface StateModal {
    sourceId: any
    panelName: string
    userName: string
}

export const ModalSettings: FC<IInfoModal> = ({
    open,
    closeHandler,
    flag,
    requestHandler,
    sorces,
    users,
    pults,
    data
}) => {
  
    const [newGbrs, setNewGbrs] = useState([])
    const [territoryName, setTerritoryName] = useState<string | undefined>(undefined)
    const [markedTerritories, setMarkedTerritories] = useState<any[]>([])
    const [searchValue, setSearchValue] = useState('')

    const [pultName, setPultName] = useState<any>()
    const [userName, setUserName] = useState<any>()
    const [sourceId, setSourceId] = useState<any>()
    const [newUsers, setNewUsers] = useState<any>()
    const [titleName, setTitleName] = useState<any>()

    const [stateData, setStateData] = useState<StateModal>()

    const {
        deleteSettings,
        requestSettings,
        editSettings,
        createSettings,
    } = SettingsPageStore
    // const [searchData, setSearchData] = useState<any>(gbrList)
    // const item = arrData?.find((el: { id: any; })  => el.id ===  territoryInfo?.id)
    console.log(data)
    console.log(users)
    useEffect(() => {
        const map = new Map();
        const result = [];
        let arr = users ? [...users] : []
        arr.forEach(item => {
            map.set(item.id, { value: item.name, title: item.name, id: item.id});
        });
        arr.forEach(item => {
            if (!item?.pId) {
                result.push(map.get(item.id));
            } else {
                const parent = map.get(item.pId);
                if (parent) {
                    if (!Object.keys(parent).includes('children')) {
                        parent.children = []
                    }
                    parent.children.push(map.get(item.id));
                }
            }
        });
        setNewUsers(result)
    }, [users])
    console.log(userName)
    // useEffect(() => {
    //     if(flag === 'post' || flag === 'delete'){
    //         setNewGbrs(item?.gbrs?.map((element: any) => {
    //             markedTerritories.push(element.gbrId)
    //         }
    //         ))
    //         setTerritoryName(item?.territoryName)
    //     }
    // }, [open])



    // const changeTerritoryName = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setTerritoryName(e.target.value)
    // }
    console.log(data)
    console.log(sourceId)
    useEffect(() => {
        if(flag === 'post'){
        setPultName(data?.panel)
        setUserName(data?.idPerformer)
        setSourceId(data?.sourceId)
        setTitleName(data?.performerName)
        }
    }, [flag])
    const handleClose = () => {
        setPultName(null)
        setUserName(null)
        setSourceId(null)
        setTitleName('')
        setMarkedTerritories([])
        setTerritoryName('')
    }

    const handleSave = () => {
        if(flag === 'post'){
            editSettings({ 
                panel: pultName,
                id_source: sourceId,
                id_performer: userName,
                id: data?.id
            }).then((res) => {
                console.log(res)
                handleClose()
                closeHandler()
                requestSettings()
            })
        }else if(flag === 'put'){
            createSettings({ 
                panel: pultName,
                id_source: sourceId,
                id_performer: userName
            }).then(() => {
                handleClose()
                closeHandler()
                requestSettings()
            })
        }else{
            deleteSettings({
                id: data?.id
            }).then(() => {
                handleClose()
                closeHandler()
                requestHandler()
            })
        }
        }

 
 

    const { Search } = Input;
    console.log(userName)
    console.log(pultName)
    console.log(sourceId)
    console.log(users)
    let a: any = []
    const onChange =(value, option) => {
        console.log(value)
        console.log(option)
        setUserName(value)
  
    } 
  return <Dialog open={open} maxWidth={'md'}>
    <ModalHeader title={flag === "post" ? 
        `Редактирование`:
        flag === "put" ?
        'Создание' :
        `Удаление`
    } closeHandler={() => {closeHandler(); handleClose();}} />
    <>
        {
            flag === "post" || flag === 'put' ?
            <div className={s.post}>
                <div className={s.post__leftSide} style={{padding:'10px !important'}}>
                    <div className={s.post__leftSide__margin}>
                    <div>Источник</div>
                    <Select
                    style={{ width: '100%' }}
                    value={sourceId}
                    onChange={(val: any) => { setSourceId(val)}}
                    showSearch 
                    allowClear
                    optionFilterProp="children"
                    className={s.selector}
                    dropdownClassName={s.CustomDropDown}
                    >
                        {sorces.map(sorce => 
                            <Select.Option 
                                key={sorce.idSource} 
                                value={sorce.idSource}
                                >
                                {sorce.name} 
                            </Select.Option>    
                                )}
                    </Select>
                    </div>
                    <div className={s.post__leftSide__margin}>
                    <div>Исполнитель</div>
                    <TreeSelect
                        showSearch
                        style={{ width: '100%' }}
                        value={titleName}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: 999999 }}
                        placeholder=""
                        allowClear
                        treeDefaultExpandAll
                        onChange={(value, option) => {
               
                            console.log(value)
                            newUsers.map(item => {
                                if(value) {
                                    console.log(value)
                                    
                                    if (value === item.value){
                                        
                                        setUserName(item.id)
                                        setTitleName(item.value)
                                    }
                                }
                            })
                        }}
                        treeData={newUsers}
                    />
                    </div>
                    <div className={s.post__leftSide__margin}>
                    <div>Панель</div>
                    <Select
                        style={{ width: '100%' }}
                        value={pultName}
                        dropdownClassName={s.CustomDropDown}
                        showSearch 
                        allowClear
                        optionFilterProp="children"
                        className={s.selector}
                        onChange={(val: any) => { setPultName(val)}}

                    >
                        {pults.map(pult => 
                            <Select.Option 
                                key={pult.name} 
                                value={pult.name}
                                >
                                {pult.name} 
                            </Select.Option>    
                                )}
                    </Select>
                    </div>
                    <div  className={s.post__leftSide__save}style={{margin:'10px !important'}}>
                    <ListItem className={s.post__leftSide__save__find} button onClick={() => handleSave()}>
                        Сохранить
                    </ListItem>
                    </div>
                </div>

            </div>
            :
            <div className={s.delete}>
                <div className={s.delete__title}>Вы точно хотите удалить {territoryName}?</div>
                <ListItem className={s.delete__find} button onClick={() => handleSave()}>
                        Удалить
                </ListItem>
            </div>
        }
    </>
  </Dialog>
}
