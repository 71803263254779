import {
    DIRECTORY_ACTIONS,
    SET_APPLICATION_ID,
    SET_CHOSEN_GROUP_ID,
    SET_CURRENT_INITIAL_PAGE,
    SET_DATES_FOR_TASK_FILTER,
    SET_DIRECTORY_CATEGORIES,
    SET_DIRECTORY_CATEGORIES_COUNT,
    SET_DIRECTORY_CATEGORIES_LOADING,
    SET_DIRECTORY_GROUPS,
    SET_DIRECTORY_GROUPS_COUNT,
    SET_DIRECTORY_GROUPS_LOADING,
    SET_DIRECTORY_ROLE_INFO,
    SET_DIRECTORY_ROLES,
    SET_DIRECTORY_ROLES_LOADING,
    SET_DIRECTORY_TEMPLATES,
    SET_DIRECTORY_TEMPLATES_COUNT,
    SET_DIRECTORY_TEMPLATES_LOADING,
    SET_FORMVIEWS,
    SET_FORMVIEWS_LOADING,
    SET_GROUP_FORMVIEWS,
    SET_IUD_FLAG,
    SET_NEW_APPLICATION_OR_TEMPLATE_FLAG,
    SET_PRIORITY,
    SET_PRIORITY_LOADING,
    SET_ROLE_GROUP,
    SET_ROLES_LIST,
    SET_ROLES_LIST_LOADING,
    SET_SUBGROUP_PARENT_ID,
    SET_VISIBLE_PAGES,
    SET_VISIBLE_PAGES_LOADING,
    TDirectoryReducer,
    CLEAR_SUBGROUPS_ROLES
} from "./types";
import { ROUTE_PATH } from "../../../routers/paths.main";
import { IDirectoryRole } from "./types.data";
import { TEnumItem } from "../../../components/helpers/utils";

const initialState: TDirectoryReducer = {
    formviews: [],
    categories: [],
    categoriesCount: 0,
    groups: [],
    groupsCount: 0,
    templates: [],
    templatesCount: 0,
    rolesList: null,
    initialPages: [
        {
            id: 0,
            isActive: true,
            name: 'Группы',
            path: ROUTE_PATH.extraPage.groups,
            iudPath: ROUTE_PATH.extraPage.iudDirectoryItem,
            formview: 'directory_groups'
        },
        {
            id: 1,
            isActive: false,
            name: 'Категории',
            path: ROUTE_PATH.extraPage.categories,
            iudPath: ROUTE_PATH.extraPage.iudDirectoryItem,
            formview: 'directory_categories'
        },
        {
            id: 2,
            isActive: false,
            name: 'Шаблоны',
            path: ROUTE_PATH.extraPage.templates,
            iudPath: ROUTE_PATH.newApplication,
            formview: 'directory_samples'
        },
        // {
        //     id: 3,
        //     isActive: false,
        //     name: 'Роли',
        //     path: ROUTE_PATH.extraPage.roles,
        //     iudPath: ROUTE_PATH.extraPage.iudDirectoryItem,
        //     formview: 'directory_roles'
        // },
        {
            id: 4,
            isActive: false,
            name: 'Пультовые системы',
            path: ROUTE_PATH.extraPage.devices,
            iudPath: ROUTE_PATH.extraPage.iudDevices,
            formview: 'directory_systems'
        },
        {
            id: 5,
            isActive: false,
            name: 'Метки',
            path: ROUTE_PATH.extraPage.marks,
            iudPath: ROUTE_PATH.extraPage.iudMarks,
            formview: 'directory_tags'
        },
        {
            id: 6,
            isActive: false,
            name: 'Праздничные дни',
            path: ROUTE_PATH.extraPage.celebrationDays,
            iudPath: ROUTE_PATH.extraPage.iudCelebrationDay,
            formview: 'celebration_days'
        }
    ],
    loadings: {
        categoryLoading: false,
        groupLoading: false,
        templateLoading: false,
        priorityLoading: false,
        roleLoading: false,
        formviewsLoading: false,
        fullRolesListLoading: false
    },
    categoryFullInfo: null,
    groupFullInfo: null,
    roleFullInfo: null,
    iudFLag: 'addGroup',
    chosenGroupId: null,
    priorityList: [],
    appOrTemplateFlag: 'addApp',
    applicationReduxId: '',
    subGroupParentId: null,
    taskFiltersDate: null,
    fullRolesList: [],
    visiblePages: [],
    visiblePagesLoading: false
}

export const DirectoryReducer = (state = initialState, action: DIRECTORY_ACTIONS) => {
    switch (action.type) {
        case SET_DIRECTORY_CATEGORIES: {
            return {
                ...state,
                categories: action.payload
            }
        }
        case SET_DIRECTORY_CATEGORIES_COUNT: {
            return {
                ...state,
                categoriesCount: action.payload
            }
        }
        case SET_DIRECTORY_CATEGORIES_LOADING: {
            return {
                ...state,
                loadings: { ...state.loadings, categoryLoading: action.payload }
            }
        }
        case SET_DIRECTORY_GROUPS: {
            return {
                ...state,
                groups: action.payload
            }
        }
        case SET_DIRECTORY_GROUPS_COUNT: {
            return {
                ...state,
                groupsCount: action.payload
            }
        }
        case SET_DIRECTORY_GROUPS_LOADING: {
            return {
                ...state,
                loadings: { ...state.loadings, groupLoading: action.payload }
            }
        }
        case SET_DIRECTORY_TEMPLATES: {
            return {
                ...state,
                templates: action.payload
            }
        }
        case SET_DIRECTORY_TEMPLATES_COUNT: {
            return {
                ...state,
                templatesCount: action.payload
            }
        }
        case SET_DIRECTORY_TEMPLATES_LOADING: {
            return {
                ...state,
                loadings: { ...state.loadings, templateLoading: action.payload }
            }
        }

        case SET_CURRENT_INITIAL_PAGE: {
            return {
                ...state,
                initialPages: state.initialPages.map((page) => {
                    if (page.id === action.payload) {
                        return { ...page, isActive: true }
                    } else {
                        return {
                            ...page,
                            isActive: false
                        }
                    }
                })
            }
        }
        case SET_IUD_FLAG: {
            return {
                ...state,
                iudFLag: action.payload
            }
        }

        case SET_CHOSEN_GROUP_ID: {
            return {
                ...state,
                chosenGroupId: action.payload
            }
        }

        case SET_PRIORITY: {
            return {
                ...state,
                priorityList: action.payload
            }
        }

        case SET_PRIORITY_LOADING: {
            return {
                ...state,
                loadings: {
                    ...state.loadings,
                    priorityLoading: action.payload
                }
            }
        }
        case SET_NEW_APPLICATION_OR_TEMPLATE_FLAG: {
            return {
                ...state,
                appOrTemplateFlag: action.payload
            }
        }

        case SET_APPLICATION_ID: {
            return {
                ...state,
                applicationReduxId: action.payload
            }
        }
        case SET_SUBGROUP_PARENT_ID: {
            return {
                ...state,
                subGroupParentId: action.payload
            }
        }

        case SET_DATES_FOR_TASK_FILTER: {
            return {
                ...state,
                taskFiltersDate: action.payload
            }
        }

        case SET_DIRECTORY_ROLES: {
            return {
                ...state,
                rolesList: action.payload
            }
        }

        case SET_DIRECTORY_ROLES_LOADING: {
            return {
                ...state,
                loadings: {
                    ...state.loadings,
                    roleLoading: action.payload
                }
            }
        }

        case SET_FORMVIEWS: {
            return {
                ...state,
                formviews: action.payload
            }
        }

        case SET_FORMVIEWS_LOADING: {
            return {
                ...state,
                loadings: {
                    ...state.loadings,
                    formviewsLoading: action.payload
                }
            }
        }

        case SET_DIRECTORY_ROLE_INFO: {
            return {
                ...state,
                roleFullInfo: action.payload
            }
        }

        case SET_ROLES_LIST: {
            return {
                ...state,
                fullRolesList: action.payload
            }
        }

        case SET_ROLES_LIST_LOADING: {
            return {
                ...state,
                loadings: {
                    ...state.loadings, fullRolesListLoading: action.payload
                }
            }
        }

        case SET_ROLE_GROUP: {
            let isExist: boolean
            if (state?.roleFullInfo && state?.roleFullInfo?.groups && state.roleFullInfo?.groups.length > 0 && state.roleFullInfo?.groups?.find(group => group.id === action.payload.id) !== undefined) {
                isExist = true
            } else isExist = false

            let object: IDirectoryRole | null = null
            let a = state?.roleFullInfo && state?.roleFullInfo?.groups && state?.roleFullInfo?.groups?.length > 0 ? state?.roleFullInfo?.groups?.map(i => {
                if (i.id === action.payload.id) {
                    return action.payload

                } else {
                    return i
                }
            }) : []

            if (isExist && a && state.roleFullInfo) {
                object = { ...state.roleFullInfo, groups: a }
            }
            return {
                ...state,
                roleFullInfo: isExist ? object : state.roleFullInfo?.groups ? {
                    ...state.roleFullInfo,
                    groups: [...state.roleFullInfo.groups, action.payload]
                } : {
                    ...state.roleFullInfo,
                    groups: [action.payload]
                }
            }
        }

        case SET_GROUP_FORMVIEWS: {

            let isExist: boolean = false
            
            isExist = state.roleFullInfo?.groups?.length !== undefined && state.roleFullInfo?.groups?.length > 0 && state.roleFullInfo?.groups.find(i => i.id === action.payload.idGroup)?.formviews?.find(item => item.id === action.payload.formview.id) !== undefined;

            let formviews: TEnumItem[] = []
            if (isExist) {
                formviews = state.roleFullInfo?.groups?.find(i => i.id === action.payload.idGroup)?.formviews?.filter(i => i.id !== action.payload.formview.id) || []
            } else {
                
                let a = state.roleFullInfo?.groups !== undefined && state.roleFullInfo?.groups?.length > 0 ? state.roleFullInfo?.groups?.find(i => i.id === action.payload.idGroup)?.formviews : undefined
                
                if (a !== undefined) formviews = [...a, action.payload.formview]
            }
            return {
                ...state,
                roleFullInfo: {
                    ...state.roleFullInfo, groups: state.roleFullInfo?.groups.map(i => {
                        if (i.id === action.payload.idGroup) {
                            return {
                                ...i,
                                formviews: formviews
                            }
                        } else {
                            return i
                        }
                    })
                }
            }
        }

        case SET_VISIBLE_PAGES: {
            return {
                ...state,
                visiblePages: action.payload
            }
        }
        case SET_VISIBLE_PAGES_LOADING: {
            return {
                ...state,
                visiblePagesLoading: action.payload
            }
        }

        case CLEAR_SUBGROUPS_ROLES: {
            return {
                ...state,
                roleFullInfo: {
                    ...state.roleFullInfo,
                    groups: state.roleFullInfo?.groups?.filter(item => item.id === action.payload)
                }
            }
        }

        default:
            return state
    }
}