import React, { createRef, useEffect, useState } from 'react';
import { DividingLine } from '../DividingLine/DividingLine';
import { Search } from '../../../icons/icons';
import { Notification } from '../../../icons/icons';
import { CheckMark } from '../../../icons/icons';
import ListItem from "@material-ui/core/ListItem";
import { HeaderPageStore } from "../../../store/header/Header";
import { observer } from "mobx-react-lite";
import { Notifications } from "./Notification/Notifications";
import { Link } from "react-router-dom";
import { ROUTE_PATH } from "../../../routers/paths.main";
import { ApplicationsStore } from "../../../store/applications/Applications";
import { Fade } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { setAppOrTemplateFlag, setCurrentPage } from "../../../redux/reducers/directory/actions/actions";
import { useKeyPressed } from "../../helpers/useKeyPress/useKeyPress";
import { LoginPageStore } from "../../../store/loginPage/LoginPage";
import { TRootState } from "../../../redux/store";
import { formviewAvailabilityFunc } from "../../helpers/fromviewHelpers/formviewAvailability";
import { paths } from '../../../store/api/paths';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { openNotification } from '../../helpers/notification';
import { instance, key } from '../../../store/api';
import { checkError } from '../../../store/api/helpers';

const s = require('./style.module.scss');

interface IHeaderComponentProps {
    nameLogin?: string
    setSearchValue: (value: string) => void
    request: (value: string) => void
    searchValue: string
    paddingLeft?: number

    [key: string]: any
}

export const Header: React.FC<IHeaderComponentProps> = observer(({
    nameLogin,
    searchValue,
    setSearchValue,
    request,
    paddingLeft
}) => {

    const { setFlag } = ApplicationsStore

    const href = window.location.href.split('/')[window.location.href.split('/').length - 1]
    //State-s
    const [bellAnimActive, setBellAnimActive] = useState<boolean>(false)
    const isMobile: boolean = window.outerWidth <= 1024
    const [showExit, setShowExit] = useState<boolean>(false)
    const [showNotification, setShowNotification] = useState<boolean>(false)

    //для socket
    const [connection, setConnection] = useState<boolean>(false)

    const {
        getMyNotification,
        addNotification,
        requestCheckedNotification,
        notificationsTotalCount,
        notifications,
        notificationsList
    } = HeaderPageStore


    const myInfo = useSelector((state: TRootState) => state.authReducer.myInfo)

    const dispatch = useDispatch()

    //useEffect-s
    useEffect(() => {
        !window.location.href.includes('/directory') && dispatch(setCurrentPage(0))
    }, [])

    //Socket
    useEffect(() => {
        const connection = new HubConnectionBuilder()
            .withUrl(paths.socketPath + paths.socketConnection, {
                accessTokenFactory: () => {
                    const main = key.main
                    return localStorage.getItem(main) || instance().auth().authorization({
                        password: '',
                        username: ''
                    }).then(res => res.data.access_token)
                }
            })
            .withAutomaticReconnect()
            .build();
        getMyNotification(0, 7)
            .then(() => {
                connection?.start()
                    .then(res => {
                        //openNotification({ type: "success", message: 'Connected!' })
                    })
                    .catch(err => {
                        //checkError(err)
                    })
            })

        connection.on('Receive', data => {
            setBellAnimActive(true)
            addNotification(JSON.parse(data))
            console.log(JSON.parse(data))
        });

        return () => {
            connection.stop()
        }
    }, [])


    useEffect(() => {
        let notif = document.getElementById('notification')
        if (notif) {
            notif.onblur = () => setShowNotification(false)
        }
    }, [showNotification])

    useKeyPressed('Enter') && request(searchValue)


    useEffect(() => {
        if (myInfo) {
            let formviewsPages = myInfo?.formviews?.filter(element => element?.type_id === 2).map(element => element?.mnemo)
            if (formviewsPages?.includes('my_tasks') && href === 'applications') {
                return
            }
            else if (formviewsPages?.includes('favorite_tasks') && href === 'favourite') {
                return
            }
            else if (formviewsPages?.includes('archive_tasks') && href === 'archive') {
                return
            }
            else if (formviewsPages?.includes('deleted_tasks') && href === 'deleted') {
                return
            }
            else if (formviewsPages?.includes('calendar') && href === 'calendar') {
                return
            }
            else if (formviewsPages?.includes('employees') && href === 'workers') {
                return
            }
            else if (formviewsPages?.includes('history') && href === 'history') {
                return
            }
            else if (formviewsPages?.includes('visible_report') && href === 'reports') {
                return
            }
            else {
                window.location.href = '/'
            }
        }
    }, [href, myInfo])

    return (
        <div className={s.main_wrapper}
            style={{ paddingLeft }}
        >
            <div className={s.main_content_wrap}>
                <div className={s.search}>
                    <div className={s.searchBlock}>
                        <ListItem
                            button
                            className={s.nested}
                            onClick={() => request(searchValue)}
                        >
                            <Search />
                        </ListItem>
                        <input
                            value={searchValue}
                            placeholder='Найти заявку, пультовый номер, сотрудника...'
                            className={s.input_header}
                            onChange={(e) => {
                                setSearchValue(e.currentTarget.value)
                                ApplicationsStore.setSearchValueStore(e.currentTarget.value)
                            }}
                        />
                    </div>

                    {myInfo?.formviews && formviewAvailabilityFunc(myInfo.formviews, 'create_task') &&
                        <div className={s.buttons}>
                            <Link
                                to={ROUTE_PATH.newApplication}
                            >
                                <div
                                    className={s.findApplication}
                                    onClick={() => dispatch(setAppOrTemplateFlag('addApp'))}>
                                    Новая заявка
                                </div>
                            </Link>
                        </div>}
                </div>
                {!isMobile && <div className={s.notification_login}>
                    <div className={s.notification}
                        id={'notification'}
                        onClick={() => {
                            /*requestCheckedNotification(() => getMyNotification(0, 1))*/
                            setShowNotification(true)
                        }}>
                        <Notification />
                        {notificationsTotalCount > 0
                            && <div className={s.red_circle_notification}>{notificationsTotalCount}</div>}
                        <div
                            onMouseLeave={() => setShowNotification(false)}
                            style={!showNotification
                                ? { display: 'none' }
                                : { position: 'absolute', zIndex: 10, right: '10%', background: 'white' }}
                        >
                            <Notifications />
                        </div>
                    </div>
                    <div style={{ margin: '0 10px' }}>
                        {myInfo?.name}
                    </div>
                    {/* {nameLogin} */}
                    <CheckMark
                        style={showExit ? {
                            cursor: 'pointer',
                            transform: 'rotateX(180deg)',
                            transition: '1s',
                            width: '20px',
                            height: '20px'
                        } : { cursor: 'pointer', transition: '1s', width: '20px', height: '20px' }} onClick={() => setShowExit(!showExit)} />
                </div>}
                {showExit && !isMobile && <Fade in timeout={500}>
                    <div className={s.exitBlock}>
                        <Link to={ROUTE_PATH.authorization}>
                            <div onClick={() => localStorage.clear()}>
                                Выход
                            </div>
                        </Link>
                    </div>
                </Fade>}
            </div>
            <div style={{ margin: '2% 0' }}>
                <DividingLine />
            </div>
        </div>
    )
})

