import {
    APPLICATIONS_ACTIONS,
    CLEAR_CHECKED,
    FILTER_CHECKED,
    SET_ACTIVE_COUNT,
    SET_CHECKED,
    SET_CURRENT_PAGE,
    SET_CURRENT_SHOW_TASKS_MODE,
    SET_TASK_ACTIONS,
    SET_TASK_ACTIONS_LOADING,
    SET_TASK_HISTORY,
    SET_TASK_HISTORY_LOADING,
    TApplicationsReducer
} from "./types";

const initialState: TApplicationsReducer = {
    activeCount: 0,
    initialPages: [
        {id: 0, name: 'Комментарии', path: '', iudPath: '', isActive: true},
        {id: 1, name: 'История', path: '', iudPath: '', isActive: false},
        {id: 2, name: 'События', path: '', iudPath: '', isActive: false}
    ],
    checkers: [],
    currentShowTaskMode: 'cards',
    taskHistory: null,
    taskHistoryLoading: false,
    taskActions: null,
    taskActionsLoading: false
}

export const applicationsReducer = (state = initialState, action: APPLICATIONS_ACTIONS) => {
    switch (action.type) {
        case SET_ACTIVE_COUNT: {
            return {
                ...state,
                activeCount: action.payload
            }
        }
        case SET_CURRENT_PAGE: {
            return {
                ...state,
                initialPages: state.initialPages.map((page) => {
                    if (page.id === action.payload) {
                        return {...page, isActive: true}
                    } else {
                        return {
                            ...page,
                            isActive: false
                        }
                    }
                })
            }
        }
        case SET_CHECKED: {
            return {
                ...state,
                checkers: state.checkers.find(i => i.id === action.payload.id) === undefined
                    ? [...state.checkers, action.payload]
                    : [...state.checkers]
            }
        }

        case CLEAR_CHECKED: {
            return {
                ...state,
                checkers: []
            }
        }

        case FILTER_CHECKED: {
            return {
                ...state,
                checkers: state.checkers.filter(i => i.id !== action.payload.id)
            }
        }

        case SET_CURRENT_SHOW_TASKS_MODE: {
            return {
                ...state,
                currentShowTaskMode: action.payload
            }
        }

        case SET_TASK_HISTORY: {
            return {
                ...state,
                taskHistory: action.payload
            }
        }

        case SET_TASK_HISTORY_LOADING: {
            return {
                ...state,
                taskHistoryLoading: action.payload
            }
        }

        case SET_TASK_ACTIONS: {
            return {
                ...state,
                taskActions: action.payload
            }
        }

        case SET_TASK_ACTIONS_LOADING: {
            return {
                ...state,
                taskActionsLoading: action.payload
            }
        }

        default :
            return state

    }
}