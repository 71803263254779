import React from 'react';
import clsx from 'clsx';

const s = require('./style.module.scss');

interface IStatusCardComponentProps {
    text: string
    color: string
    background: string
    [key: string]: any
}

export const StatusCard: React.FC<IStatusCardComponentProps> = ({
    text,
    color,
    background,
                                                         }) => {
    return (
        <div className={clsx(s.main_wrap)} style={{color, background}}>
           {text}
        </div>
    );
};

