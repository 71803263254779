import React from 'react';

const s = require('./style.module.scss');

interface ITagCardComponentProps {
    title: string
    color: string
    [key: string]: any
}

export const TagCard: React.FC<ITagCardComponentProps> = ({
                                                             title,
                                                             color,
                                                         }) => {
    return (
        <div className={s.title} style={{color: `${color}`}}>
            {title}
        </div>
    );
};

