import React, { useEffect, useState } from "react";
import { IModal } from "../../../store/sidebar/types.data";
import { observer } from "mobx-react-lite";
import { Dialog, ListItem } from "@material-ui/core";
import { ModalHeader } from "../../common/Header/ModalHeader/ModalHeader";
import { SimpleSelect } from "../../helpers/Selects/SimpleSelect";
import WorkersPageStore from "../../../store/WorkersPage/WorkersStore";
import { openNotification } from "../../helpers/notification";
import { useStyles } from "../../helpers/Styles/CommonStyles";

const s = require('./AddWorkersToGroupModal.module.scss')

interface IAddWorkersToGroupModalProps extends IModal {
    selectedWorkers: { workerId: number }[]
    requestHandler?: (value?: string) => void
}

export const AddWorkersToGroupModal: React.FC<IAddWorkersToGroupModalProps> = observer(({
    title,
    open,
    closeHandler,
    selectedWorkers,
    requestHandler
}) => {


    const { requestWorkersGroups, groups, groupsLoading, requestAddWorkersToGroup } = WorkersPageStore

    const [groupId, setGroupId] = useState<number | null>(null)
    const [workers, setWorkers] = useState<{ id: number }[]>([])

    const [showAlert, setShowAlert] = useState<boolean>(false)

    useEffect(() => {
        requestWorkersGroups()
        let a: { id: number }[] = []
        selectedWorkers.map(i => {
            a.push({ id: i.workerId })
        })
        setWorkers(a)
    }, [])

    const common = useStyles()

    const submitHandler = () => {
        groupId && requestAddWorkersToGroup({
            id_group: groupId,
            contractors: workers

        }, () => {
            openNotification({
                type: 'success',
                message: 'Сотрудники успешно добавлены в группу'
            })
            requestHandler && requestHandler()
            closeHandler()
        })
    }

    return <Dialog open={open} classes={{ paper: common.dialogWithoutOverflow }}>
        <ModalHeader title={title} closeHandler={closeHandler} />
        <div className={s.body}>
            <div className={s.item}>
                <div className={s.title}>
                    Выберите группу:
                </div>
                <div className={s.value}>
                    <SimpleSelect
                        onChange={setGroupId}
                        items={groups || []}
                        size={'xs'}
                        showSearch
                    />
                </div>
            </div>
        </div>
        <div className={s.footer}>
            <div className={s.btns}>
                <ListItem
                    button
                    onClick={closeHandler}
                    style={{
                        background: 'white',
                        color: 'black',
                        border: '1px solid black',
                        borderRadius: 4,
                        display: 'flex',
                        justifyContent: 'center',
                        width: 88,
                        height: 34
                    }}
                >Отмена</ListItem>
                <ListItem
                    disabled={!groupId}
                    button
                    onClick={submitHandler}
                    style={{
                        background: 'black',
                        color: 'white',
                        borderRadius: 4,
                        display: 'flex',
                        justifyContent: 'center',
                        width: 96,
                        height: 34
                    }}
                >Добавить</ListItem>
            </div>
        </div>
    </Dialog>
})

