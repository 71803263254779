// @ts-nocheck
import { Select } from 'antd';
import React, { memo } from 'react'
import { IMultipleSelectProps } from "./types";
import clsx from "clsx";

const { Option } = Select;

const s = require('./selects.module.scss')

const tagRender = (count: number) => {
    return (
        <div className={s.text}>
            {`Выбрано: ${count}`}
        </div>
    )
}

export const MultipleSelect: React.FC<IMultipleSelectProps> = memo(({
    items,
    width = '100%',
    values,
    onChange,
    size = 'lg',
    ...props
}) => {
    const classNames = clsx(
        s.multiplySelect,
        values && values.length === 0 && s.dark,
        props?.withoutEmpty && s.light,
        size === 'lg' && s.lg,
        size === 'md' && s.md,
        size === 'xs' && s.xs
    )
    return (
        <div style={{ width }}>
            <Select mode={"multiple"}
                allowClear
                placeholder={props?.placeholder || 'Выбрано: 0'}
                className={classNames}
                style={{ width, borderRadius: 4 }}
                maxTagCount={0}
                tagRender={() => tagRender(values && values.length || 0)}
                filterOption={(input, option) =>
                    option?.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(value, option) => {
                    let changedList = option.map((i: any) => {
                        return {
                            id: i.value,
                            name: i.children
                        }
                    })
                    onChange(value, changedList)
                }}
                value={values}
                dropdownStyle={{ zIndex: 10000000000000 }}
                popupClassName={s.dropdown} defaultValue={[]}
                disabled={props?.disabled}
                {...props}>
                    
                {items.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
            </Select>
        </div>
    )
})