import React from "react";
import {Button} from "@material-ui/core";

const s = require('./MyButton.module.scss')

interface ISimpleButton {
    onClick: any
    flag: 'white' | 'black' | "red"
    className?: string
    border?: boolean
    disabled?: boolean
    title?: string
    active?: boolean

    [key: string]: any
}

export const MyButton: React.FC<ISimpleButton> = ({
                                                      onClick,
                                                      active,
                                                      className,
                                                      flag,
                                                      border = false,
                                                      disabled = false,
                                                      title,
                                                      children,
                                                  }) => {
    const padding = typeof children === 'string' ? '8px 16px' : '13px 16px'
    return <Button
        title={title}
        className={s.button + ' ' +
        (flag === 'black'
            ? s.black
            : flag === 'white'
                ? s.white
                : flag === 'red' && s.red)
        + ' ' + (disabled ? s.disabled : '')
        + ' ' + (active ? s.active : '')
        + ' ' + className}
        style={border ? {
                border: '1px solid #878787',
                boxSizing: 'border-box',
                boxShadow: 'none',
                padding: padding,
            }
            : {
                padding: padding,
            }}
        onClick={onClick}>
        {children}
    </Button>
}