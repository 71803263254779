import { makeAutoObservable } from "mobx";
import { IWorker, TAddWorkersToGroup, TGetWorkersListOrItem, TIudGroup, TIudWorker } from "./types";
import { instance } from "../api";
import { checkError, isError } from "../api/helpers";
import { TEnumItem } from "../../components/helpers/utils";

class WorkersStore {


    workers: IWorker[] | null = null
    workersCount: number = 0
    workersLoading: boolean = false

    employee: IWorker | null = null


    workersWithGroups: { id: number, pId: number | null, name: string, title: string, value: string | number }[] = []
    workersWithGroupsLoading: boolean = false


    groups: TEnumItem[] = []
    groupsLoading: boolean = false

    admins: TEnumItem[] = []
    adminsLoading: boolean = false

    //Исполнители
    executors: { id: number, pId: number | null, name: string, title: string, value: string | number }[] = []
    executorsLoading: boolean = false

    //Наблюдатели
    observers: TEnumItem[] = []
    observersLoading: boolean = false

    constructor() {
        makeAutoObservable(this)
    }

    /*  setSelectedWorkers(workerId: number) {
           this.selectedWorkers = [...this.selectedWorkers, {workerId: workerId}]
      }

      deleteSelectedWorkers(workerId: number) {

          this.selectedWorkers = this.selectedWorkers.filter(i => i.workerId !== workerId)
      }*/

    requestWorkersListOrItem = async (data: TGetWorkersListOrItem) => {
        this.workersLoading = true
        try {
            const res = await instance().close().getWorkersListOrItem(data)
            if (data.id) {
                this.employee = res.data?.data?.value[0]
            } else {
                this.workers = res.data?.data?.value
                this.workersCount = res.data?.data?.count
            }
        } catch (e) {
            isError(e)
            checkError(e)
            
        }
        this.workersLoading = false
    }

    requestIudGroup = async (data: TIudGroup, onSuccess?: () => void) => {
        try {
            const res = await instance().close().iudGroup(data)
            onSuccess && onSuccess()
        } catch (e) {
            isError(e)
            checkError(e)
            
        }
    }

    requestWorkersWithGroups = async (body: { flag?: string, searchValue?: string }, onSuccess?: (workersWithGroups: { id: number, pId: number | null, name: string, title: string, value: string | number }[]) => void) => {
        this.workersWithGroupsLoading = true
        this.adminsLoading = true
        try {
            const res = await instance().close().getWorkersWithGroups(body)
            if (body.flag === 'admins') {
                this.admins = res.data.data?.value || []
            } else {
                let a: { id: number, pId: number | null, name: string }[] = []
                a = res.data?.data?.value
                this.workersWithGroups = a.map(i => {
                    return {
                        id: i.id,
                        name: i.name,
                        pId: i.pId,
                        title: i.name,
                        value: i.id
                    }
                })
                onSuccess && onSuccess(a.map(i => {
                    return {
                        id: i.id,
                        name: i.name,
                        pId: i.pId,
                        title: i.name,
                        value: i.id
                    }
                }))
            }

        } catch (e) {
            isError(e)
            checkError(e)
            
        }
        this.workersWithGroupsLoading = false
        this.adminsLoading = false
    }

    requestObservers = async (body: { flag?: string, searchValue?: string }, onSuccess?: (workersWithGroups: { id: number, pId: number | null, name: string, title: string, value: string | number }[]) => void) => {
        this.observersLoading = true
        try {
            const res = await instance().close().getWorkersWithGroups(body)
            this.observers = res.data?.data?.value || []
        }
        catch (e) {
            isError(e)
            checkError(e)
            
        }
        this.observersLoading = false
    }


    requestExecutors = async (body: { flag?: string, searchValue?: string }, onSuccess?: (workersWithGroups: { id: number, pId: number | null, name: string, title: string, value: string | number }[]) => void) => {
        this.executorsLoading = true
        try {
            const res = await instance().close().getWorkersWithGroups(body)
            let a: { id: number, pId: number | null, name: string }[] = []
            a = res.data?.data?.value
            this.executors = a.map(i => {
                return {
                    id: i.id,
                    name: i.name,
                    pId: i.pId,
                    title: i.name,
                    value: i.id
                }
            })
        }
        catch (e) {
            isError(e)
            checkError(e)
            
        }
        this.executorsLoading = false
    }


    requestWorkersGroups = async () => {
        this.groupsLoading = true
        try {
            const res = await instance().close().getGroups()
            this.groups = res.data?.data?.value

        } catch (e) {
            isError(e)
            checkError(e)
            
        }
        this.groupsLoading = false
    }


    requestAddWorkersToGroup = async (data: TAddWorkersToGroup, onSuccess?: () => void, onError?: () => void) => {
        try {
            const res = await instance().close().addWorkersToGroup(data)
            onSuccess && onSuccess()
        } catch (e) {
            isError(e)
            checkError(e)
            
            onError && onError()
        }
    }

    requestIudWorker = async (data: TIudWorker, onSuccess?: () => void) => {
        try {
            const res = await instance().close().iudWorker(data)
            onSuccess && onSuccess()
        } catch (e) {
            isError(e)
            checkError(e)
            
        }
    }

}

const WorkersPageStore = new WorkersStore()

export default WorkersPageStore;