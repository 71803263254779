import React, { FC, useEffect, useState } from "react";
import { Header } from "../common/Header/Header";
import { HeadTitle } from "../common/HeadTitle/PageTitle";
import { observer } from "mobx-react-lite";
import { ListItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import { IInitialPage } from "../../store/extraPage/types";
import { connect, useSelector } from "react-redux";
import { TRootState } from "../../redux/store";
import { setCurrentPage, setIudFlag } from "../../redux/reducers/directory/actions/actions";
import { ISetCurrentPage, ISetIudFlag } from "../../redux/reducers/directory/actions/types";
import { formviewAvailabilityFunc } from "../helpers/fromviewHelpers/formviewAvailability";
import { TEnumItem } from "../helpers/utils";

const s = require('./style.module.scss')

interface IDirectoryProps {
    initialPages: IInitialPage[]
    setCurrentPage: ISetCurrentPage
    setIudFlag: ISetIudFlag
}

const Directory: FC<IDirectoryProps> = observer(({
    children,
    initialPages,
    setCurrentPage,
    setIudFlag
}) => {


    const isMobile: boolean = window.outerWidth <= 425

    const formviews = useSelector((state: TRootState) => state.authReducer.myInfo?.formviews)

    useEffect(() => {
        switch (initialPages.find(page => page.isActive)?.id) {
            case 0: {
                setIudFlag('addGroup')
                return
            }
            case 1: {
                setIudFlag('addCategory')
                return;
            }
            case 3: {
                setIudFlag('addRole')
                return;
            }
            default:
                return;
        }
    }, [initialPages])


    return <div className={s.main}>
        {/*<Header/>*/}
        <HeadTitle
            needSubNavigation
            needButtons
            setCurrentPage={setCurrentPage}
            needSearch={window.location.href.includes('devices')}
            link={initialPages.find(page => page.isActive)?.iudPath || '/'}
            title={initialPages.find(page => page.isActive)?.name || 'Справочники'} />
        <div className={s.wrap}>
            {!isMobile && <div className={s.pageChangerBlock}>
                {initialPages.map((i) => <Link to={i.path}>
                    <ListItem
                        style={{
                            width: 280,
                            height: 38,
                            padding: '7px 19px',
                            fontSize: 18,
                            fontWeight: 'bold',
                            color: 'black',
                            cursor: 'pointer',
                            borderRadius: 5,
                            background: i?.isActive ? '#F1F3F5' : 'none'
                        }}
                        button
                        onClick={() => {
                            setCurrentPage(i.id)
                        }}
                    >
                        {i.name}
                    </ListItem>
                </Link>)}
            </div>}
            <div style={{ width: isMobile ? '100%' : '80%' }}>
                {children}
            </div>
        </div>
    </div>
})

const MapStateToProps = (state: TRootState) => ({
    initialPages: state.directoryReducer.initialPages,
})

export const DirectoryContainer = connect(MapStateToProps, { setCurrentPage, setIudFlag })(Directory)