import React, { FC, useEffect, useState } from "react";
import { HeadTitle } from "../common/HeadTitle/PageTitle";
import { TopSidebarButton } from "../TopSidebarButton/TopSidebarButton";
import { Cards } from "./Cards/Cards";
import { DividingLine } from "../common/DividingLine/DividingLine";
import { Header } from "../common/Header/Header";
import { MyPagination } from "../helpers/MyPagination/MyPagination";
import { observer } from "mobx-react-lite";
import { key } from "../../store/api";
import { Fallback } from "../helpers/Fallback/Fallback";
import { LoginPageStore } from "../../store/loginPage/LoginPage";
import { ApplicationsStore } from "../../store/applications/Applications";
import { SidebarStore } from "../../store/sidebar/sidebarMobx";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "../../redux/store";
import { getMyInfo } from "../../redux/reducers/auth/actions/actions";
import { Table } from "./TableComponent/Table";
import { ListItem } from "@material-ui/core";
import { setCurrentShowTaskMode } from "../../redux/reducers/applications/actions/actions";
import { SimpleSelect } from "../helpers/Selects/SimpleSelect";
import { RangeDatePicker } from "../NewApplication/DatePicker/DatePickerComponent";
import moment from 'moment'

const s = require('./style.module.scss');

interface MyApplicationProps {
    flag: 'my' | 'favourite' | 'archive' | 'deleted',
    title: string
}

export const MyApplication: FC<MyApplicationProps> = observer(({
    flag,
    title

}) => {
    //Дата сохраненная после работы с календарём
    const taskFiltersDate = useSelector((state: TRootState) => state.directoryReducer.taskFiltersDate)
    const currentShowTaskMode = useSelector((state: TRootState) => state.applicationsReducer.currentShowTaskMode)



    const [currentPage, setCurrentPage] = useState<number>(1)

    const [pageSize, setPageSize] = useState<number>(12)

    const { applicationList } = SidebarStore
    const { applications, searchValueStore, applicationsLoading, activeFlag } = ApplicationsStore

    const [searchValue, setSearchValue] = useState<string>('')
    const [value, setValue] = useState<string | number>(1)
    const [fromToValue, setFromToValue] = useState<string | number>(1)
    const [cardsMode, setCardsMode] = useState<boolean>(currentShowTaskMode === 'cards')

    const [dateStart, setDateStart] = useState<string | undefined>(undefined)
    const [dateEnd, setDateEnd] = useState<string | undefined>(undefined)

    const href = window.location.href.split('/')[window.location.href.split('/').length - 1]
    const formateDate = (date: string) => {
        const arr = date.split('T')[0].split('-')
        return `${arr[2]}.${arr[1]}.${arr[0]}`
    }

    const chosen = useSelector((state: TRootState) => state.applicationsReducer.activeCount)
    const dispatch = useDispatch()
    const requestHandler = (searchValue: string,
        count?: number,
        flagFunc?: string,
        fromToValue?: string | number,
    ) => {
        if (flag === 'favourite') {
            ApplicationsStore.requestApplications({
                id: null,
                searchValue: searchValue === '' ? null : searchValue,
                name: null,
                flag: 'favorite',
                filters: null,
                dateFrom: dateStart ? formateDate(dateStart) : null,
                dateTo: dateEnd ? formateDate(dateEnd) : null,
                offset: 0,
                count: count ? count : pageSize,
                startDate: taskFiltersDate?.startDate || undefined,
                endDate: taskFiltersDate?.endDate || undefined,
                sortBy: fromToValue === 1 ? 'fromNew' : 'fromOld'
            })
        } else if (flag === 'archive') {
            ApplicationsStore.requestApplications({
                id: null,
                searchValue: searchValue === '' ? null : searchValue,
                name: null,
                flag: 'archive',
                filters: null,
                dateFrom: dateStart ? formateDate(dateStart) : null,
                dateTo: dateEnd ? formateDate(dateEnd) : null,
                offset: 0,
                count: count ? count : pageSize,
                startDate: taskFiltersDate?.startDate || undefined,
                endDate: taskFiltersDate?.endDate || undefined,
                sortBy: fromToValue === 1 ? 'fromNew' : 'fromOld'
            })
        } else if (flag === 'deleted') {
            ApplicationsStore.requestApplications({
                id: null,
                searchValue: searchValue === '' ? null : searchValue,
                name: null,
                flag: 'deleted',
                filters: null,
                dateFrom: dateStart ? formateDate(dateStart) : null,
                dateTo: dateEnd ? formateDate(dateEnd) : null,
                offset: 0,
                count: count ? count : pageSize,
                startDate: taskFiltersDate?.startDate || undefined,
                endDate: taskFiltersDate?.endDate || undefined,
                sortBy: fromToValue === 1 ? 'fromNew' : 'fromOld'
            })
        } else {
            ApplicationsStore.requestApplications({
                id: null,
                searchValue: searchValue === '' ? null : searchValue,
                name: null,
                flag: flagFunc || activeFlag || null,
                filters: null,
                dateFrom: dateStart ? formateDate(dateStart) : null,
                dateTo: dateEnd ? formateDate(dateEnd) : null,
                offset: 0,
                count: count ? count : pageSize,
                startDate: taskFiltersDate?.startDate || undefined,
                endDate: taskFiltersDate?.endDate || undefined,
                sortBy: fromToValue === 1 ? 'fromNew' : 'fromOld'
            })
        }
        // flag === 'favourite' ? ApplicationsStore.requestApplications({
        //     id: null,
        //     searchValue: searchValue === '' ? null : searchValue,
        //     name: null,
        //     flag: 'favorite',
        //     filters: null,
        //     dateFrom: dateStart ? formateDate(dateStart) : null,
        //     dateTo: dateEnd ? formateDate(dateEnd) : null,
        //     offset: 0,
        //     count: count ? count : pageSize,
        //     startDate: taskFiltersDate?.startDate || undefined,
        //     endDate: taskFiltersDate?.endDate || undefined,
        //     sortBy: fromToValue === 1 ? 'fromNew' : 'fromOld'
        // }) : ApplicationsStore.requestApplications({
        //     id: null,
        //     searchValue: searchValue === '' ? null : searchValue,
        //     name: null,
        //     flag: flagFunc || activeFlag || null,
        //     filters: null,
        //     dateFrom: dateStart ? formateDate(dateStart) : null,
        //     dateTo: dateEnd ? formateDate(dateEnd) : null,
        //     offset: 0,
        //     count: count ? count : pageSize,
        //     startDate: taskFiltersDate?.startDate || undefined,
        //     endDate: taskFiltersDate?.endDate || undefined,
        //     sortBy: fromToValue === 1 ? 'fromNew' : 'fromOld'
        // })
    }



    useEffect(() => {
        setCardsMode(currentShowTaskMode === 'cards')
    }, [currentShowTaskMode])

    useEffect(() => {
        // if (!localStorage.getItem(key.main)) window.location.href = '/authorization'
        dispatch(getMyInfo({}))
        /*setTimeout(() => {
            dispatch(setTaskFilterDates(null))
        }, 2000)*/
    }, [])

    useEffect(() => {
        requestHandler(searchValue, pageSize, null, fromToValue)
        //cardsMode ? setPageSize(12) : setPageSize(30)
    }, [cardsMode, pageSize, activeFlag, fromToValue, dateStart, dateEnd])


    // useEffect если переходим на другой флаг, чтобы страница пагинации обнулялась
    useEffect(() => {
        setCurrentPage(1)
    }, [localStorage.getItem('flag')])


    return (
        <div>
            {!localStorage.getItem(key.main)
                ? <Fallback />
                : <div className={s.main_page_wrap}>
                    <div className={s.main_content_wrap}>
                        <Header paddingLeft={!(window.outerWidth <= 425) ? 8 : undefined} searchValue={searchValue} setSearchValue={setSearchValue}
                            request={(value) => requestHandler(value)} />
                        <HeadTitle paddingLeft={!(window.outerWidth <= 425) ? 8 : undefined} title={title} />

                        <div className={s.applicationFilters}>
                            {!(window.outerWidth <= 425) && <ListItem
                                className={cardsMode ? s.modeBtn_black : s.modeBtn_white}
                                button
                                onClick={() => {
                                    if (cardsMode) {
                                        dispatch(setCurrentShowTaskMode('table'))
                                    } else {
                                        dispatch(setCurrentShowTaskMode('cards'))
                                    }
                                }}

                            >
                                {cardsMode ? 'Табличный вид' : 'Карточный вид'}
                            </ListItem>}
                            <SimpleSelect
                                width={window.outerWidth > 425 ? 120 : '100%'}
                                value={value}
                                placeholder={'Кол-во:'}
                                size={'lg'}
                                items={cardsMode
                                    ? [
                                        {
                                            id: 1,
                                            name: '12'
                                        },
                                        {
                                            id: 2,
                                            name: '21'
                                        },
                                        {
                                            id: 3,
                                            name: '30'
                                        },
                                    ]
                                    : [
                                        {
                                            id: 1,
                                            name: '25'
                                        },
                                        {
                                            id: 2,
                                            name: '50'
                                        },
                                        {
                                            id: 3,
                                            name: '100'
                                        },
                                    ]}
                                onChange={(value, option) => {
                                    setValue(value)
                                    setPageSize(+option?.children)
                                }}
                            />
                            <SimpleSelect
                                width={window.outerWidth > 425 ? 200 : '100%'}
                                value={fromToValue}
                                placeholder={'Выберите'}
                                size={'lg'}
                                items={
                                    [
                                        {
                                            id: 1,
                                            name: 'От старых к новым'
                                        },
                                        {
                                            id: 2,
                                            name: 'От новых к старым'
                                        },
                                    ]
                                }
                                onChange={(fromToValue, option) => {
                                    setFromToValue(fromToValue)
                                }}
                            />
                        </div>
                        <div className={s.datePicker}>
                            <RangeDatePicker
                                width={'100%'}
                                mobile={Boolean(window.innerWidth <= 425)}
                                onChange={(value: any) => {
                                    if (value?.length === 2) {
                                        setDateStart(moment(value[0]).format())
                                        setDateEnd(moment(value[1]).format())
                                    } else {
                                        setDateStart(undefined)
                                        setDateEnd(undefined)
                                    }
                                }}
                                value={undefined}
                                rangeValues={dateEnd && dateStart ? [dateStart, dateEnd] : undefined}
                            />
                        </div>
                        {/* <TopSidebarButton title={'новое (1)'}/> */}
                        {(flag !== 'favourite' && flag !== 'archive' && flag !== 'deleted') && <div>
                            <TopSidebarButton selectedWorkers={[]} flag={'myApplications'} />
                        </div>}
                        {cardsMode ? <div className={s.cards_wrap}>
                            {applicationsLoading ? <Fallback /> : applications?.value?.map((i) => <div
                                className={s.item}>
                                <Cards
                                    request={() => requestHandler(searchValue, pageSize, localStorage.getItem('flag') || undefined)}
                                    key={i.id}
                                    item={i}
                                    flag={flag} />
                            </div>)}
                        </div> : <div style={{ paddingLeft: 20 }}>
                            <Table
                                request={() => requestHandler(searchValue, pageSize, localStorage.getItem('flag') || undefined)}
                                tableData={applications?.value || []}
                                fullWidth={`100%`}
                                totalCount={applications?.value?.length || 0}
                                flag={flag}
                            />
                            {/* <div className={s.underTableBlock}>
                                <div className={s.table_func}>
                                    <ListItem
                                        button
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: 38,
                                            width: 190,
                                            background: 'white',
                                            color: 'black',
                                            border: '1px solid black',
                                            borderRadius: 5
                                        }}
                                    >Выберите действие</ListItem>
                                    <ListItem
                                        button
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: 39,
                                            width: 109,
                                            background: 'black',
                                            color: 'white',
                                            borderRadius: 5
                                        }}
                                    >Применить</ListItem>
                                </div>
                                <div className={s.touched}>
                                    {`Отмечено: ${chosen} / ${applications?.count}`}
                                </div>
                            </div> */}
                        </div>}
                        <MyPagination
                            paddingLeft={20}
                            currentPage={currentPage}
                            onChange={(value) => {
                                setCurrentPage(value)
                                ApplicationsStore.requestApplications({
                                    id: null,
                                    offset: cardsMode ? pageSize * (value - 1) : 10 * (value - 1),
                                    count: pageSize,
                                    flag: href === 'applications' ? localStorage.getItem('flag') : flag,
                                    filters: null,
                                    name: null,
                                    searchValue,
                                    startDate: taskFiltersDate?.startDate || undefined,
                                    endDate: taskFiltersDate?.endDate || undefined,
                                    sortBy: fromToValue === 1 ? 'fromNew' : 'fromOld'
                                })
                            }}
                            total={applications?.count || 0}
                            pageSize={pageSize}
                        />
                    </div>
                    <div className={s.footer}>
                        <DividingLine />
                        <div className={s.footer_description}>
                            Холдинг безопасности «Нева», 2021. Все права защищены.
                        </div>
                    </div>
                </div>
            }
        </div>
    )
})

