import {useEffect, useState} from "react";

export function useKeyPressed(targetKey: string) {

    const [keyPressed, setKeyPressed] = useState<boolean>(false)

    //Нажатие на клавишу
    const downHandler = ({key}: KeyboardEvent) => {
        if (key === targetKey) {
            setKeyPressed(true)
        }
    }
    //Отпускание клавиши
    const upHandler = ({key}: KeyboardEvent) => {
        if (key === targetKey) {
            setKeyPressed(false)
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);
        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        }
    }, [])

    return keyPressed

}