import React, {useEffect, useState} from "react";
import {IModal} from "../../../../store/sidebar/types.data";
import {observer} from "mobx-react-lite";
import {Dialog, ListItem} from "@material-ui/core";
import {ModalHeader} from "../../../common/Header/ModalHeader/ModalHeader";
import {SimpleTreeSelect} from "../../Selects/SimpleTreeSelect";
import {ApplicationsStore} from "../../../../store/applications/Applications";
import WorkersPageStore from "../../../../store/WorkersPage/WorkersStore";
import {openNotification} from "../../notification";
import {useStyles} from "../../Styles/CommonStyles";

const s = require('./DelegationModal.module.scss')

interface IDelegationModalProps extends IModal {
    appId: number
}

export const DelegationModal: React.FC<IDelegationModalProps> = observer(({
                                                                              title,
                                                                              open,
                                                                              closeHandler,
                                                                              appId
                                                                          }) => {
    let a: { id: number }[] = []

    const {requestIudApplication, requestApplications} = ApplicationsStore
    const {workersWithGroups, requestWorkersWithGroups} = WorkersPageStore

    const [responsibles, setResponsibles] = useState<{ id: number }[]>([])
    useEffect(() => {
        requestWorkersWithGroups({})
    }, [])


    const common = useStyles()

    return <Dialog open={open} classes={{paper: common.dialogWithoutOverflow}}>
        <ModalHeader title={title} closeHandler={closeHandler}/>
        <div className={s.container}>
            <div className={s.content}>
                <SimpleTreeSelect
                    showSearch
                    multiple
                    width={300}
                    size={'xs'}
                    items={workersWithGroups}
                    onChange={(value, option) => {
                        if (value.length > responsibles.length) {
                            value.map((i: number) => setResponsibles([...responsibles, {id: i}]))
                        } else {
                            value.map((i: number) => a = [...a, {id: i}])
                            setResponsibles(a)
                        }
                    }}/>
            </div>
            <div className={s.footer}>
                <div className={s.btns}>
                    <ListItem
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: 'white',
                            border: '1px solid black',
                            borderRadius: 4,
                            height: 32,
                            width: 88,
                            color: 'black'
                        }}
                        button
                        title={'Отмена'}
                        onClick={closeHandler}
                    >Отмена</ListItem>
                    <ListItem
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: 'black',
                            borderRadius: 4,
                            height: 32,
                            width: 120,
                            color: 'white'
                        }}
                        button
                        title={'Делегировать'}
                        onClick={() => {
                            responsibles.length > 0 ?
                                requestIudApplication({
                                    id: appId,
                                    flag: 'delegate',
                                    responsible: responsibles,


                                }, () => {
                                    openNotification({
                                        type: 'success',
                                        message: 'Заявка успешно делегирована'
                                    })
                                    closeHandler()
                                    //TODO Добавить перезапрос сюда
                                    requestApplications({})
                                    
                                }) : openNotification({
                                    type: 'warning',
                                    message: 'Выберите ответственных'
                                })
                        }}
                    >Делегировать</ListItem>
                </div>
            </div>
        </div>
    </Dialog>
})