import React, {useState} from 'react'
import {IDeviceListItem, ISystemFullInfo} from "../../../store/directory/types";
import {Link} from "react-router-dom";
import {ROUTE_PATH} from "../../../routers/paths.main";
import {CrossIcon, PencilIcon} from "../../../icons/icons";
import {observer} from "mobx-react-lite";
import {DirectoryStore} from "../../../store/directory/DirectoryStore";
import {DeleteModal} from "../../helpers/Modals/DeleteModal";
import {openNotification} from "../../helpers/notification";
import {TEnumItem} from "../../helpers/utils";
import {ApplicationsStore} from "../../../store/applications/Applications";
import {useSelector} from "react-redux";
import {TRootState} from "../../../redux/store";
import {formviewAvailabilityFunc} from "../../helpers/fromviewHelpers/formviewAvailability";

const s = require('./style.module.scss')

interface IDevicesItemProps {
    item: ISystemFullInfo
    reqHandler: any
}

export const DevicesItem: React.FC<IDevicesItemProps> = observer(({
                                                                      item,
                                                                      reqHandler
                                                                  }) => {

    const formviews = useSelector((state:TRootState)=>state.authReducer.myInfo?.formviews)


    //На случай, если захотят иуд
    const [deleteModal, setDeleteMode] = useState<boolean>(false)

    const {requestIudSystems} = ApplicationsStore
    const {setSystemFullInfo} = DirectoryStore

    const deleteHandler = () => {
        requestIudSystems({
            flag: 'D',
            id: item.id
        }, () => {
            openNotification({
                type: 'success',
                message: 'Удаление прошло успешно'
            })
            reqHandler()
            setDeleteMode(false)
        })
    }

    return <div className={s.deviceItem}>
        <div className={s.row}>
            <div className={s.item}>
                {item?.name}
            </div>
            {item?.isCustom &&  <div className={s.item}>
                {formviewAvailabilityFunc(formviews || [], 'edit_system') && <Link
                    to={ROUTE_PATH.extraPage.iudDevices}
                    style={{
                        width: 14,
                        height: 16,
                        marginRight: 8
                    }}
                    onClick={() => {
                        setSystemFullInfo(item)
                        DirectoryStore.setIudFlag('U')
                    }}
                >
                    <PencilIcon
                        style={{cursor: 'pointer'}}/>
                </Link>}
                {formviewAvailabilityFunc(formviews || [], 'delete_system') && <CrossIcon onClick={() => {
                    setDeleteMode(true)
                }} style={{cursor: 'pointer'}}/>}

                <DeleteModal
                    open={deleteModal}
                    title={'данной пультовой системы'}
                    closeHandler={() => setDeleteMode(false)}
                    request={deleteHandler}
                />
            </div>}
        </div>
    </div>
})


