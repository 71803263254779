import React from "react";
import { formatIsoToDate } from "../../helpers/utils";
import { IActionsItem } from "../../../redux/reducers/applications/types.data";

const s = require('./style.module.scss')

interface IActionsTableItemProps {
    item: IActionsItem
}

export const ActionsTableItem: React.FC<IActionsTableItemProps> = ({ item }) => {
    return <div className={s.row}>
        <div className={s.item} style={{ flex: '0 0 8%' }}>{formatIsoToDate(item?.date) + ' ' + item?.date[11] + item?.date[12] + item?.date[13] + item?.date[14] + item?.date[15]}</div>
        <div className={s.item} style={{ flex: '0 0 3%' }}>{item?.code}</div>
        <div className={s.item} style={{ flex: '0 0 4%' }}>{item?.part}</div>
        <div className={s.item} style={{ flex: '0 0 4%' }}>{item?.zone}</div>
        <div className={s.item} style={{ flex: '0 0 8%' }}>{item?.eventQaulifier}</div>
        <div className={s.item} style={{ flex: '0 0 60%' }}>{item?.eventDesc}</div>
    </div>
}

export const ActionsTableCardItem: React.FC<IActionsTableItemProps> = ({ item }) => {
    return <div className={s.card}>
        <div className={s.item}>
            <div className={s.title}>
                Дата
            </div>
            <div className={s.value}>
                {formatIsoToDate(item?.date) + ' ' + item?.date[11] + item?.date[12] + item?.date[13] + item?.date[14] + item?.date[15]}
            </div>
        </div>
        <div className={s.item}>
            <div className={s.title}>
                Код
            </div>
            <div className={s.value}>
                {item?.code}
            </div>
        </div>
        <div className={s.item}>
            <div className={s.title}>
                Раздел
            </div>
            <div className={s.value}>
                {item?.part}
            </div>
        </div>
        <div className={s.item}>
            <div className={s.title}>
                Шлейф
            </div>
            <div className={s.value}>
                {item?.zone}
            </div>
        </div>
        <div className={s.item}>
            <div className={s.title}>
                Класс события
            </div>
            <div className={s.value}>
                {item?.eventQaulifier}
            </div>
        </div>
        <div className={s.item}>
            <div className={s.title}>
                Описание события
            </div>
            <div className={s.value}>
                {item?.eventDesc}
            </div>
        </div>
    </div>
}