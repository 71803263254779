import React from "react";
import {IInitialPage} from "../../../store/extraPage/types";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentPage} from "../../../redux/reducers/applications/actions/actions";
import {TRootState} from "../../../redux/store";

const s = require('./style.module.scss')

interface ISubPageItemProps {
    item: IInitialPage,
    commentsCount: number
    taskActionsCount: number
    taskHistoryCount: number
}

export const SubPageItem: React.FC<ISubPageItemProps> = ({item, commentsCount, taskActionsCount, taskHistoryCount}) => {

    const dispatch = useDispatch()

    const taskEventsCount = useSelector((state: TRootState) => state.applicationsReducer.taskActions?.count)

    return <div
        onClick={() => {
            dispatch(setCurrentPage(item.id))
        }}
        className={s.item + ' ' + (item.isActive && s.active)}>
        {item.name + ' ' + `(${item.id === 0 ? commentsCount : item.id === 1 ? taskHistoryCount : item.id === 2 && taskEventsCount || taskActionsCount})`}
    </div>
}