import { makeAutoObservable, runInAction } from "mobx";
import {
    IApplicationInfo,
    IApplications,
    IApplicationTag,
    IClient, IDeviceListItem,
    IReloadTask,
    ISaveFileBody, ITaskComment, ITaskCommentsList,
    TGetClient, TGetSystems, TGetTaskComments,
    TIudApplication, TIudSystems, TSendComment
} from "./types";
import { instance, key } from "../api";
import { TGetApplicationList } from "../api/bodies/types";
import { checkError, isError } from "../api/helpers";
import { TEnumItem } from "../../components/helpers/utils";
import { ISimpleTreeSelectItem } from "../../components/helpers/Selects/types";
import { ISystemFullInfo } from "../directory/types";
import moment, { Moment } from "moment";
import axios from "axios";
import { convertDataURIToBinary } from "../../components/helpers/FileInput/utils";
import { paths } from "../api/paths";

class Applications {

    applications: IApplications | null = null
    applicationsLoading: boolean = false


    applicationInfo: IApplicationInfo | null = null
    applicationsInfoLoading: boolean = false


    applicationId: number | null = null

    appStatuses: TEnumItem[] = [
        {
            id: 0,
            name: 'Взять в работу'
        },
        {
            id: 1,
            name: 'Завершить задачу'
        }
    ]
    appStatusesLoading: boolean = false

    searchValueStore: string = ''

    periods: TEnumItem[] = []
    periodsLoading: boolean = false

    taskSamples: TEnumItem[] = []
    taskSamplesLoading: boolean = false

    systems: TEnumItem[] = []
    systemsList: ISystemFullInfo[] | null = null
    systemsLoading: boolean = false

    devices: TEnumItem[] = []
    devicesList: IDeviceListItem[] = []
    devicesLoading: boolean = false

    workersTree: ISimpleTreeSelectItem[] = []
    workersTreeLoading: boolean = false

    clientInfo: IClient | null = null
    clientInfoLoading: boolean = false

    tags: IApplicationTag[] = []
    tagsLoading: boolean = false

    flag: 'new' | 'edit' | null = 'edit'

    categories: TEnumItem[] = []
    categoriesLoading: boolean = false

    pults : any = null


    commentsList: ITaskCommentsList | null = null
    commentsLoading: boolean = false

    calculatedTaskDate: string | undefined = undefined
    calculatedTaskDateLoading: boolean = false

    date: Moment = moment()
    
    activeFlag:string | null = localStorage.getItem('flag')

    constructor() {
        makeAutoObservable(this)
    }

    setActiveFlag = (value: string | null) => {
        this.activeFlag = value
    }

    setDate = (date:Moment) => {
        this.date = date
    }


    setApplicationId(applicationId: number) {
        this.applicationId = applicationId
    }

    setSearchValueStore(str: string) {
        this.searchValueStore = str
    }

    setFlag(flag: 'new' | 'edit') {
        this.flag = flag
    }

    setDevices() {
        this.devices = [];
    }

    requestApplications = async (data: TGetApplicationList, onSuccess?: () => void) => {
        runInAction(() => this.applicationsLoading = true)
        try {
            const res = await instance().close().getApplicationList(data)
            if (data.flag === 'samples') {
                this.taskSamples = res.data?.data?.value?.map((i: any) => {
                    return {
                        id: i.id,
                        name: i.name
                    }
                })
            } else this.applications = res.data?.data || {}
            onSuccess && onSuccess()
        } catch (e) {
            isError(e)
            checkError(e) 
            debugger
        }
        runInAction(() => this.applicationsLoading = false)
    }

    requestCategories = async () => {
        this.categoriesLoading = true
        try {
            const res = await instance().close().getDirectoryCategories({
                offset: 0,
                count: 1024
            })
            this.categories = res.data?.data?.value.map((i: any) => {
                return {
                    id: i.id,
                    name: i.name
                }
            })
        } catch (e) {
            isError(e)
            checkError(e)
        }
        this.categoriesLoading = false
    }

    requestApplicationInfo = async (id: number, onSuccess?: (value: IApplicationInfo) => void) => {
        this.applicationsInfoLoading = true
        try {
            const res = await instance().close().getApplicationInfo(id)
            this.applicationInfo = res.data.data.value
            onSuccess && onSuccess(res.data.data.value)
        } catch (e) {
            checkError(e)
            isError(e)
        }
        this.applicationsInfoLoading = false
    }

    requestIudApplication = async (data: TIudApplication, onSuccess: (id: number) => void) => {
        this.applicationsLoading = true
        try {
            const res = await instance().close().iudApplication(data)
            onSuccess && onSuccess(res.data?.data?.value?.id)
        } catch (e) {
            checkError(e)
            isError(e)
        }
        this.applicationsLoading = false
    }

    requestAppStatuses = async () => {
        this.appStatusesLoading = true
        try {
            const res = await instance().close().getAppStatuses()
            this.appStatuses = res.data?.data.value || []
        } catch (e) {
            checkError(e)
            isError(e)
        }
        this.appStatusesLoading = false
    }


    requestTaskSamples = async () => {
        this.taskSamplesLoading = true
        try {
            const res = await instance().close().getTaskSamples()
            this.taskSamples = res.data?.data?.value
        } catch (e) {
            checkError(e)
            isError(e)
        }
        this.taskSamplesLoading = false
    }

    requestPeriods = async () => {
        this.periodsLoading = true
        try {
            const res = await instance().close().getPeriods()
            this.periods = res.data?.data?.value
        } catch (e) {
            checkError(e)
            isError(e)
        }
        this.periodsLoading = false
    }

    requestSystems = async (data?: TGetSystems, systemFullInfo?: boolean) => {
        this.systemsLoading = true
        try {
            const res = await instance().close().getSystems(data)
            systemFullInfo
                ? this.systemsList = res.data?.data?.value
                : this.systems = res.data?.data?.value
        } catch (e) {
            checkError(e)
            isError(e)
        }
        this.systemsLoading = false
    }
    requestIudSystems = async (data?: TIudSystems, onSuccess?: () => void) => {
        this.systemsLoading = true
        try {
            const res = await instance().close().iudSystem(data)
            onSuccess && onSuccess()
        } catch (e) {
            checkError(e)
            isError(e)
        }
        this.systemsLoading = false
    }

    requestDevices = async (panel?: string | number, searchValue?: string) => {
        this.devicesLoading = true
        try {
            
            const res = await instance().close().getDevices(panel, searchValue)
            this.devices = res.data?.data?.value.map((element, index) => {
                return (
                    {
                        ...element, id : index, name : `${element.number} ` + element.name
                    }
                )
            })
            this.devicesList = res.data?.data?.value
        } catch (e) {
            checkError(e)
            isError(e)
        }
        this.devicesLoading = false
    }

    requestWorkersTree = async () => {
        this.workersTreeLoading = true
        try {
            const res = await instance().close().getWorkersTree()
            this.workersTree = res.data?.data?.value
        } catch (e) {
            checkError(e)
            isError(e)
        }
        this.workersTreeLoading = false
    }

    requestClient = async (data: TGetClient) => {
        this.clientInfoLoading = true
        try {
            const res = await instance().close().getClientInfo(data)
            this.clientInfo = res.data?.data?.value && res.data?.data?.value.length > 0 ? res.data?.data?.value[0] : null
        } catch (e) {
            checkError(e)
            isError(e)
        }
        this.clientInfoLoading = false
    }

    requestTags = async () => {
        this.tagsLoading = true
        try {
            const res = await instance().close().getTags()
            this.tags = res.data?.data?.value
        } catch (e) {
            checkError(e)
            isError(e)
        }
        this.tagsLoading = false
    }

    saveFile = async (body: ISaveFileBody, onSuccess?: () => void) => {

    let data = new FormData()
    
    body.Files?.map(file => {
        return data.append('sFile', new Blob([convertDataURIToBinary(file.Base64)]), file.Name)
    }) 
    
    const funcUrl = () => {
        return localStorage.getItem('api') || 'https://api.aska-crm.ru'
    }
    
    data.append('jsonData', JSON.stringify({
        "Id": body?.Id,
        "Type": "TASK",
        "Flag": 'I',
        Files: [...body.Files.map(i => ({
            Name:i.Name,
            Extention:i.Extention,
            Base64: "",
            Rewrite: true,
            DataType: 'TASK',
        }))]
    }))

    await axios({
        headers: {
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '',
            'Access-Control-Allow-Credentials': 'true',
            'Authorization': `Bearer ${localStorage.getItem(key.main)}`
        },
        method: 'post',
        url:  paths.baseURL + '/' + paths.baseURLCloseFiles + '/' + paths.saveFile,
        data,

    }).then((response) => {
    }).catch((err) => {
    })



        // let formData = new FormData()
        // debugger
        // formData.append('sFile', file, body?.Files[0]?.Name)
        // formData.append('jsonData', JSON.stringify(body))
        // try {
        //     const res = await instance().close().saveFile(body, formData)
        //     onSuccess && onSuccess()
        // } catch
        // (e) {
        //     checkError(e)
        // }
    }

    requestTaskComments = async (body: TGetTaskComments) => {
        this.commentsLoading = true
        try {
            const res = await instance().close().getTaskComments(body)
            this.commentsList = {
                comments: res.data?.data?.value || [],
                count: res.data?.data?.count || 0
            }
        } catch (e) {
            checkError(e)
            isError(e)
        }
        this.commentsLoading = false
    }

    requestCalculateTaskDate = async (data: { categoryId: number }) => {
        runInAction(() => this.calculatedTaskDateLoading = true)
        try {
            const res = await instance().close().calculateTaskDate(data)
            this.calculatedTaskDate = res.data?.data?.value
        }
        catch (e) {
            checkError(e)
            isError(e)
        }
        runInAction(() => this.calculatedTaskDateLoading = false)
    }

    requestSendComment = async (body: TSendComment, onSuccess?: () => void) => {
        try {
            const res = await instance().close().sendComment(body)
            onSuccess && onSuccess()
        } catch (e) {
            checkError(e)
            isError(e)
        }
    }


    requestRefreshTask = async (data : IReloadTask) => {
        this.applicationsLoading = true
        try {
            const res = await instance().close().refreshTask(data)
        } catch (e) {
            checkError(e)
            isError(e)
        }
        this.applicationsLoading = false
    }

    requestSelPults = async () => {
        this.applicationsLoading = true
        try {
            const res = await instance().close().selPults()
            this.pults = res?.data?.data?.value
        } catch (e) {
            checkError(e)
            isError(e)
        }
        this.applicationsLoading = false
    }
}



export const ApplicationsStore = new Applications()