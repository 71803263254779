import {applyMiddleware, combineReducers, createStore} from "redux";
import createSagaMiddleware from "redux-saga";
import {DirectoryReducer} from "./reducers/directory";
import thunkMiddleware from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension'
import {rootSaga} from "./rootSaga";
import {TDirectoryReducer} from "./reducers/directory/types";
import {authReducer} from "./reducers/auth";
import {TAuthReducer} from "./reducers/auth/types";
import {applicationsReducer} from "./reducers/applications";
import {TApplicationsReducer} from "./reducers/applications/types";

const initialState = {}

const combined = combineReducers({
    directoryReducer: DirectoryReducer,
    authReducer: authReducer,
    applicationsReducer: applicationsReducer,
})

const sagaMiddleware = createSagaMiddleware()

const middlewares = [sagaMiddleware, thunkMiddleware]
const store = createStore(
    combined,
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares))
)

export type TRootState = {
    directoryReducer: TDirectoryReducer
    authReducer: TAuthReducer
    applicationsReducer: TApplicationsReducer
}

sagaMiddleware.run(rootSaga)

export {store}