import React from "react";
import {observer} from "mobx-react-lite";
import {INotification} from "../../../../store/header/types";
import {formatIsoToDate} from "../../../helpers/utils";

const s = require('./Notifications.module.scss')

export interface INotificationItemProps {
    item: INotification
}

export const NotificationItem: React.FC<INotificationItemProps> = observer(({
                                                                                item
                                                                            }) => {
    return <div className={s.item} style={item.isNew ? {
        background: '#F1F3F5'
    } : {}}>
        < div className={s.line}>
            <div className={s.title}>
                {item?.title}
            </div>
            <div className={s.date}>
                {formatIsoToDate(item?.task?.date?.value)}
            </div>
        </div>
        <div>
            «{item?.body?.slice(20, item?.body?.length)}»
        </div>
    </div>
})