import React, { useEffect, useState } from "react";
import { MyPagination } from "../../helpers/MyPagination/MyPagination";
import { ActionsTableCardItem, ActionsTableItem } from "./ActionsTableItem";
import { IActionsItem, IActionsList } from "../../../redux/reducers/applications/types.data";
import { connect } from "react-redux";
import { TRootState } from "../../../redux/store";
import { getTaskActions } from "../../../redux/reducers/applications/actions/actions";
import { IGetTaskActions } from "../../../redux/reducers/applications/actions/types";
import { Fallback } from "../../helpers/Fallback/Fallback";
import { ArrowIcon, SimpleSelectDown, SimpleSelectUp } from "../../../icons/icons";

const s = require('./style.module.scss')

interface IActionsTableProps {
    taskActions: IActionsList | null
    taskActionsLoading: boolean
    getTaskActions: IGetTaskActions
    searchValue: string
    id_device: string | number
    id_system: string | number
    dateFrom: any
    dateTo: any
}

const ActionsTable: React.FC<IActionsTableProps> = ({
    taskActions,
    taskActionsLoading,
    getTaskActions,
    searchValue,
    id_device,
    id_system,
    dateFrom,
    dateTo
}) => {
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [orderById, setOrderById] = useState<1 | 2 | 3 | 4 | 5 | 6>(1)
    const PAGE_SIZE = 10
    const isMobile: boolean = window.outerWidth <= 425

    useEffect(() => {
        if (currentPage === 1) {
            getTaskActions({
                offset: 0,
                count: PAGE_SIZE,
                searchValue,
                orderById,
                id_device,
                id_system,
                dateFrom: dateFrom !== '' ? dateFrom : undefined,
                dateTo: dateTo !== '' ? dateTo : undefined
            })
        } else {
            getTaskActions({
                offset: (currentPage - 1) * PAGE_SIZE,
                count: PAGE_SIZE,
                searchValue,
                orderById,
                id_device,
                id_system,
                dateFrom: dateFrom !== '' ? dateFrom : undefined,
                dateTo: dateTo !== '' ? dateTo : undefined
            })
        }
    }, [currentPage, orderById])


    return <div className={s.container}>
        {taskActionsLoading ? <Fallback /> : <div>
            {!isMobile && <div className={s.headers}>
                <div
                    onClick={() => setOrderById(1)}
                    style={{ flex: '0 0 8%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>
                        Дата
                    </div>
                    <div
                        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 16, cursor: 'pointer' }}>
                        <SimpleSelectUp style={{ width: 6.97, height: 6 }} />
                        <SimpleSelectDown style={{ width: 6.97, height: 6 }} />
                    </div>
                </div>
                <div
                    onClick={() => setOrderById(2)}
                    style={{ flex: '0 0 3%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>
                        Код
                    </div>
                    <div
                        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 16, cursor: 'pointer' }}>
                        <SimpleSelectUp style={{ width: 6.97, height: 6 }} />
                        <SimpleSelectDown style={{ width: 6.97, height: 6 }} />
                    </div>
                </div>
                <div
                    onClick={() => setOrderById(3)}
                    style={{ flex: '0 0 4%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>
                        Раздел
                    </div>
                    <div
                        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 16, cursor: 'pointer' }}>
                        <SimpleSelectUp style={{ width: 6.97, height: 6 }} />
                        <SimpleSelectDown style={{ width: 6.97, height: 6 }} />
                    </div>
                </div>
                <div
                    onClick={() => setOrderById(4)}
                    style={{ flex: '0 0 4%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>
                        Шлейф
                    </div>
                    <div
                        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 16, cursor: 'pointer' }}>
                        <SimpleSelectUp style={{ width: 6.97, height: 6 }} />
                        <SimpleSelectDown style={{ width: 6.97, height: 6 }} />
                    </div>
                </div>
                <div
                    onClick={() => setOrderById(5)}
                    style={{ flex: '0 0 8%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>
                        Класс события
                    </div>
                    <div
                        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 16, cursor: 'pointer' }}>
                        <SimpleSelectUp style={{ width: 6.97, height: 6 }} />
                        <SimpleSelectDown style={{ width: 6.97, height: 6 }} />
                    </div>
                </div>
                <div
                    onClick={() => setOrderById(6)}
                    style={{ flex: '0 0 60%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>
                        Описание события
                    </div>
                    <div
                        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 16, cursor: 'pointer' }}>
                        <SimpleSelectUp style={{ width: 6.97, height: 6 }} />
                        <SimpleSelectDown style={{ width: 6.97, height: 6 }} />
                    </div>
                </div>
            </div>}
            <div className={s.body}>
                {taskActions && taskActions?.list && taskActions?.list?.length > 0 && taskActions?.list?.map((i: IActionsItem) => {
                    if (isMobile) return <ActionsTableCardItem item={i} />
                    else return <ActionsTableItem item={i} />
                })}
            </div>
            <MyPagination
                currentPage={currentPage}
                onChange={setCurrentPage}
                total={taskActions?.count || 0}
                pageSize={PAGE_SIZE}
            />
        </div>}
    </div>
}

const MapStateToProps = (state: TRootState) => ({
    taskActions: state.applicationsReducer.taskActions,
    taskActionsLoading: state.applicationsReducer.taskActionsLoading,
})

export const ActionsTableContainer = connect(MapStateToProps, { getTaskActions })(ActionsTable)