import React from "react";
import { IModal } from "../../../store/sidebar/types.data";
import { Dialog, ListItem } from "@material-ui/core";

const s = require('./style.module.scss')

interface IDeleteModalProps extends IModal {
    request: (e?: any) => void
}

export const DeleteModal: React.FC<IDeleteModalProps> = (({
    request,
    title,
    open,
    closeHandler
}) => {
    return <Dialog open={open}>
        <div className={s.deleteModal}>
            <div className={s.header}>
                {`Вы подтверждаете удаление ${title}`.toUpperCase()}
            </div>
            <div className={s.body}>
                <div className={s.btns}>
                    <ListItem
                        button
                        style={{
                            width: 90,
                            height: 32,
                            background: 'white',
                            border: '1px solid black',
                            borderRadius: 5,
                            color: 'black',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                        onClick={closeHandler}
                    >
                        Отмена
                    </ListItem>
                    <ListItem
                        button
                        onClick={request}
                        style={{
                            width: 90,
                            height: 32,
                            background: 'black',
                            borderRadius: 5,
                            color: 'white',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >Удалить</ListItem>
                </div>
            </div>
        </div>
    </Dialog>
})