import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { IApplicationItem } from "../../../store/applications/types";
import { formatIsoToDate } from "../../helpers/utils";

const s = require('./HistoryListItem.module.scss')

interface IHistoryListItemCardProps {
    item: IApplicationItem
}

export const HistoryListItemCard: React.FC<IHistoryListItemCardProps> = observer(({
    item
}) => {

    const [checked, setChecked] = useState<boolean>(false)

    return <div className={s.card + ' ' + (checked && s.checked)}
        onClick={() => {
            setChecked(!checked)
        }}


    >
        <div className={s.item}>
            <div className={s.title}>
                №
            </div>
            <div className={s.value}>
                {item?.number}
            </div>
        </div>
        <div className={s.item}>
            <div className={s.title}>
                Дата
            </div>
            <div className={s.value}>
                {formatIsoToDate(item?.dateCreate) + ' ' + item?.dateCreate[11] + item?.dateCreate[12] + item?.dateCreate[13] + item?.dateCreate[14] + item?.dateCreate[15]}
            </div>
        </div>
        <div className={s.item}>
            <div className={s.title}>
                Ответственные
            </div>
            <div className={s.value}>
                {item?.responsible?.map((i, index) =>
                    <span>{i.name}{index !== item.responsible.length - 1 && ','} </span>)}
            </div>
        </div>
        <div className={s.item}>
            <div className={s.title}>
                Клиент
            </div>
            <div className={s.value}>
                {item?.client?.name}
            </div>
        </div>
        <div className={s.item}>
            <div className={s.title}>
                Статус
            </div>
            <div className={s.value}>
                {item?.status?.name}
            </div>
        </div>
        <div className={s.item}>
            <div className={s.title}>
                Телефон клиента
            </div>
            <div className={s.value}>
                <a href={`tel:${item?.client?.phone?.value}`}>{item?.client?.phone?.value}</a>
            </div>
        </div>
    </div>
})