import { makeAutoObservable, runInAction } from "mobx";
import { ICountReports, IGetReportXLSX, IOnlyReports, IReports, TGetReports } from "./types";
import { instance } from "../api";
import { checkError, isError } from "../api/helpers";

class ReportsStore {
    reports: IReports 
    reportsCount: ICountReports
    reportsLoading: boolean = false

    downloadExcelLoading: boolean = false

    constructor() {
        makeAutoObservable(this)
    }

    requestReportsListOrItem = async (data: TGetReports) => {
        this.reportsLoading = true
        try {
            const res = await instance().close().getReports(data)
            this.reports = res.data?.response?.data
            this.reportsCount = res.data?.response?.data?.ticketCount
            debugger
        } catch (e) {
            checkError(e)
            isError(e)
        }
        this.reportsLoading = false
    }

    getReportXlSX = async (data: IGetReportXLSX) => {
        await instance().close().getReportXLSX(data).then(res => {
            runInAction(() => this.downloadExcelLoading = true)
            var a = document.createElement("a"); //Create <a>
            a.href = "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64," + res.data; //Image Base64 Goes here
            a.download = data?.reportName + '.' + data?.fileExtension; //File name Here
            a.click();
        }).catch(err => {
            checkError(err);
            isError(err)
            runInAction(() => this.downloadExcelLoading = false)
        }).finally(() => {
            runInAction(() => this.downloadExcelLoading = false);
        })

    }
}

const ReportPageStore = new ReportsStore()

export default ReportPageStore;