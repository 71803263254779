import React, {FC} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {ROUTE_PATH} from "../paths.main";
import {MyApplication} from "../../components/MyApplication/MyApplication";
import {NewApplicationContainer} from "../../components/NewApplication/NewApplication";

export const MyApplicationSwitch: FC = () => {
    return (
        <Switch>
            <Route
                path={ROUTE_PATH.myApplications.main}
                component={()=><MyApplication flag={'my'} title={'Мои заявки'}/>}
            />
            <Route
                path={ROUTE_PATH.newApplication}
                component={() => <NewApplicationContainer/>}
            />
        </Switch>
    )
}