import { Dialog, ListItem } from '@material-ui/core'
import React from 'react'
import { IModal } from '../../../store/sidebar/types.data'
import { ModalHeader } from '../../common/Header/ModalHeader/ModalHeader'

const s = require('./style.module.scss')


interface IAreYouSureModalProps extends IModal {
    submitHandler: () => void
}

export const AreYouSureModal: React.FC<IAreYouSureModalProps> = ({
    closeHandler,
    open,
    title,
    submitHandler
}) => {
    return <Dialog open={open}>
        <ModalHeader title={title} closeHandler={closeHandler} />
        <div className={s.areYouSureModal}>
            <ListItem
                button
                onClick={closeHandler}
                style={{
                    width: 90,
                    display: 'flex',
                    justifyContent: 'center',
                    height: 37,
                    background: 'white',
                    color: 'black',
                    border: '1px solid black',
                    borderRadius: 4
                }}
            >Отмена</ListItem>
            <ListItem
                button
                onClick={submitHandler}
                style={{
                    width: 90,
                    display: 'flex',
                    justifyContent: 'center',
                    height: 37,
                    background: 'black',
                    color: 'white',
                    border: '1px solid black',
                    borderRadius: 4,
                    marginLeft: 8
                }}
            >Применить</ListItem>
        </div>
    </Dialog>
}