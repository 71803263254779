import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { connect } from "react-redux";
import { TRootState } from "../../../redux/store";
import { IApplicationItem } from "../../../store/applications/types";
import { getDirectoryTemplates, setIudFlag } from "../../../redux/reducers/directory/actions/actions";
import { IGetDirectoryTemplates, ISetIudFlag } from "../../../redux/reducers/directory/actions/types";
import { Fallback } from "../../helpers/Fallback/Fallback";
import { DirectoryItem } from "../Groups/DirectoryItem/DirectoryItem";
import { MyPagination } from "../../helpers/MyPagination/MyPagination";

interface ITemplatesProps {
    templates: IApplicationItem[] | null
    loading: boolean
    getDirectoryTemplates: IGetDirectoryTemplates
    setIudFlag: ISetIudFlag
    templatesCount: number
}

const Templates: React.FC<ITemplatesProps> = observer(({
    loading,
    templates,
    getDirectoryTemplates,
    setIudFlag,
    templatesCount
}) => {

    const PAGE_SIZE: number = 15

    const [currentPage, setCurrentPage] = useState<number>(1)

    const searchHandler = () => {
        getDirectoryTemplates({
            offset: PAGE_SIZE * (currentPage - 1),
            count: PAGE_SIZE,
            flag: 'samples'
        })
    }

    useEffect(() => {
        searchHandler()
    }, [currentPage])

    return <div>
        {loading
            ? <Fallback />
            : templates && templates.length > 0 && templates.map((i) =>
                <DirectoryItem
                    searchHandler={searchHandler}
                    setIudFlag={setIudFlag}
                    flag={'T'}
                    template={i}
                />)}
        <MyPagination
            onChange={setCurrentPage}
            currentPage={currentPage}
            pageSize={PAGE_SIZE}
            total={templatesCount}
        />
    </div>

})

const MapStateToProps = (state: TRootState) => ({
    templates: state.directoryReducer.templates,
    templatesCount: state.directoryReducer.templatesCount,
    loading: state.directoryReducer.loadings.templateLoading
})

export const TemplatesContainer = connect(MapStateToProps, { getDirectoryTemplates, setIudFlag })(Templates)