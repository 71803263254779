import axios from "axios";
import { paths } from "./paths";
import { IRequestAuthData, TGetApplicationList } from "./bodies/types";
import { TAddWorkersToGroup, TGetWorkersListOrItem, TIudGroup, TIudWorker } from "../WorkersPage/types";
import {
    IReloadTask,
    ISaveFileBody,
    TGetClient,
    TGetSystems,
    TGetTaskComments,
    TIudApplication, TIudSystems,
    TSendComment
} from "../applications/types";
import { TPaginationReq } from "../../redux/types";
import { TIudRole } from "../../redux/reducers/directory/types.data";
import { TGetHistoryList, TGetTaskActions } from "../../redux/reducers/applications/types.data";
import { IIudDeviceItem, IIudMarkItem, TGetCelebrationDays, TGetDevicesList, TGetMarksList, TGetTCelebrationDayTypes, TIudCelebrationDay } from "../directory/types";
import { IGetReportXLSX, TGetReports } from "../reportsPage/types";


export const key = {
    main: 'CLIENT_TOKEN',
    refresh: 'CLIENT_TOKEN_REFRESH',
    serviceUrl: process.env.REACT_APP_CLIENT_SERVICE_URL
        ? process.env.REACT_APP_CLIENT_SERVICE_URL
        : ''
}
const auth = () => axios.create({
    baseURL: paths.baseURL,
    headers: {
        //mode: 'no-cors',
        ContentType: 'application/json'
    }
})

const refreshTok = (token: string | null) => axios.create({
    baseURL: paths.baseURL + '/' + 'Account/',
    headers: {
        //mode: 'no-cors',
        Authorization: `Bearer ${token}`,
        ContentType: 'application/json'
    }
})

const instanceAxiosClose = (token: string | null) => axios.create({
    baseURL: paths.baseURL + '/' + paths.baseURLClose,
    headers: {
        //mode: 'no-cors',
        Authorization: `Bearer ${token}`,
        ContentType: 'application/json'
    }
})

const instanceAxiosCloseTasks = (token: string | null) => axios.create({
    baseURL: paths.baseURL + '/' + paths.baseURLCloseTasks,
    headers: {
        //mode: 'no-cors',
        Authorization: `Bearer ${token}`,
        ContentType: 'application/json'
    }
})

const instanceAxiosCloseReport = (token: string | null) => axios.create({
    baseURL: paths.baseURL + '/' + paths.baseURLCloseReport,
    headers: {
        //mode: 'no-cors',
        Authorization: `Bearer ${token}`,
        ContentType: 'application/json'
    }
})



const instanceAxiosCloseDownloadReport = (token: string | null) => axios.create({
    baseURL: paths.baseURL,
    headers: {
        //mode: 'no-cors',
        Authorization: `Bearer ${token}`,
        ContentType: 'application/json'
    }
})

const instanceAxiosClosePult = (token: string | null) => axios.create({
    baseURL: paths.baseURL + '/' + paths.baseURLClosePult,
    headers: {
        //mode: 'no-cors',
        Authorization: `Bearer ${token}`,
        ContentType: 'application/json'
    }
})
const instanceAxiosOpen = (token: string | null) => axios.create({
    baseURL: paths.baseURL + '/' + paths.baseURLOpen,
    headers: {
        //mode: 'no-cors',
        Authorization: `Bearer ${token}`,
        ContentType: 'application/json'
    }
})

export const instance = () => {
    const mainKey = key.main
    const token = localStorage.getItem(mainKey)
    const refreshToken = localStorage.getItem(key.refresh)

    return {
        auth: () => ({
            authorization: (data: IRequestAuthData) => auth().post(paths.authorization, data)
        }),
        close: () => ({
            getRefreshToken : () => refreshTok(refreshToken).get(paths.refreshToken, {}),
            //Заявки
            getApplicationList: (data: TGetApplicationList) => instanceAxiosCloseTasks(token).post(paths.getApplicationList, data),
            getApplicationsCounts: () => instanceAxiosClose(token).post(paths.getApplicationsCounts, {}),
            getApplicationInfo: (id: number) => instanceAxiosClose(token).post(paths.getApplicationInfo, { id }),
            iudApplication: (data: TIudApplication) => instanceAxiosClose(token).post(paths.iudApplication, data),
            getAppStatuses: () => instanceAxiosClose(token).post(paths.getAppStatuses, {}),

            //История
            getTaskHistory: (data: TGetHistoryList) => instanceAxiosClose(token).post(paths.getTaskHistory, data),
            //Отчеты
            getReports: (data: TGetReports) => instanceAxiosCloseReport(token).post(paths.getReports, data),
            getReportXLSX: (body: IGetReportXLSX) => instanceAxiosCloseDownloadReport(token).post(paths.showReport, body),
            //Настройки
            getSettings: () => instanceAxiosCloseTasks(token).get(paths.getSettings, {}),
            postSettings: (data: any) => instanceAxiosCloseTasks(token).post(paths.postSettings, data),
            putSettings: (data: any) => instanceAxiosCloseTasks(token).post(paths.putSettings, data),
            deleteSettings: (data: any) => instanceAxiosCloseTasks(token).post(paths.deleteSettings, data),
            getSourcess: () => instanceAxiosCloseTasks(token).post(paths.getSources),
            getUserss: (data: any) => instanceAxiosClose(token).post(paths.getUsers, data),
            //События
            getTaskActions: (body: TGetTaskActions) => instanceAxiosClose(token).post(paths.getTaskActions, body),

            //Уведомления
            getMyNotifications: (offset: number, count: number) => instanceAxiosClose(token).post(paths.getMyNotifications, {
                offset,
                count
            }),
            checkedNotifications: () => instanceAxiosClose(token).post(paths.checkedNotifications, {}),


            //Сотрудники
            getWorkersListOrItem: (data: TGetWorkersListOrItem) => instanceAxiosClose(token).post(paths.getWorkersListOrItem, data),
            iudGroup: (data: TIudGroup) => instanceAxiosClose(token).post(paths.iudGroup, data),
            getWorkersWithGroups: (body: { flag?: string }) => instanceAxiosClose(token).post(paths.getWorkersWithGroups, body),
            getGroups: () => instanceAxiosClose(token).post(paths.getGroups, {}),
            addWorkersToGroup: (data: TAddWorkersToGroup) => instanceAxiosClose(token).post(paths.addWorkersToGroup, data),
            iudWorker: (body: TIudWorker) => instanceAxiosClose(token).post(paths.iudWorker, body),

            //Создание заявки
            getTaskSamples: () => instanceAxiosClose(token).post(paths.getTaskSamples, {}),
            getPeriods: () => instanceAxiosClose(token).post(paths.getPeriods, {}),
            getSystems: (data?: TGetSystems) => instanceAxiosClose(token).post(paths.getSystems, data || {}),
            iudSystem: (data?: TIudSystems) => instanceAxiosClose(token).post(paths.iudSystem, data),
            getDevices: (panel?: string | number, deviceId?: string) => instanceAxiosClosePult(token).post(paths.getDevices, {
                panel,
                deviceId
            }),
            getWorkersTree: () => instanceAxiosClose(token).post(paths.getWorkersTree, {}),
            getClientInfo: (data: TGetClient) => instanceAxiosClose(token).post(paths.getClientInfo, data),
            getTags: () => instanceAxiosClose(token).post(paths.getTags, {}),
            saveFile: (body: ISaveFileBody, data: FormData) =>
                axios.post(paths.baseURL + '/' + paths.baseURLCloseFiles + '/' + paths.saveFile,
                    data,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ),
            selPults : () => instanceAxiosClosePult(token).post(paths.selPult, {}),


            //Справочники
            getDirectoryGroups: (body: TPaginationReq) => instanceAxiosClose(token).post(paths.getDirectoryGroups, body),
            getDirectoryCategories: (body: TPaginationReq) => instanceAxiosClose(token).post(paths.getDirectoryCategories, body),
            getDirectoryTemplates: (body: TGetApplicationList) => instanceAxiosClose(token).post(paths.getApplicationList, body),
            getDirectoryRoles: (body: {}) => instanceAxiosClose(token).post(paths.getDirectoryRoles, body),
            getPriority: (body: TGetApplicationList) => instanceAxiosClose(token).post(paths.getPriority, body),
            iudCategory: (body: TGetApplicationList) => instanceAxiosClose(token).post(paths.iudCategory, body),
            iudDirectoryRole: (body: TIudRole) => instanceAxiosClose(token).post(paths.iudRole, body),
            getTaskComments: (body: TGetTaskComments) => instanceAxiosClose(token).post(paths.getTaskComments, body),
            sendComment: (body: TSendComment) => instanceAxiosClose(token).post(paths.sendComment, body),
            getDevicesList: (data: TGetDevicesList) => instanceAxiosClose(token).post(paths.getDevices, data),
            getMarksList: (body: TGetMarksList) => instanceAxiosClose(token).post(paths.getMarks, body),
            iudDevice: (body: IIudDeviceItem) => instanceAxiosClose(token).post(paths.iudDevice, body),
            iudMark: (body: IIudMarkItem) => instanceAxiosClose(token).post(paths.iudMark, body),
            getColors: () => instanceAxiosClose(token).post(paths.getColors, {}),
            getCelebrationDays: (body: TGetCelebrationDays) => instanceAxiosClose(token).post(paths.getCelebrationDays, body),
            iudCelebrationDay: (body: TIudCelebrationDay) => instanceAxiosClose(token).post(paths.iudCelebrationDay, body),
            getTypes: (body: TGetTCelebrationDayTypes) => instanceAxiosClose(token).post(paths.getTypes, body),
            calculateTaskDate: (data: { categoryId: number }) => instanceAxiosClose(token).post(paths.calculateTaskDate, data),
            //Информация о пользователе
            getMyInfo: () => instanceAxiosClose(token).post(paths.getMyInfo, {}),


            //формвью
            getFormview: (body: {}) => instanceAxiosClose(token).post(paths.getFormview, body),


            //Полный список ролей
            getFullRolesList: (body: {}) => instanceAxiosClose(token).post(paths.getFullRoleList, body),

            //Удаленные
            refreshTask : (body : IReloadTask) => instanceAxiosClose(token).post(paths.refreshTask, body)
        })
    }
}