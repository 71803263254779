import React, { useState } from 'react';
import { ArrowIcon, CheckMark } from "../../../icons/icons";
import { IconButton } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { MyButton } from "../../helpers/MyButton/MyButton";
import { useDispatch, useSelector } from "react-redux";
import { setAppOrTemplateFlag } from "../../../redux/reducers/directory/actions/actions";
import { useKeyPressed } from "../../helpers/useKeyPress/useKeyPress";
import { observer } from "mobx-react-lite";
import { DirectoryStore } from "../../../store/directory/DirectoryStore";
import { TRootState } from "../../../redux/store";
import { formviewAvailabilityFunc } from "../../helpers/fromviewHelpers/formviewAvailability";
import { CheckMarkIcon } from '../../../icons/CheckMark';
import { DirectoryNavigationList } from '../../ExtraPage/utils';
import { ISetCurrentPage } from '../../../redux/reducers/directory/actions/types';

const s = require('./style.module.scss');

interface IHeadTitleComponentProps {
    title?: string
    link?: string
    needBack?: boolean
    needButtons?: boolean
    needSearch?: boolean
    paddingLeft?: number
    setCurrentPage?: ISetCurrentPage
    needSubNavigation?: boolean


    [key: string]: any
}

export const HeadTitle: React.FC<IHeadTitleComponentProps> = observer(({
    title,
    needBack,
    needButtons,
    link,
    needSearch,
    paddingLeft,
    setCurrentPage,
    needSubNavigation
}) => {

    const router = useHistory()

    const dispatch = useDispatch()

    const [searchValue, setSearchValue] = useState<string>(DirectoryStore.searchValue || '')

    const { requestDevicesList } = DirectoryStore

    useKeyPressed('Enter') && DirectoryStore.setSearchValue(searchValue)
    useKeyPressed('Enter') && DirectoryStore.setCurrentPageStore(1)

    const formviews = useSelector((state: TRootState) => state.authReducer.myInfo?.formviews)


    const checkAddAbility = () => {
        if (window.location.href.includes('groups')) {
            return formviewAvailabilityFunc(formviews || [], 'add_groups')
        } else if (window.location.href.includes('categories')) {
            return formviewAvailabilityFunc(formviews || [], 'add_categories')
        } else if (window.location.href.includes('templates')) {
            return formviewAvailabilityFunc(formviews || [], 'add_samples')
        } else {
            return formviewAvailabilityFunc(formviews || [], 'add_roles')
        }
    }
    const isMobile: boolean = window.outerWidth <= 425

    const [show, setShow] = useState<boolean>(false)

    return (
        <div className={s.container} style={{ paddingLeft }}>
            <div className={s.title}>
                <IconButton className={needBack ? s.back : s.none} onClick={() => window.history.back()}>
                    <ArrowIcon />
                </IconButton>
                {title}
                {isMobile && needSubNavigation && <CheckMarkIcon color={'black'} style={show ? {
                    cursor: 'pointer',
                    transform: 'rotateX(180deg)',
                    transition: '1s',
                    marginLeft: 4,
                    width: 16,
                    height: 16,
                } : {
                    cursor: 'pointer',
                    transition: '1s',
                    marginLeft: 4,
                    width: 16,
                    height: 16,
                }} onClick={() => setShow(!show)} />}
                {isMobile && show && needSubNavigation && <div className={s.pages}>
                    {DirectoryNavigationList?.map(i => (<div onClick={() => {
                        setCurrentPage && setCurrentPage(i?.id)
                        router.push(i?.path)
                    }} className={s.item}>
                        {i?.name}
                    </div>
                    ))}
                </div>}
            </div>
            {/* {needSearch && <input
                type="text"
                value={searchValue}
                className={s.input}
                onChange={(e) => setSearchValue(e.currentTarget.value)}
                placeholder={'Поиск...'}
            />} */}
            {needButtons && checkAddAbility() && <Link to={link || '/'}>
                <MyButton
                    onClick={() => {
                        if (window.location.href.includes('groups') || window.location.href.includes('categories') || window.location.href.includes('templates') || window.location.href.includes('roles')) {

                            dispatch(setAppOrTemplateFlag('addTemplate'))
                        } else {

                            DirectoryStore.setIudFlag('I')
                        }
                    }}
                    flag={'white'}
                    border
                    className={s.button}
                >
                    Добавить
                </MyButton>
            </Link>}
        </div>
    );
})
