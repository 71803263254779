import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { HeaderPageStore } from "../../../../store/header/Header";
import { ExpandMore } from "@material-ui/icons";
import { NotificationItem } from "./NotificationItem";
import { Fallback } from "../../../helpers/Fallback/Fallback";
import notFound from '../../../../icons/FileNotFoundIcon.svg'

const s = require('./Notifications.module.scss')

export interface INotificationsProps {

}

export const Notifications: React.FC<INotificationsProps> = observer(({ }) => {

    const {
        notifications,
        notificationsList,
        notificationsLoading,
        checkedLoading,
        notificationsTotalCount
    } = HeaderPageStore
    // useEffect(() => {
    //     console.log(notificationsList)
    // }, [notificationsList])

    const [loadCount, setLoadCount] = useState<number>(3)


    return <div>
        <div className={s.main}>
            <div className={s.header}>
                Уведомления
            </div>
            <div style={{overflowY : notificationsList?.value ? 'scroll' : 'hidden' , maxHeight: '620px'}} className={s.notifications}>
                {notificationsLoading || checkedLoading ? (
                    <Fallback />
                ) : (
                    notificationsList?.value ? (
                        notificationsList.value.map(i => (
                            <NotificationItem
                                key={i.contractor.id}
                                item={i}
                            />
                        ))
                    ) : (
                        // Обработка случая, когда notificationsList?.value === null или undefined
                        <div style={{display : 'flex', alignItems: 'center', justifyContent : 'center'}}>
                            <img src={notFound} alt="notFound" />
                            <div style={{color : 'grey', padding: '16px 16px'}}>Нет данных по запросу "уведомления"</div>
                        </div>
                    )
                )}
            </div>
            <div className={s.pagination}
                onClick={() => {
                    HeaderPageStore.getMyNotification(0, loadCount)
                    setLoadCount(loadCount + 2)
                }}
            >
                Загрузить еще <ExpandMore />
            </div>
        </div>
    </div>
})