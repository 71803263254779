import React, {FC, useEffect, useState} from "react";
import {TRootState} from "../../../redux/store";
import {connect, useSelector} from "react-redux";
import {getDirectoryGroups, setChosenGroupId, setIudFlag} from "../../../redux/reducers/directory/actions/actions";
import {IGetDirectoryGroups, ISetChosenGroupId, ISetIudFlag} from "../../../redux/reducers/directory/actions/types";
import {IDirectoryGroup} from "../../../redux/reducers/directory/types.data";
import {Fallback} from "../../helpers/Fallback/Fallback";
import {DirectoryItem} from "./DirectoryItem/DirectoryItem";
import {MyPagination} from "../../helpers/MyPagination/MyPagination";

const s = require('./groups.module.scss')

interface IGroupsProps {
    groups: IDirectoryGroup[] | null
    loading: boolean
    getDirectoryGroups: IGetDirectoryGroups
    setChosenGroupId: ISetChosenGroupId
    setIudFlag: ISetIudFlag
    groupsCount: number
}

const Groups: FC<IGroupsProps> = ({
                                      getDirectoryGroups,
                                      groups,
                                      loading,
                                      setIudFlag,
                                      setChosenGroupId,
                                      children,
                                      groupsCount
                                  }) => {

    const PAGE_SIZE: number = 15
    const href = window.location.href.split('/')[window.location.href.split('/').length - 1]                               
    const [currentPage, setCurrentPage] = useState<number>(1)

    const myInfo = useSelector((state: TRootState) => state.authReducer.myInfo)


    const searchHandler = () => {
        getDirectoryGroups({
            offset: PAGE_SIZE * (currentPage - 1),
            count: PAGE_SIZE
        })
    }

    useEffect(() => {
        searchHandler()
    }, [currentPage])


    // useEffect который выкидывает со страницы если нет прав на таковую
    useEffect(() => {
        if (myInfo) {
            let formviewsPages = myInfo?.formviews?.filter(element => element?.type_id === 2).map(element => element?.mnemo)
            if (formviewsPages?.includes('directory') && href === 'groups') {
                return
            }
            else {
                window.location.href = '/'
            }
        }
    }, [href, myInfo])



    return <div className={s.container}>
        {loading ? <Fallback/> : <div className={s.main}>
            {groups && groups.length > 0 && groups.map((i) => <DirectoryItem
                searchHandler={searchHandler}
                setIudFlag={setIudFlag}
                setChosenGroupId={setChosenGroupId}
                flag={'G'} group={i}/>)}
        </div>}
        <MyPagination
            onChange={setCurrentPage}
            currentPage={currentPage}
            pageSize={PAGE_SIZE}
            total={groupsCount}
        />
    </div>
}

const MapStateToProps = (state: TRootState) => ({
    groups: state.directoryReducer?.groups,
    groupsCount: state.directoryReducer?.groupsCount,
    loading: state.directoryReducer?.loadings?.groupLoading
})

export const GroupsContainer = connect(MapStateToProps, {getDirectoryGroups, setChosenGroupId, setIudFlag})(Groups)