import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {Dialog, ListItem} from "@material-ui/core";
import {SimpleInput} from "../../../helpers/Inputs/inputs";
import {IModal} from "../../../../store/sidebar/types.data";
import {openNotification} from "../../../helpers/notification";
import WorkersPageStore from "../../../../store/WorkersPage/WorkersStore";

const s = require('./CreateGroupModal.module.scss')

interface ICreateGroupModalProps extends IModal {

}

export const CreateGroupModal: React.FC<ICreateGroupModalProps> = observer(({
                                                                                title,
                                                                                open,
                                                                                closeHandler
                                                                            }) => {

    const [name, setName] = useState<string | null>(null)

    return <Dialog open={open} onClose={closeHandler}>
        <div className={s.container}>
            <div className={s.header}>
                {title}
            </div>
            <div className={s.body}>
                <div className={s.item}>
                    <div className={s.title}>
                        Введите название создаваемой группы :
                    </div>
                    <SimpleInput
                        onChange={(e) => setName(e.currentTarget.value)}
                        width={356}
                        size={'md'}
                    />
                </div>
            </div>
            <div className={s.footer}>
                {/*<div className={s.btns}>
                    <ListItem
                        button
                        onClick={closeHandler}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: '1px solid black',
                            width: 109,
                            height: 37,
                            background: 'white',
                            color: 'black',
                            borderRadius: '5px'
                        }}
                    >
                        Отмена
                    </ListItem>
                </div>*/}
                <ListItem
                    button
                    onClick={() => {
                        name ?
                            WorkersPageStore.requestIudGroup({
                                flag: 'I',
                                name: name
                            }, () => {
                                openNotification({
                                    type: 'success',
                                    message: 'Группа успешно создана'
                                })
                                closeHandler()
                            })
                            : openNotification({
                                type: 'warning',
                                message: 'Введите название группы'
                            })
                    }}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 109,
                        height: 37,
                        background: 'black',
                        color: 'white',
                        borderRadius: '5px'
                    }}
                >
                    Создать
                </ListItem>
            </div>
        </div>
    </Dialog>
})