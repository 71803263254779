import { ListItem } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { ArrowIcon } from '../../../icons/icons'
import { ROUTE_PATH } from '../../../routers/paths.main'
import { DirectoryStore } from '../../../store/directory/DirectoryStore'
import { ICelebrationDayItem } from '../../../store/directory/types'
import { SimpleCheckbox } from '../../common/CheckBox/CheckBox'
import { SimpleInput } from '../../helpers/Inputs/inputs'
import { SimpleTextArea } from '../../helpers/Inputs/Textarea'
import { openNotification } from '../../helpers/notification'
import { SimpleSelect } from '../../helpers/Selects/SimpleSelect'
import { formatIsoToDate } from '../../helpers/utils'
import { DatePickerComponent, RangeDatePicker } from '../../NewApplication/DatePicker/DatePickerComponent'

const s = require('./style.module.scss')

interface IIudCelebrationDayProps {
}

export const IudCelebrationItem: React.FC<IIudCelebrationDayProps> = observer(({ }) => {

    //isMobileOrNot
    const isMobile: boolean = window.outerWidth <= 425

    //helpers
    const router = useHistory()

    // changing data
    const [name, setName] = useState<string>('')
    const [date, setDate] = useState<Date | undefined>(undefined)
    const [isWorkDay, setIsWorkDay] = useState<boolean>(false)
    const [description, setDescription] = useState<string>('')
    const [dayType, setDayType] = useState<number | undefined>(undefined)
    //noChanging data
    const { celebrationDayInfo, requestIudCelebrationDay, iudFlag, requestCelebrationDayTypes, celebrationDayTypes, celebrationDayTypesLoading } = DirectoryStore

    //button disable
    const [disabledButton, setDisabledButton] = useState<boolean>(false)

    //handler
    const iudHandler = () => {

        requestIudCelebrationDay({
            flag: iudFlag,
            alias: description,
            day: date ? formatIsoToDate(date.toISOString()) : undefined,
            id: celebrationDayInfo?.id,
            isWork: isWorkDay,
            name,
            type: dayType ? {
                id: dayType
            } : undefined
        }, () => {
            openNotification({
                type: 'success',
                message: 'Успешно !'
            })
            router.push(ROUTE_PATH.extraPage.celebrationDays)
            setDisabledButton(false)
        })
    }



    //effects
    useEffect(() => {
        requestCelebrationDayTypes({
            offset: 0,
            count: 1000,
            mnemo: 'workdays'
        })
    }, [])

    useEffect(() => {
        if (iudFlag === 'U') {
            celebrationDayInfo?.name && setName(celebrationDayInfo?.name)
            celebrationDayInfo?.alias && setDescription(celebrationDayInfo?.alias)
            celebrationDayInfo?.day && setDate(new Date(celebrationDayInfo?.day))
            celebrationDayInfo?.isWork !== undefined && setIsWorkDay(celebrationDayInfo?.isWork)
            celebrationDayInfo?.type !== undefined && setDayType(+celebrationDayInfo?.type?.id)

        }
    }, [celebrationDayInfo])

    return <div className={s.iudDay}>
        <Link to={ROUTE_PATH.extraPage.celebrationDays}>
            <div style={{ display: 'flex', alignItems: "baseline" }}>
                <ArrowIcon style={{
                    transform: 'rotate(180deg)'
                }} />
                <div style={{ color: 'black', fontWeight: 600, fontSize: 24, marginLeft: 6 }}>
                    НАЗАД
                </div>
            </div>
        </Link>

        <div className={s.item}>
            <div className={s.title}>
                Наименование:
            </div>
            <SimpleInput
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
            />
        </div>

        <div className={s.item}>
            <div className={s.title}>
                Описание:
            </div>
            <SimpleTextArea
                value={description}
                rows={6}
                onChange={(e) => setDescription(e.currentTarget.value)}
            />
        </div>

        <div className={s.item}>
            <div className={s.title}>
                Тип :
            </div>
            <SimpleSelect
                items={celebrationDayTypes || []}
                loading={celebrationDayTypesLoading}
                value={dayType}
                onChange={setDayType}
            />
        </div>

        <div className={s.item} style={{ display: 'flex', alignItems: 'center' }}>
            <div className={s.title}>
                Рабочий день:
            </div>
            <SimpleCheckbox
                value={isWorkDay}
                onChange={() => setIsWorkDay(!isWorkDay)}
            />
        </div>

        <div className={s.item}>
            <div className={s.title}>
                Календарное время:
            </div>
            <DatePickerComponent
                width={isMobile ? '100%' : undefined}
                showTime={false}
                onChange={(value: Date | undefined) => {

                    setDate(value)
                }}
                value={date}
            />
        </div>
        <div className={s.divider}>
        </div>

        <div className={s.btns}>
            <ListItem
                style={{
                    width: 104,
                    height: 37,
                    background: 'black',
                    border: '1px solid #212529',
                    borderRadius: 5,
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    cursor: disabledButton ? 'not-allowed' : 'pointer'
                }}
                button
                disabled={disabledButton}
                onClick={iudHandler}
            >
                Сохранить
            </ListItem>
        </div>
    </div>
})