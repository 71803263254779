import { CLEAR_SUBGROUPS_ROLES } from './../types';
import {
    IClearSubgroupsRoles,
    IGetDirectoryCategories,
    IGetDirectoryGroups,
    IGetDirectoryRoles,
    IGetDirectoryTemplates,
    IGetFormviews,
    IGetFullRolesList,
    IGetPriority,
    IIudCategory,
    IIudDirectoryRole,
    IIudGroup,
    ISetApplicationId,
    ISetAppOrTemplateFlag,
    ISetChosenGroupId,
    ISetCurrentPage,
    ISetDateTaskFilter,
    ISetDirectoryCategories, ISetDirectoryCategoriesCount,
    ISetDirectoryCategoriesLoading,
    ISetDirectoryGroups, ISetDirectoryGroupsCount,
    ISetDirectoryGroupsLoading,
    ISetDirectoryRoleFullInfo,
    ISetDirectoryRoles,
    ISetDirectoryRolesLoading,
    ISetDirectoryTemplateLoading,
    ISetDirectoryTemplates, ISetDirectoryTemplatesCount,
    ISetFormviews,
    ISetFormviewsLoading,
    ISetFullRolesList,
    ISetFullRolesListLoading,
    ISetGroupFormViews,
    ISetIudFlag,
    ISetPriority,
    ISetPriorityLoading,
    ISetRoleGroup,
    ISetSubgroupParentId, ISetVisiblePages, ISetVisiblePagesLoading
} from "./types";
import {
    IUD_CATEGORY,
    IUD_DIRECTORY_ROLE,
    IUD_GROUP,
    REQUEST_DIRECTORY_CATEGORIES,
    REQUEST_DIRECTORY_GROUPS,
    REQUEST_DIRECTORY_ROLES,
    REQUEST_DIRECTORY_TEMPLATES,
    REQUEST_FORMVIEWS,
    REQUEST_PRIORITY,
    REQUEST_ROLES_LIST,
    SET_APPLICATION_ID,
    SET_CHOSEN_GROUP_ID,
    SET_CURRENT_INITIAL_PAGE,
    SET_DATES_FOR_TASK_FILTER,
    SET_DIRECTORY_CATEGORIES, SET_DIRECTORY_CATEGORIES_COUNT,
    SET_DIRECTORY_CATEGORIES_LOADING,
    SET_DIRECTORY_GROUPS, SET_DIRECTORY_GROUPS_COUNT,
    SET_DIRECTORY_GROUPS_LOADING,
    SET_DIRECTORY_ROLE_INFO,
    SET_DIRECTORY_ROLES,
    SET_DIRECTORY_ROLES_LOADING,
    SET_DIRECTORY_TEMPLATES, SET_DIRECTORY_TEMPLATES_COUNT,
    SET_DIRECTORY_TEMPLATES_LOADING,
    SET_FORMVIEWS,
    SET_FORMVIEWS_LOADING, SET_GROUP_FORMVIEWS,
    SET_IUD_FLAG,
    SET_NEW_APPLICATION_OR_TEMPLATE_FLAG,
    SET_PRIORITY,
    SET_PRIORITY_LOADING, SET_ROLE_GROUP, SET_ROLES_LIST, SET_ROLES_LIST_LOADING,
    SET_SUBGROUP_PARENT_ID, SET_VISIBLE_PAGES, SET_VISIBLE_PAGES_LOADING
} from "../types";
import { TEnumItem } from "../../../../components/helpers/utils";

export const getDirectoryGroups: IGetDirectoryGroups = (payload, onSuccess, onError) => ({
    type: REQUEST_DIRECTORY_GROUPS,
    payload,
    onSuccess,
    onError
})

export const setDirectoryGroups: ISetDirectoryGroups = (payload) => ({
    type: SET_DIRECTORY_GROUPS,
    payload
})
export const setDirectoryGroupsCount: ISetDirectoryGroupsCount = (payload) => ({
    type: SET_DIRECTORY_GROUPS_COUNT,
    payload
})
export const setDirectoryGroupsLoading: ISetDirectoryGroupsLoading = (payload) => ({
    type: SET_DIRECTORY_GROUPS_LOADING,
    payload
})


export const getDirectoryCategories: IGetDirectoryCategories = (payload, onSuccess, onError) => ({
    type: REQUEST_DIRECTORY_CATEGORIES,
    payload,
    onSuccess,
    onError
})

export const setDirectoryCategories: ISetDirectoryCategories = (payload) => ({
    type: SET_DIRECTORY_CATEGORIES,
    payload
})

export const setDirectoryCategoriesCount: ISetDirectoryCategoriesCount = (payload) => ({
    type: SET_DIRECTORY_CATEGORIES_COUNT,
    payload
})
export const setDirectoryCategoriesLoading: ISetDirectoryCategoriesLoading = (payload) => ({
    type: SET_DIRECTORY_CATEGORIES_LOADING,
    payload
})


export const getDirectoryTemplates: IGetDirectoryTemplates = (payload, onSuccess, onError) => ({
    type: REQUEST_DIRECTORY_TEMPLATES,
    payload,
    onSuccess,
    onError
})

export const setDirectoryTemplate: ISetDirectoryTemplates = (payload) => ({
    type: SET_DIRECTORY_TEMPLATES,
    payload
})
export const setDirectoryTemplateCount: ISetDirectoryTemplatesCount = (payload) => ({
    type: SET_DIRECTORY_TEMPLATES_COUNT,
    payload
})
export const setDirectoryTemplatesLoading: ISetDirectoryTemplateLoading = (payload) => ({
    type: SET_DIRECTORY_TEMPLATES_LOADING,
    payload
})

export const setCurrentPage: ISetCurrentPage = (payload) => ({
    type: SET_CURRENT_INITIAL_PAGE,
    payload
})
export const setIudFlag: ISetIudFlag = (payload) => ({
    type: SET_IUD_FLAG,
    payload
})

export const setChosenGroupId: ISetChosenGroupId = (payload) => ({
    type: SET_CHOSEN_GROUP_ID,
    payload
})

export const iudGroup: IIudGroup = (payload, onSuccess?: () => void) => ({
    type: IUD_GROUP,
    payload,
    onSuccess
})

export const getPriority: IGetPriority = (payload, onSuccess?: (list: TEnumItem[]) => void) => ({
    type: REQUEST_PRIORITY,
    payload,
    onSuccess
})
export const setPriority: ISetPriority = payload => ({
    type: SET_PRIORITY,
    payload
})
export const setPriorityLoading: ISetPriorityLoading = payload => ({
    type: SET_PRIORITY_LOADING,
    payload
})

export const iudCategory: IIudCategory = (payload, onSuccess?: () => void) => ({
    type: IUD_CATEGORY,
    payload,
    onSuccess
})

export const setAppOrTemplateFlag: ISetAppOrTemplateFlag = (payload) => ({
    type: SET_NEW_APPLICATION_OR_TEMPLATE_FLAG,
    payload
})

export const setApplicationId: ISetApplicationId = (payload) => ({
    type: SET_APPLICATION_ID,
    payload
})

export const setSubGroupParentId: ISetSubgroupParentId = (payload) => ({
    type: SET_SUBGROUP_PARENT_ID,
    payload
})

export const setTaskFilterDates: ISetDateTaskFilter = (payload) => ({
    type: SET_DATES_FOR_TASK_FILTER,
    payload
})

export const iudDirectoryRoles: IIudDirectoryRole = (payload, onSuccess) => ({
    type: IUD_DIRECTORY_ROLE,
    payload,
    onSuccess
})

export const getDirectoryRoles: IGetDirectoryRoles = (payload) => ({
    type: REQUEST_DIRECTORY_ROLES,
    payload
})

export const setDirectoryRoles: ISetDirectoryRoles = (payload) => ({
    type: SET_DIRECTORY_ROLES,
    payload
})

export const setDirectoryRolesLoading: ISetDirectoryRolesLoading = (payload) => ({
    type: SET_DIRECTORY_ROLES_LOADING,
    payload
})

export const setDirectoryRoleFullInfo: ISetDirectoryRoleFullInfo = (payload) => ({
    type: SET_DIRECTORY_ROLE_INFO,
    payload
})
//Формвью
export const getFormviews: IGetFormviews = (payload) => ({
    type: REQUEST_FORMVIEWS,
    payload
})

//Полномочия
export const setFormviews: ISetFormviews = (payload) => ({
    type: SET_FORMVIEWS,
    payload
})
export const setFormviewsLoading: ISetFormviewsLoading = (payload) => ({
    type: SET_FORMVIEWS_LOADING,
    payload
})

//Видимый функционал
export const setVisiblePages: ISetVisiblePages = (payload) => ({
    type: SET_VISIBLE_PAGES,
    payload
})

export const setVisiblePagesLoading: ISetVisiblePagesLoading = (payload) => ({
    type: SET_VISIBLE_PAGES_LOADING,
    payload
})
//Полный список ролей

export const getFullRolesList: IGetFullRolesList = (payload) => ({
    type: REQUEST_ROLES_LIST,
    payload
})

export const setFullRolesList: ISetFullRolesList = (payload) => ({
    type: SET_ROLES_LIST,
    payload
})

export const setFullRolesListLoading: ISetFullRolesListLoading = (payload) => ({
    type: SET_ROLES_LIST_LOADING,
    payload
})

export const setRoleGroup: ISetRoleGroup = (payload) => ({
    type: SET_ROLE_GROUP,
    payload
})

export const setGroupFormView: ISetGroupFormViews = (payload) => ({
    type: SET_GROUP_FORMVIEWS,
    payload
})

//Чистка подгрупп, при отмене чекера передачи полномочий
export const clearSubgroupsRoles: IClearSubgroupsRoles = (payload) => ({
    type: CLEAR_SUBGROUPS_ROLES,
    payload
})