import React, { createRef, useEffect, useRef, useState } from 'react';
import { StatusCard } from './StatusCard/StatusCard';
import { TagCard } from './TagCard/TagCard';
import { Additionaly, BinIcon, EditIcon, HeartIcon, LikeBackgroundIcon, Reload } from '../../../icons/icons';
import { IApplicationItem } from "../../../store/applications/types";
import { Link } from "react-router-dom";
import { ROUTE_PATH } from "../../../routers/paths.main";
import { formatIsoToDate } from "../../helpers/utils";
import { ApplicationsStore } from "../../../store/applications/Applications";
import { Fallback } from "../../helpers/Fallback/Fallback";
import { NoData } from "../../common/Nodata/NoData";
import { Fade, IconButton } from "@material-ui/core";
import { DeleteModal } from "../../helpers/Modals/DeleteModal";
import { openNotification } from "../../helpers/notification";
import { ButtonsItem } from "./ButtonsItem";
import { observer } from "mobx-react-lite";
import { useDispatch, useSelector } from "react-redux";
import { setApplicationId, setAppOrTemplateFlag } from "../../../redux/reducers/directory/actions/actions";
import { TRootState } from "../../../redux/store";
import { formviewAvailabilityFunc } from "../../helpers/fromviewHelpers/formviewAvailability";
import { ReloadModal } from '../../helpers/Modals/ReloadModal/ReloadModal';

const s = require('./style.module.scss');

interface ICardsComponentProps {
    title?: string
    description?: string
    item: IApplicationItem
    request?: () => void
    [key: string]: any
    flag?: string
}

export const Cards: React.FC<ICardsComponentProps> = observer(({
    title,
    description,
    executor,
    item,
    request,
    flag
}) => {

    const { appStatuses, appStatusesLoading, requestIudApplication, setFlag, requestApplications, requestRefreshTask } = ApplicationsStore

    const [showFunctional, setShowFunctional] = useState<boolean>(false)
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [reloadModal, setReloadModal] = useState<boolean>(false)
    const [liked, setLiked] = useState<boolean>(item.favorite)


    const ref = useRef<HTMLDivElement>(null)

    const dispatch = useDispatch()

    const myInfo = useSelector((state: TRootState) => state.authReducer.myInfo)
    let workers = ''

    useEffect(() => {
        item?.responsible?.map((i, idx) => {
            if ((item?.responsible?.length > 1) && (idx !== item?.responsible?.length - 1)) workers = workers + i.name + ', '
            else workers = workers + i.name
        })
    }, [item?.responsible])

    useEffect(() => {
        setLiked(item.favorite)
    }, [item])


    const likeHandler = () => {
        setLiked(!liked)
        requestIudApplication({
            flag: 'favorite',
            id: item.id
        }, () => {
            liked ?
                openNotification({
                    type: 'success',
                    message: 'Заявка убрана из избранного'
                }) : openNotification({
                    type: 'success',
                    message: 'Заявка добавлена в избранное'
                })
            request && request()
        })
    }

    const editHandler = () => {
        dispatch(setAppOrTemplateFlag('editApp'))
        dispatch(setApplicationId(item.id))
    }

    const deleteHandler = () => {
        requestIudApplication({
            flag: 'D',
            id: item.id
        }, () => {
            openNotification({
                type: 'success',
                message: 'Заявка удалена'
            })
            request && request()
            setDeleteModal(false)
        })
    }

    const reloadHandler = () => {
        
        requestRefreshTask({
            id: item.id
        }).then(() => {
            request && request()
            setReloadModal(false)
        })
    }


    return (

        <div className={s.main_wrap}>
            <div style={{
                display: `${flag !== 'archive' ? 'flex' : 'block'}`,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                position : 'relative',
                width: '100%',
                height: '30%'
            }} className={s.status_additionaly_wrap}>
                <Link style={{ width: '80%' }} to={`${ROUTE_PATH.applicationInfo}/${item.id}/${flag}`}
                    onClick={() => {
                        ApplicationsStore.setApplicationId(item.id)
                    }}
                >
                    <div className={s.status}>
                        <StatusCard text={`${formatIsoToDate(item?.dateCreate)}`}
                            color={`${item?.date?.color}`}
                            background={`${item?.date?.backgrund}`} />
                        {item?.category?.name && <StatusCard text={item?.category?.name}
                            color={item?.category?.color || '-'}
                            background={`${item?.category?.background_color}`} />}
                        <StatusCard text={item?.status?.name || '-'}
                            color={item?.status?.color || '-'}
                            background={`${item?.status?.background}`} />
                    </div>
                </Link>
                {(flag !== 'archive' && flag !== 'deleted') &&
                    <div className={s.functions}>
                        <IconButton
                            onClick={likeHandler}
                            className={liked ? s.likeIcon_active : s.likeIcon}
                        >
                            <LikeBackgroundIcon />
                        </IconButton>

                        {item?.actionButtons?.find(item => item.formview.mnemo === 'edit_task') &&
                            <Link to={ROUTE_PATH.newApplication}>
                                <IconButton
                                    onClick={editHandler}
                                    className={s.icon}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Link>}
                        {item?.actionButtons?.find(item => item.formview.mnemo === 'delete_task') &&
                            <IconButton
                                onClick={() => setDeleteModal(true)}
                                className={s.icon}
                            >
                                <BinIcon />
                            </IconButton>}
                        {deleteModal && <DeleteModal
                            open={deleteModal}
                            closeHandler={() => setDeleteModal(false)}
                            title={'заявки'}
                            request={deleteHandler}
                        />}
                        {((item?.buttons && item?.buttons?.length > 0 && item?.buttons?.find(i => i.formview.mnemo === 'take_task_in_work') !== undefined) ||
                            (item?.buttons?.find(i => i.formview.mnemo === 'delegate_task') !== undefined) ||
                            (item?.buttons?.find(i => i.formview.mnemo === 'finish_task') !== undefined)) &&
                            <IconButton
                                className={s.threePoints}
                                onClick={(event) => {
                                    setShowFunctional(!showFunctional)
                                    ref.current?.focus({
                                        preventScroll: true
                                    })
                                }}
                            >
                                <Additionaly />
                            </IconButton>}
                    </div>
                }

                {flag === "deleted" &&
                    <div className={s.functions}>

                        <IconButton
                            onClick={() => setReloadModal(true)}
                            className={s.icon}
                        >
                            <Reload />
                        </IconButton>
                        {reloadModal && <ReloadModal
                            open={reloadModal}
                            closeHandler={() => setReloadModal(false)}
                            title={item.id}
                            request={reloadHandler}
                        />}
                    </div>
                }






                {showFunctional && <Fade in timeout={100}>
                    <div
                        ref={ref}
                        className={s.status_changer}
                        onMouseLeave={() => {
                            setShowFunctional(false)
                        }}>
                        {appStatusesLoading
                            ? <Fallback />
                            : appStatuses && appStatuses.length > 0
                                ? item.buttons.map((action, idx) => <ButtonsItem
                                    name={action?.name}
                                    action={action}
                                    id={item.id} />)
                                : <NoData text={'Статусы'} />}
                    </div>
                </Fade>}
            </div>
            <Link to={`${ROUTE_PATH.applicationInfo}/${item.id}/${flag}`}
                onClick={() => {
                    ApplicationsStore.setApplicationId(item.id)
                }}
            >
                <div>
                    <div className={s.title_card}>
                        {item?.name || '-'}
                    </div>
                    <div className={s.description}>
                        {item?.description || '-'}
                    </div>

                    <div className={s.executor_wrap}>
                        <div className={s.executor_wrap_title}>
                            Номер заявки:
                        </div>
                        <div className={s.executor} title={workers}>
                            {
                                item?.number ? item.number : '-'
                            }
                        </div>
                    </div>

                    <div className={s.executor_wrap}>
                        <div className={s.executor_wrap_title}>
                            Исполнитель:
                        </div>
                        <div className={s.executor} title={workers}>
                            {item?.responsible?.map((i, idx) => {
                                if ((item?.responsible?.length > 1) && (idx !== item?.responsible?.length - 1)) return i.name + ', '
                                else return i.name
                            }) || '-'}
                        </div>
                    </div>

                    <div className={s.executor_wrap}>
                        <div className={s.executor_wrap_title}>
                            ФИО клиента:
                        </div>
                        <div className={s.executor} title={workers}>
                            {
                                item?.client?.name ? item?.client?.name : '-'
                            }
                        </div>
                    </div>

                    <div className={s.executor_wrap}>
                        <div className={s.executor_wrap_title}>
                            Телефон клиента:
                        </div>
                        <div className={s.executor} title={workers}>
                            {
                                item?.client?.phone?.value ? item?.client?.phone?.value : '-'
                            }
                        </div>
                    </div>

                    <div className={s.executor_wrap}>
                        <div className={s.executor_wrap_title}>
                            Адрес:
                        </div>
                        <div className={s.executor} title={workers}>
                            {
                                item?.device?.address ? item?.device?.address : '-'
                            }
                        </div>
                    </div>

                    <div className={s.executor_wrap}>
                        <div className={s.executor_wrap_title}>
                            Имя объекта:
                        </div>
                        <div className={s.executor} title={workers}>
                            {
                                item?.nameObject ? item?.nameObject : '-'
                            }
                        </div>
                    </div>


                    <div className={s.tags_card}>
                        {item?.tags?.map(tag => <TagCard
                            key={tag.id}
                            title={tag.name}
                            color={tag.color}
                        />)}
                    </div>
                </div>
            </Link>

        </div>
    )
})

