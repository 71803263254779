import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {openNotification} from "../../helpers/notification";
import {ChangeApplicationStatusModal} from "../../helpers/Modals/ChangeApplicationStatusModal/ChangeApplicationStatusModal";
import {TEnumItem} from "../../helpers/utils";
import {IButtonsActions} from "../../../store/applications/types";
import {ApplicationsStore} from "../../../store/applications/Applications";
import {DelegationModal} from "../../helpers/Modals/DelegationModal/DelegationModal";

const s = require('./style.module.scss')

interface IButtonsItemProps {
    action: IButtonsActions
    name: string
    id: number
}

export const ButtonsItem: React.FC<IButtonsItemProps> = observer(({
                                                                      action,
                                                                      id,
                                                                      name
                                                                  }) => {

    const {requestIudApplication, requestApplications} = ApplicationsStore

    const [showStatuses, setShowStatuses] = useState<boolean>(false)
    const [chooseUsersMode, setChooseUsersMode] = useState<boolean>(false)


    return <div className={s.item}>
        <div onClick={() => {
            switch (name) {
                case 'Завершить' :
                    setShowStatuses(true)
                    return;
                case 'Делегировать' :
                    setChooseUsersMode(true)
                    return;
                default :
                    requestIudApplication({
                        flag: 'status',
                        id: id,
                        status: {
                            id: +action.actions[0].id
                        }
                    }, () => {
                        openNotification({
                            type: 'success',
                            message: 'Статус заявки успешно изменен'
                        })
                        requestApplications({})

                    })
                    return
            }
        }}>
            {action.name}
        </div>
        <ChangeApplicationStatusModal
            closeHandler={() => setShowStatuses(false)}
            open={showStatuses}
            title={'Завершение заявки'}
            appId={id}
            statuses={action.actions}
        />
        <DelegationModal
            title={'Делегировать заявку'}
            open={chooseUsersMode}
            appId={id}
            closeHandler={() => setChooseUsersMode(false)}
        />
    </div>
})