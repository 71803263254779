import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Lines } from '../../icons/icons';
import { observer } from "mobx-react-lite";
import { FilterButtons } from "../helpers/utils";
import { FilterButtonsItem } from "./FilterButtonsItem/FilterButtonsItem";
import { SidebarStore } from "../../store/sidebar/sidebarMobx";

const s = require('./style.module.scss');

interface ITopSidebarButtonComponentProps {
    titleId?: string
    totalCount?: number
    paddingLeft?: number
    flag: 'employees' | 'myApplications' | 'history'
    selectedWorkers: { workerId: number }[]
    requestHandler?: (value?: string) => void

    request?: (value?: string) => void

    [key: string]: any
}

export const TopSidebarButton: React.FC<ITopSidebarButtonComponentProps> = observer(({
    titleId,
    totalCount,
    flag,
    selectedWorkers,
    paddingLeft,
    requestHandler,
    request
}) => {


    return (
        <div className={s.wrapper} style={paddingLeft ? {
            paddingLeft: paddingLeft
        } : {}}>
            {FilterButtons.filter(item => (item.page === flag) || (item.page === 'icon')).map((i, idx) => {
                return <div>
                    <FilterButtonsItem
                        request={request}
                        requestHandler={requestHandler}
                        selectedWorkers={selectedWorkers}
                        flag={flag}
                        item={i}
                    />
                </div>
            })}
        </div>
    )
})

