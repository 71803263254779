import React from "react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";

const s = require('./ModalHeader.module.scss')

interface IModalHeader {
    title: string | number
    closeHandler: any

    [key: string]: any
}

export const ModalHeader: React.FC<IModalHeader> = ({
                                                        title,
                                                        closeHandler,
                                                        ...props
                                                    }) => {
    return (
        <>
            <div className={clsx(s.rootHeader, props?.mobile && s.rootHeader__mobile)}>
                <div className={clsx(s.headerTitle, props?.mobile && s.headerTitle__mobile)}>{title}</div>
                <IconButton onClick={closeHandler}
                            style={{padding: 2}}
                            className={s.headerCloseIcon}>
                    <CloseIcon/>
                </IconButton>
            </div>
            <div className={s.divider}></div>
        </>
    )
}
