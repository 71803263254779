import { ListItem } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React from 'react'
import { DirectoryStore } from '../../../store/directory/DirectoryStore'
import { Fallback } from '../../helpers/Fallback/Fallback'
import { MyPagination } from '../../helpers/MyPagination/MyPagination'
import { formatIsoToDate } from '../../helpers/utils'
import { DatePickerComponent, RangeDatePicker } from '../../NewApplication/DatePicker/DatePickerComponent'
import { CelebrationDaysItem } from './CelebrationDaysItem'
const s = require('./style.module.scss')


interface CelebrationDaysProps {

}

export const CelebrationDays: React.FC<CelebrationDaysProps> = observer(({ }) => {

    const { requestCelebrationDays, celebrationDaysList, celebrationDaysLoading } = DirectoryStore

    const [dateFrom, setDateFrom] = React.useState<string | undefined>(undefined)

    const [dateTo, setDateTo] = React.useState<string | undefined>(undefined)

    const [currentPage, setCurrentPage] = React.useState<number>(1)

    const PAGE_SIZE: number = 50

    const isMobile: boolean = window.outerWidth <= 425

    const searchHandler = () => {
        requestCelebrationDays({
            offset: (currentPage - 1) * PAGE_SIZE,
            count: PAGE_SIZE,
            dateFrom: dateFrom ? formatIsoToDate(dateFrom) : undefined,
            dateTo: dateTo ? formatIsoToDate(dateTo) : undefined,
        })
    }


    React.useEffect(() => {
        searchHandler()
    }, [currentPage])

    return <div className={s.root}>
        <div className={s.filters}>
            <RangeDatePicker
                width={isMobile ? '100%' : 'unset'}
                onChange={(value: any) => {

                    if (value?.length === 2) {
                        setDateFrom(moment(value[0]).format())
                        setDateTo(moment(value[1]).format())
                    } else {
                        setDateFrom(undefined)
                        setDateTo(undefined)
                    }
                }}
                value={undefined}
            />
            <ListItem
                button
                style={{
                    color: 'white',
                    background: 'black',
                    width: 90,
                    height: 37,
                    borderRadius: 4,
                    display: 'flex',
                    justifyContent: 'center',
                    marginLeft: '8px'
                }}
                onClick={searchHandler}
            >Искать</ListItem>
        </div>
        {celebrationDaysLoading
            ? <Fallback />
            : celebrationDaysList?.list && celebrationDaysList?.list?.length > 0
            && celebrationDaysList?.list?.map((i, idx) => <CelebrationDaysItem searchHandler={searchHandler} item={i} key={idx} />)}
        <div className={s.pagination}>
            <MyPagination
                onChange={setCurrentPage}
                currentPage={currentPage}
                pageSize={PAGE_SIZE}
                total={celebrationDaysList?.count || 0}
            />
        </div>
    </div>
})