import React from "react";
import {makeAutoObservable, runInAction} from "mobx";
import {IApplicationList, ISidebarItem, ISidebarLoadings} from "./types.data";
import {ROUTE_PATH} from "../../routers/paths.main";
import {MyApplication, Calendar, Favorites, Workers, History} from '../../icons/icons';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import {instance} from "../api";
import {IApplicationsCounts, TGetApplicationList} from "../api/bodies/types";
import {checkError, isError} from "../api/helpers";

class SidebarMobx {

    pages: ISidebarItem[] = [
        {id: '1', current: true, url: ROUTE_PATH.myBids.main, title: 'меню'},
        {
            id: '2',
            current: false,
            url: ROUTE_PATH.myApplications.main,
            title: 'Мои заявки',
            icon: <MyApplication/>,
            items: [
                {id: '3', current: false, url: ROUTE_PATH.myApplications.new, title: 'Новые (1)'},
                {id: '4', current: false, url: ROUTE_PATH.myApplications.overdue, title: 'Просроченные (0)'},
                {id: '5', current: false, url: ROUTE_PATH.myApplications.inProcess, title: 'В работе (1)'},
                {id: '6', current: false, url: ROUTE_PATH.myApplications.assign, title: 'Назначенные (1)'},
                {id: '7', current: false, url: ROUTE_PATH.myApplications.observed, title: 'Наблюдаемые (0)'},
                {id: '8', current: false, url: ROUTE_PATH.myApplications.pending, title: 'Ждёт ответа (0)'},
            ]
        },


        {id: '9', current: false, url: ROUTE_PATH.favourite, title: 'Избранное', icon: <Favorites/>},
        {id: '10', current: false, url: ROUTE_PATH.calendar, title: 'Календарь', icon: <Calendar/>},
        {id: '11', current: false, url: ROUTE_PATH.workers, title: 'Сотрудники', icon: <Workers/>},
        {id: '12', current: false, url: ROUTE_PATH.history, title: 'История', icon: <History/>},

        {
            id: '13',
            current: false,
            url: ROUTE_PATH.myApplications.main, title: 'Администрирование'
        },
        /*{id: '14', current: false, url: ROUTE_PATH.myApplications, title: 'Уведомление пользователей'},
        {id: '15', current: false, url: ROUTE_PATH.myApplications, title: 'Статистика'},
        {id: '15', current: false, url: ROUTE_PATH.myApplications, title: 'Раздел меню'},*/


        {id: '16', current: false, url: ROUTE_PATH.newApplication, title: 'Новая заявка'},
        {id: '17', current: false, url: ROUTE_PATH.authorization, title: 'Авторизация'},
        {id: '18', current: false, url: ROUTE_PATH.applicationInfo, title: 'Важная заявка'},
        // icon: <MyIcon/>
    ]

    applicationList: IApplicationList = {
        count: 1515,
        list: []
    }
    loadings: ISidebarLoadings = {
        applicationListLoading: false
    }

    applicationsCounts: IApplicationsCounts | null = null
    applicationsCountsLoading: boolean = false

    showSidebar: boolean = true

    constructor() {
        makeAutoObservable(this)
    }


    setCurrentPage = (newPage: ISidebarItem) => {
        runInAction(() => {
            this.pages = this.pages.map(page => ({
                ...page,
                current: page.id === newPage.id
            }))
        })
    }

    setShowSidebar(bol: boolean) {
        this.showSidebar = bol
    }

    requestApplicationsCounts = async () => {
        this.applicationsCountsLoading = true
        try {
            const res = await instance().close().getApplicationsCounts()
            this.applicationsCounts = res.data
        } catch (e) {
            // isError(e)
            checkError(e)
            
        }
        this.applicationsCountsLoading = false
    }

    requestApplicationList = async (data: TGetApplicationList, onSuccess?: () => void, onError?: () => void) => {
        runInAction(() => this.loadings.applicationListLoading = true)
        try {
            const res = await instance().close().getApplicationList(data)
            this.applicationList = res.data
            onSuccess && onSuccess()
        } catch (e) {
            // isError(e)
            checkError(e)
            onError && onError()
        }
        this.loadings.applicationListLoading = false
    }
}

export const SidebarStore = new SidebarMobx()