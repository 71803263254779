import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { SimpleCheckbox } from "../../common/CheckBox/CheckBox";
import { IApplicationItem } from "../../../store/applications/types";
import { formatIsoToDate } from "../../helpers/utils";

const s = require('./HistoryListItem.module.scss')

interface IHistoryListItemProps {
    item: IApplicationItem
}

export const HistoryListItem: React.FC<IHistoryListItemProps> = observer(({
    item
}) => {

    const [checked, setChecked] = useState<boolean>(false)

    return <div className={s.content + ' ' + (checked && s.checked)}
        onClick={() => {
            setChecked(!checked)
        }}
    >
        <div className={s.rowItem} style={{ width: '5%' }}>
            {item?.number}
        </div>
        <div className={s.rowItem} style={{ width: '10%' }}>
            {formatIsoToDate(item?.dateEdited) + ' ' + item?.dateEdited[11] + item?.dateEdited[12] + item?.dateEdited[13] + item?.dateEdited[14] + item?.dateEdited[15]}
        </div>
        <div className={s.rowItem} style={{ width: '45%' }}>
            {item?.responsible?.map((i, index) =>
                <span>{i.name}{index !== item.responsible.length - 1 && ','} </span>)}
        </div>
        <div className={s.rowItem} style={{ width: '15%' }}>
            {item?.client?.name}
        </div>
        <div className={s.rowItem} style={{ width: '10%' }}>
            {item?.status?.name}
        </div>
        <div className={s.rowItem} style={{ width: '15%' }}>
            <a href={`tel:${item?.client?.phone?.value}`}>{item?.client?.phone?.value}</a>
        </div>
    </div>
})