import {IInitialPage} from "../../../store/extraPage/types";
import {IActionsList, ICheckedItem, IHistoryList, TGetHistoryList, TGetTaskActions} from "./types.data";

export type TApplicationsReducer = {
    activeCount: number
    initialPages: IInitialPage[]
    checkers: ICheckedItem[]
    currentShowTaskMode: 'table' | 'cards'
    taskHistory: IHistoryList | null
    taskHistoryLoading: boolean
    taskActions: IActionsList | null
    taskActionsLoading: boolean
}

export const SET_ACTIVE_COUNT = 'SET_ACTIVE_COUNT'
export type SET_ACTIVE_COUNT_ACTION = {
    type: typeof SET_ACTIVE_COUNT,
    payload: number
}

export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
export type SET_CURRENT_PAGE_ACTION = {
    type: typeof SET_CURRENT_PAGE,
    payload: number
}

export const SET_CHECKED = 'SET_CHECKED'
export type SET_CHECKED_ACTION = {
    type: typeof SET_CHECKED,
    payload: ICheckedItem
}

export const CLEAR_CHECKED = 'CLEAR_CHECKED'
export type CLEAR_CHECKED_ACTION = {
    type: typeof CLEAR_CHECKED,
    payload: []
}


export const FILTER_CHECKED = 'FILTER_CHECKED'
export type FILTER_CHECKED_ACTION = {
    type: typeof FILTER_CHECKED,
    payload: ICheckedItem
}

export const SET_CURRENT_SHOW_TASKS_MODE = 'SET_CURRENT_SHOW_TASKS_MODE'
export type SET_CURRENT_SHOW_TASKS_MODE_ACTION = {
    type: typeof SET_CURRENT_SHOW_TASKS_MODE,
    payload: 'table' | 'cards'
}

//История заявки


export const REQUEST_TASK_HISTORY = 'REQUEST_TASK_HISTORY'
export type REQUEST_TASK_HISTORY_ACTION = {
    type: typeof REQUEST_TASK_HISTORY,
    payload: TGetHistoryList
}

export const SET_TASK_HISTORY = 'SET_TASK_HISTORY'
export type SET_TASK_HISTORY_ACTION = {
    type: typeof SET_TASK_HISTORY,
    payload: IHistoryList
}


export const SET_TASK_HISTORY_LOADING = 'SET_TASK_HISTORY_LOADING'
export type SET_TASK_HISTORY_LOADING_ACTION = {
    type: typeof SET_TASK_HISTORY_LOADING,
    payload: boolean
}

//Событие заявки
export const REQUEST_TASK_ACTIONS = 'REQUEST_TASK_ACTIONS'
export type REQUEST_TASK_ACTIONS_ACTION = {
    type: typeof REQUEST_TASK_ACTIONS,
    payload: TGetTaskActions
}

export const SET_TASK_ACTIONS = 'SET_TASK_ACTIONS'
export type SET_TASK_ACTIONS_ACTION = {
    type: typeof SET_TASK_ACTIONS,
    payload: IActionsList
}

export const SET_TASK_ACTIONS_LOADING = 'SET_TASK_ACTIONS_LOADING'
export type SET_TASK_ACTIONS_LOADING_ACTION = {
    type: typeof SET_TASK_ACTIONS_LOADING,
    payload: boolean
}


export type APPLICATIONS_ACTIONS =
    | SET_ACTIVE_COUNT_ACTION
    | SET_CURRENT_PAGE_ACTION
    | SET_CHECKED_ACTION
    | CLEAR_CHECKED_ACTION
    | FILTER_CHECKED_ACTION
    | SET_CURRENT_SHOW_TASKS_MODE_ACTION
    | SET_TASK_HISTORY_ACTION
    | SET_TASK_HISTORY_LOADING_ACTION
    | SET_TASK_ACTIONS_ACTION
    | SET_TASK_ACTIONS_LOADING_ACTION