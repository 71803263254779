import React, { useEffect } from 'react';
import clsx from 'clsx';
import { TEnumItem } from "../../helpers/utils";
import { log } from "util";

const s = require('./style.module.scss');

interface IApplicationInfoItemComponentProps {
    title?: string,
    data: string | TEnumItem[] | any,
    colorData?: string

    [key: string]: any
}

export const ApplicationInfoItem: React.FC<IApplicationInfoItemComponentProps> = ({
    title,
    data,
    colorData,
}) => {


    return (
        <div className={s.main_page_wrap}>
            <div className={s.title_wrap}>{title}</div>
            {!Array.isArray(data) ? title === 'Телефон' ? <a href={`tel:${data}`}>{data}</a> :
                <div className={s.data_wrap} style={{ color: `${colorData}` }}>{data}</div>
                : data?.map((i, idx) => <div className={s.data_wrap}
                    style={{ color: colorData && `${colorData[idx]}` }}>
                    {
                        i?.url ?
                            <a href={i.url}>
                                {i.name}
                            </a>
                            : i.name
                    }</div>)}
        </div>
    );
};

