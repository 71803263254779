import {put, takeEvery} from "redux-saga/effects";

import {
    IUD_CATEGORY,
    IUD_CATEGORY_ACTION, IUD_DIRECTORY_ROLE, IUD_DIRECTORY_ROLE_ACTION,
    IUD_GROUP,
    IUD_GROUP_ACTION,
    REQUEST_DIRECTORY_CATEGORIES,
    REQUEST_DIRECTORY_GROUPS,
    REQUEST_DIRECTORY_GROUPS_ACTION,
    REQUEST_DIRECTORY_ROLES,
    REQUEST_DIRECTORY_ROLES_ACTION,
    REQUEST_DIRECTORY_TEMPLATES,
    REQUEST_DIRECTORY_TEMPLATES_ACTION, REQUEST_FORMVIEWS, REQUEST_FORMVIEWS_ACTION,
    REQUEST_PRIORITY,
    REQUEST_PRIORITY_ACTION, REQUEST_ROLES_LIST, REQUEST_ROLES_LIST_ACTION
} from "../types";
import {instance} from "../../../../store/api";
import {
    getDirectoryCategories,
    setDirectoryCategories, setDirectoryCategoriesCount,
    setDirectoryCategoriesLoading,
    setDirectoryGroups, setDirectoryGroupsCount,
    setDirectoryGroupsLoading, setDirectoryRoles,
    setDirectoryRolesLoading,
    setDirectoryTemplate, setDirectoryTemplateCount,
    setDirectoryTemplatesLoading, setFormviews, setFormviewsLoading, setFullRolesList, setFullRolesListLoading,
    setPriority,
    setPriorityLoading, setVisiblePages, setVisiblePagesLoading
} from "./actions";
import {ResponseGenerator} from "../../types";
import {checkError, isError} from "../../../../store/api/helpers";
import {TPaginationReq} from "../../../types";
import {TGetApplicationList} from "../../../../store/api/bodies/types";
import {TIudGroup} from "../../../../store/WorkersPage/types";
import {TIudCategory, TIudRole} from "../types.data";

export function* directoryWatcher() {
    yield takeEvery(REQUEST_DIRECTORY_GROUPS, getDirectoryGroupsWorker)
    yield takeEvery(REQUEST_DIRECTORY_CATEGORIES, getDirectoryCategoriesWorker)
    yield takeEvery(REQUEST_DIRECTORY_TEMPLATES, getDirectorTemplatesWorker)
    yield takeEvery(IUD_GROUP, iudGroupWorker)
    yield takeEvery(REQUEST_PRIORITY, getPriorityWorker)
    yield takeEvery(IUD_CATEGORY, IudCategoryWorker)
    yield takeEvery(REQUEST_DIRECTORY_ROLES, getDirectoryRolesWorker)
    yield takeEvery(REQUEST_FORMVIEWS, getFormviewsWorker)
    yield takeEvery(IUD_DIRECTORY_ROLE, iudDirectoryRolesWorker)
    yield takeEvery(REQUEST_ROLES_LIST, getFullRolesListWorker)
}

function* getDirectoryGroupsWorker(action: REQUEST_DIRECTORY_GROUPS_ACTION) {
    yield put(setDirectoryGroupsLoading(true))
    try {
        const res: ResponseGenerator = yield getDirectoryGroupsAsync(action.payload).next().value
        yield put(setDirectoryGroups(res.data?.value || []))
        yield put(setDirectoryGroupsCount(res.data?.count || 0))
        action.onSuccess && action.onSuccess()
    } catch (e) {
        checkError(e)
        isError(e)
    }
    yield put(setDirectoryGroupsLoading(false))
}

function* getDirectoryGroupsAsync(body: TPaginationReq) {
    return instance().close().getDirectoryGroups(body)
}

function* getDirectoryCategoriesWorker(action: REQUEST_DIRECTORY_GROUPS_ACTION) {
    yield put(setDirectoryCategoriesLoading(true))
    try {
        const res: ResponseGenerator = yield getDirectoryCategoriesAsync(action.payload).next().value
        yield put(setDirectoryCategories(res.data?.data?.value || []))
        yield put(setDirectoryCategoriesCount(res.data?.data?.count || 0))
        action.onSuccess && action.onSuccess()
    } catch (e) {
        isError(e)
        checkError(e) 
    }
    yield put(setDirectoryCategoriesLoading(false))
}

function* getDirectoryCategoriesAsync(body: TPaginationReq) {
    return instance().close().getDirectoryCategories(body)
}

function* getDirectorTemplatesWorker(action: REQUEST_DIRECTORY_TEMPLATES_ACTION) {
    yield put(setDirectoryTemplatesLoading(true))
    try {
        const res: ResponseGenerator = yield getDirectoryTemplatesAsync(action.payload).next().value
        yield put(setDirectoryTemplate(res.data?.data?.value || []))
        yield put(setDirectoryTemplateCount(res.data?.data?.count || 0))
        action.onSuccess && action.onSuccess()
    } catch (e) {
        isError(e)
        checkError(e)
        
    }
    yield put(setDirectoryTemplatesLoading(false))
}

function* getDirectoryTemplatesAsync(body: TGetApplicationList) {
    return instance().close().getDirectoryTemplates(body)
}


function* iudGroupWorker(action: IUD_GROUP_ACTION) {
    try {
        const res: ResponseGenerator = yield iudGroupAsync(action.payload).next().value
        action.onSuccess && action.onSuccess()
    } catch (e) {
        checkError(e)
        isError(e)
    }
}

function* iudGroupAsync(body: TIudGroup) {
    return instance().close().iudGroup(body)
}

function* getPriorityWorker(action: REQUEST_PRIORITY_ACTION) {
    yield put(setPriorityLoading(true))
    try {
        const res: ResponseGenerator = yield getPriorityAsync(action.payload).next().value
        yield put(setPriority(res.data?.data?.value.map((i: any) => {
            return {
                id: i.id,
                name: i.name
            }
        })))
        action.onSuccess && action.onSuccess(res.data?.data?.value.map((i: any) => {
            return {
                id: i.id,
                name: i.name
            }
        }))
    } catch (e) {
        checkError(e)
        isError(e)
    }
    yield put(setPriorityLoading(false))
}

function* getPriorityAsync(body: TPaginationReq) {
    return instance().close().getPriority(body)
}


function* IudCategoryWorker(action: IUD_CATEGORY_ACTION) {
    try {
        const res: ResponseGenerator = yield iudCategoryAsync(action.payload).next().value
        action.onSuccess && action.onSuccess()
    } catch (e) {
        checkError(e)
        isError(e)
    }
}

function* iudCategoryAsync(body: TIudCategory) {
    return instance().close().iudCategory(body)
}

function* getDirectoryRolesWorker(action: REQUEST_DIRECTORY_ROLES_ACTION) {
    yield put(setDirectoryRolesLoading(true))
    try {
        const res: ResponseGenerator = yield getDirectoryRolesAsync(action.payload).next().value
        yield put(setDirectoryRoles({
            count: res.data?.data?.count || 15,
            roles: res.data?.data?.value || []
        }))
    } catch (e) {
        checkError(e)
        isError(e)
    }
    yield put(setDirectoryRolesLoading(false))
}

function* getDirectoryRolesAsync(body: {}) {
    return instance().close().getDirectoryRoles(body)
}

function* iudDirectoryRolesWorker(action: IUD_DIRECTORY_ROLE_ACTION) {
    yield put(setDirectoryRolesLoading(true))
    try {
        const res: ResponseGenerator = yield iudDirectoryRolesAsync(action.payload).next().value
        action.onSuccess && action.onSuccess()
    } catch (e) {
        checkError(e)
        isError(e)
    }
    yield put(setDirectoryRolesLoading(false))
}

function* iudDirectoryRolesAsync(body: TIudRole) {
    return instance().close().iudDirectoryRole(body)
}

function* getFormviewsWorker(action: REQUEST_FORMVIEWS_ACTION) {
    if (action.payload.typeId === 2) {
        yield put(setVisiblePagesLoading(true))
    } else yield put(setFormviewsLoading(true))
    try {
        const res: ResponseGenerator = yield getFormviewsAsync(action.payload).next().value

        if (action.payload.typeId === 2) {
            yield put(setVisiblePages(res.data?.data?.value))
        } else yield put(setFormviews(res.data?.data?.value))
    } catch (e) {
        checkError(e)
        isError(e)
    }
    if (action.payload.typeId === 2) {
        yield put(setVisiblePagesLoading(false))
    } else yield put(setFormviewsLoading(false))
}

function* getFormviewsAsync(body: {}) {
    return instance().close().getFormview(body)
}

function* getFullRolesListWorker(action: REQUEST_ROLES_LIST_ACTION) {
    yield put(setFullRolesListLoading(true))
    try {
        const res: ResponseGenerator = yield getFullRolesListAsync(action.payload).next().value
        yield put(setFullRolesList(res.data?.data?.value || []))
    } catch (e) {
        checkError(e)
        isError(e)
    }
    yield put(setFullRolesListLoading(false))
}

function* getFullRolesListAsync(body: {}) {
    return instance().close().getFullRolesList(body)
}

