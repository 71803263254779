import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { AuthorizationLogo, EyeIcon } from '../../icons/icons';
import { LoginPageStore } from "../../store/loginPage/LoginPage";
import { openNotification } from "../helpers/notification";
import { SidebarStore } from "../../store/sidebar/sidebarMobx";
import { observer } from "mobx-react-lite";
import { Fallback } from "../helpers/Fallback/Fallback";

const s = require('./style.module.scss');

interface IAuthorizationComponentProps {
    [key: string]: any
}

export const Authorization: React.FC<IAuthorizationComponentProps> = observer(({ }) => {

    const [login, setLogin] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [showPassword, setShowPassword] = useState<boolean>(false)

    const [showError, setShowError] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        SidebarStore.setShowSidebar(false)
    }, [])

    const { requestAuthorization } = LoginPageStore

    const loginHandler = () => {
        requestAuthorization({
            username: login,
            password: password
        }, () => {
            openNotification({
                type: 'success',
                message: 'Успешный вход в приложение'
            })
            setShowError(false)

            setLoading(true)

            window.location.href = '/applications'
            setTimeout(() => {
                setLoading(false)

            }, 2000)
        }, () => {
            setShowError(true)
        })
    }

    return (
        <div className={s.main_page_wrap}>
            {loading
                ? <Fallback />
                : <div className={s.main_content_wrap}>
                    <AuthorizationLogo />
                    <div className={s.login_wrap}>
                        <div className={s.title_wrap}>
                            Авторизация
                        </div>
                        {showError && <div className={s.error_wrap}>
                            <div className={s.title_error}>
                                Неправильный логин или пароль
                            </div>
                            <div className={s.description_error}>
                                Попробуйте еще раз
                            </div>
                        </div>}
                        <div className={s.input_wrap}>
                            <div className={s.title_input}>
                                Логин
                            </div>
                            <input
                                className={s.input}
                                onChange={(e) => {
                                    setLogin(e.currentTarget.value)
                                    localStorage.setItem('login', e.currentTarget.value)
                                }}
                            />
                            <div className={s.title_input}>
                                Пароль
                            </div>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                className={s.input}
                                onChange={(e) => {
                                    localStorage.setItem('password', e.currentTarget.value)
                                    setPassword(e.currentTarget.value)
                                }}
                            />
                            <EyeIcon
                                onClick={() => setShowPassword(!showPassword)}
                                className={s.eyeIcon} />
                        </div>
                        <button
                            className={s.btn_wrap}
                            onClick={loginHandler}
                        >
                            Войти
                        </button>
                    </div>
                </div>
            }
        </div>
    );
})

