export const PlusIcon = (props) => {
  const color = props?.color || "white";
  const width = props?.width || 14;
  const height = props?.height || 14;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.00078 14C6.81513 14 6.63714 13.9263 6.50586 13.795C6.37458 13.6637 6.30078 13.4857 6.30078 13.3V0.7C6.30078 0.514348 6.37458 0.336268 6.50586 0.204993C6.63714 0.0737172 6.81513 0 7.00078 0C7.18643 0 7.36451 0.0737172 7.49579 0.204993C7.62707 0.336268 7.70078 0.514348 7.70078 0.7V13.3C7.70078 13.4857 7.62707 13.6637 7.49579 13.795C7.36451 13.9263 7.18643 14 7.00078 14Z" />
      <path d="M7.00078 14C6.81513 14 6.63714 13.9263 6.50586 13.795C6.37458 13.6637 6.30078 13.4857 6.30078 13.3V0.7C6.30078 0.514348 6.37458 0.336268 6.50586 0.204993C6.63714 0.0737172 6.81513 0 7.00078 0C7.18643 0 7.36451 0.0737172 7.49579 0.204993C7.62707 0.336268 7.70078 0.514348 7.70078 0.7V13.3C7.70078 13.4857 7.62707 13.6637 7.49579 13.795C7.36451 13.9263 7.18643 14 7.00078 14Z" />
      <path d="M13.3 7.70005H0.7C0.514348 7.70005 0.336354 7.62633 0.205078 7.49506C0.0738027 7.36378 0 7.1857 0 7.00005C0 6.8144 0.0738027 6.63632 0.205078 6.50504C0.336354 6.37377 0.514348 6.30005 0.7 6.30005H13.3C13.4857 6.30005 13.6637 6.37377 13.795 6.50504C13.9263 6.63632 14 6.8144 14 7.00005C14 7.1857 13.9263 7.36378 13.795 7.49506C13.6637 7.62633 13.4857 7.70005 13.3 7.70005Z" />
      <path d="M13.3 7.70005H0.7C0.514348 7.70005 0.336354 7.62633 0.205078 7.49506C0.0738027 7.36378 0 7.1857 0 7.00005C0 6.8144 0.0738027 6.63632 0.205078 6.50504C0.336354 6.37377 0.514348 6.30005 0.7 6.30005H13.3C13.4857 6.30005 13.6637 6.37377 13.795 6.50504C13.9263 6.63632 14 6.8144 14 7.00005C14 7.1857 13.9263 7.36378 13.795 7.49506C13.6637 7.62633 13.4857 7.70005 13.3 7.70005Z" />
    </svg>
  );
};
