import React, {useState} from "react";
import {Link} from "react-router-dom";
import {ROUTE_PATH} from "../../../../routers/paths.main";
import {CrossIcon, PencilIcon} from "../../../../icons/icons";
import {
    iudCategory,
    iudDirectoryRoles,
    iudGroup,
    setSubGroupParentId
} from "../../../../redux/reducers/directory/actions/actions";
import {IDirectoryGroup, IDirectorySubGroup} from "../../../../redux/reducers/directory/types.data";
import {ISetChosenGroupId, ISetIudFlag} from "../../../../redux/reducers/directory/actions/types";
import {useDispatch} from "react-redux";
import {openNotification} from "../../../helpers/notification";
import {DeleteModal} from "../../../helpers/Modals/DeleteModal";

const s = require('./SubgroupsItem.module.scss')

interface ISubgroupItemProps {
    item: IDirectorySubGroup
    setIudFlag: ISetIudFlag
    setChosenGroupId?: ISetChosenGroupId
    group: IDirectoryGroup
    searchHandler: () => void
}

export const SubgroupsItem: React.FC<ISubgroupItemProps> = ({
                                                                item,
                                                                setIudFlag,
                                                                setChosenGroupId,
                                                                group,
                                                                searchHandler
                                                            }) => {

    const dispatch = useDispatch()

    const [deleteMode, setDeleteMode] = useState<boolean>(false)

    const deleteHandler = () => {
        dispatch(iudGroup({
            flag: 'D',
            id: item?.id
        }, () => {
            openNotification({
                type: 'success',
                message: 'Подгруппа удалена'
            })
            searchHandler()
        }))
    }

    return <div className={s.subrow}>
        <div>
            {item?.name}
        </div>
        <div>
            <Link
                to={ROUTE_PATH.extraPage.iudDirectoryItem}
                style={{
                    width: 14,
                    height: 16,
                    marginRight: 8
                }}
            >
                <PencilIcon style={{
                    width: 14,
                    height: 16,
                    cursor: 'pointer'
                }} onClick={() => {
                    setIudFlag('editGroup')
                    setChosenGroupId && setChosenGroupId(item?.id || 0)
                    dispatch(setSubGroupParentId(group.id))
                }}/>
            </Link>
            <CrossIcon onClick={() => {
                setDeleteMode(true)
            }} style={{
                marginLeft: 8,
                cursor: 'pointer'
            }}/>
            <DeleteModal
                open={deleteMode}
                title={'подгруппы'}
                closeHandler={() => setDeleteMode(false)}
                request={deleteHandler}
            />

        </div>
    </div>
}