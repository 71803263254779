export const CheckMarkIcon = (props) => {
  const color = props?.color;
  return (
    <svg
      style={props?.style}
      onClick={props?.onClick}
      width={props?.width || "8"}
      height={props?.height || "4"}
      viewBox="0 0 8 4"
      fill={color || "#ADB5BD"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.77405 0.177677L3.99642 2.53455L1.21879 0.177677C0.939597 -0.0592255 0.488591 -0.0592255 0.209396 0.177677C-0.0697987 0.414579 -0.0697987 0.797266 0.209396 1.03417L3.4953 3.82232C3.7745 4.05923 4.2255 4.05923 4.5047 3.82232L7.7906 1.03417C8.0698 0.797266 8.0698 0.414579 7.7906 0.177677C7.51141 -0.0531511 7.05324 -0.059833 6.77405 0.177677Z" />
    </svg>
  );
};
