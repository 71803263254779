import React, { useEffect, useState } from "react";
import { SimpleCheckbox } from "../../common/CheckBox/CheckBox";
import { IApplicationItem } from "../../../store/applications/types";
import { formatIsoToDate } from "../../helpers/utils";
import { useDispatch, useSelector } from "react-redux";
import { filterCheckers, setActiveCount, setCheckers } from "../../../redux/reducers/applications/actions/actions";
import { TRootState } from "../../../redux/store";
import { observer } from "mobx-react-lite";
import { ROUTE_PATH } from "../../../routers/paths.main";
import { ApplicationsStore } from "../../../store/applications/Applications";
import { Link, useHistory } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { BinIcon, EditIcon, LikeBackgroundIcon, Reload } from "../../../icons/icons";
import { setApplicationId, setAppOrTemplateFlag } from "../../../redux/reducers/directory/actions/actions";
import { openNotification } from "../../helpers/notification";
import { DeleteModal } from "../../helpers/Modals/DeleteModal";
import { ReloadModal } from "../../helpers/Modals/ReloadModal/ReloadModal";

const s = require('./style.module.scss')

interface ITasksTableItemProps {
    allChecked: boolean
    item: IApplicationItem
    setAllChecked: any
    request?: () => void
    flag: string
}

export const TasksTableItem: React.FC<ITasksTableItemProps> = observer(({
    allChecked,
    item,
    setAllChecked,
    request,
    flag
}) => {

    const router = useHistory()

    const [checked, setChecked] = useState<boolean>(false)
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [liked, setLiked] = useState<boolean>(item.favorite)
    const [reloadModal, setReloadModal] = useState<boolean>(false)

    const dispatch = useDispatch()
    const totalCount = useSelector((state: TRootState) => state.applicationsReducer.activeCount)
    const checkers = useSelector((state: TRootState) => state.applicationsReducer.checkers)

    const { requestIudApplication, requestRefreshTask } = ApplicationsStore


    const likeHandler = (e: any) => {
        e.preventDefault()
        e.stopPropagation()

        setLiked(!liked)
        requestIudApplication({
            flag: 'favorite',
            id: item.id
        }, () => {
            liked ?
                openNotification({
                    type: 'success',
                    message: 'Заявка убрана из избранного'
                }) : openNotification({
                    type: 'success',
                    message: 'Заявка добавлена в избранное'
                })
            request && request()
        })
    }

    const editHandler = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        dispatch(setAppOrTemplateFlag('editApp'))
        dispatch(setApplicationId(item.id))
        router.push(ROUTE_PATH.newApplication)
    }

    const deleteHandler = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        requestIudApplication({
            flag: 'D',
            id: item.id
        }, () => {
            openNotification({
                type: 'success',
                message: 'Заявка удалена'
            })
            request && request()
            setDeleteModal(false)
        })
    }

    const reloadHandler = () => {
        
        requestRefreshTask({
            id : item.id
        }).then(() => {
            request && request()
            setReloadModal(false)
        })
    }


    useEffect(() => {
        setLiked(item.favorite)
    }, [item])
    return <div
        className={s.row + ' ' + (checkers.find(i => i.id === item.id) !== undefined && s.checked) + ' ' + (allChecked && s.checked)}
        onClick={() => {
            ApplicationsStore.setApplicationId(item.id)
            router.push(`${ROUTE_PATH.applicationInfo}/${item.id}/${flag}`)
        }}
    >
        {/* <div className={s.check} onClick={(event) => {
                event.stopPropagation()
            }} style={{flex: '0 0 3%'}}>
                <SimpleCheckbox

                    value={allChecked || checkers.find(i => i.id === item.id) !== undefined}
                    onChange={() => {

                        if (allChecked) {
                            dispatch(setActiveCount(totalCount - 1))
                            setAllChecked(false)
                            dispatch(filterCheckers({
                                id: item.id
                            }))
                        } else {
                            if (checkers.find(i => i.id === item.id) !== undefined) {
                                dispatch(setActiveCount(totalCount - 1))
                                dispatch(filterCheckers({
                                    id: item.id
                                }))
                            } else {
                                dispatch(setActiveCount(totalCount + 1))
                                dispatch(setCheckers({
                                    id: item.id
                                }))
                            }
                        }


                    }}
                />
            </div> */}
        <div className={s.item} style={{ flex: '0 0 35%' }}>
            {item?.name || '-'}
        </div>
        <div className={s.item} style={{ flex: '0 0 10%' }}>
            {item?.number ? '№' + ' ' + `${item?.number}` : '-'}
        </div>
        <div className={s.item} style={{
            flex: '0 0 12%',
            height: '100%',
            display: 'flex',
            alignItems: 'center'
        }}>
            <div style={{
                color: `${item.date.color}`,
                borderRadius: 15,
                padding: 4,
                background: `${item.date.backgrund}`,
            }}>
                {formatIsoToDate(item?.date?.value) + ' ' + item?.date?.value[11] + item?.date?.value[12] + item?.date?.value[13] + item?.date?.value[14] + item?.date?.value[15] || '-'}
            </div>
        </div>
        <div className={s.item} style={{ flex: '0 0 25%' }}>
            {item?.responsible && item?.responsible?.length > 0 && item?.responsible?.map((i, idx) => {
                if (idx !== item?.responsible?.length && item?.responsible?.length > 1) {
                    return i.name + ', '
                } else {
                    return i.name
                }
            }) || '-'}
        </div>
        <div className={s.item} style={{ flex: '0 0 10%' }}>
            {formatIsoToDate(item?.dateCreate) + ' ' + item?.dateCreate[11] + item?.dateCreate[12] + item?.dateCreate[13] + item?.dateCreate[14] + item?.dateCreate[15] || '-'}
        </div>
        <div className={s.item} style={{ flex: '0 0 8%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>

            {
                (flag !== 'archive' && flag !== 'deleted') && (
                    <>
                        <IconButton
                            onClick={likeHandler}
                            className={liked ? s.likeIcon_active : s.likeIcon}
                        >
                            <LikeBackgroundIcon />
                        </IconButton>

                        {item?.actionButtons?.find(item => item.formview.mnemo === 'edit_task') &&
                            <Link to={ROUTE_PATH.newApplication}>
                                <IconButton
                                    onClick={editHandler}
                                    className={s.icon}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Link>}

                        {item?.actionButtons?.find(item => item.formview.mnemo === 'delete_task') &&
                            <IconButton
                                onClick={(e: any) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    setDeleteModal(true)
                                }}
                                className={s.icon}
                            >
                                <BinIcon />
                            </IconButton>}

                    </>
                )
            }

            {flag === "deleted" &&
                <div>

                    <IconButton
                        onClick={() => setReloadModal(true)}
                        className={s.icon}
                    >
                        <Reload />
                    </IconButton>
                    {reloadModal && <ReloadModal
                        open={reloadModal}
                        closeHandler={() => setReloadModal(false)}
                        title={item.id}
                        request={reloadHandler}
                    />}
                </div>
            }
        </div>
        {deleteModal && <DeleteModal
            open={deleteModal}
            closeHandler={(e: any) => {
                e.preventDefault()
                e.stopPropagation()
                setDeleteModal(false)
            }}
            title={'заявки'}
            request={deleteHandler}
        />}
    </div>
})