import { CelebrationDays } from './../../components/ExtraPage/CelebrationDays/CelebrationDays';
import { makeAutoObservable, runInAction } from "mobx";
import {
    ICelebrationDayItem,
    ICelebrationDaysList,
    IColorListItem,
    IDeviceListItem,
    IDevicesList,
    IIudDeviceItem, IIudMarkItem,
    IMarksList,
    IMarksListItem, ISystemFullInfo,
    TGetCelebrationDays,
    TGetDevicesList,
    TGetMarksList,
    TGetTCelebrationDayTypes,
    TIudCelebrationDay
} from "./types";
import { instance } from "../api";
import { checkError, isError } from "../api/helpers";
import { TEnumItem } from "../../components/helpers/utils";

class Directory {

    devicesList: IDevicesList | null = null
    devicesListLoading: boolean = false

    marksList: IMarksList | null = null
    marksListLoading: boolean = false

    searchValue: string = ''
    currentPageStore: number | null = null

    deviceItemFullInfo: IDeviceListItem | null = null
    systemItemFullInfo: ISystemFullInfo | null = null
    markItemFullInfo: IMarksListItem | null = null

    iudFlag: 'I' | 'U' = 'I'

    colors: TEnumItem[] = []
    colorsList: IColorListItem[] = []
    colorsLoading: boolean = false


    celebrationDaysList: ICelebrationDaysList | null = null
    celebrationDaysLoading: boolean = false
    celebrationDayInfo: ICelebrationDayItem | null = null

    celebrationDayTypes: TEnumItem[] = []
    celebrationDayTypesLoading: boolean = false
    constructor() {
        makeAutoObservable(this)
    }

    setIudFlag = (flag: 'I' | 'U') => {
        this.iudFlag = flag
    }

    setSystemFullInfo = (value: ISystemFullInfo | null) => {
        this.systemItemFullInfo = value
    }

    setDeviceItemFullInfo(value: IDeviceListItem) {
        this.deviceItemFullInfo = value
        
    }

    setMarkItemFullInfo(value: IMarksListItem) {
        this.markItemFullInfo = value
    }

    setSearchValue(searchValue: string) {
        this.searchValue = searchValue
    }

    setCurrentPageStore(page: number | null) {
        this.currentPageStore = page
    }

    setCelebrationDayInfo = (value: ICelebrationDayItem) => {
        this.celebrationDayInfo = value
    }

    //Список пультовых устройств
    requestDevicesList = async (data: TGetDevicesList) => {
        runInAction(() => this.devicesListLoading = true)
        try {
            const res = await instance().close().getDevicesList(data)
            this.devicesList = {
                count: res.data?.data?.count,
                list: res.data?.data?.value
            }
        } catch (e) {
            checkError(e)
            isError(e)
        }
        runInAction(() => this.devicesListLoading = false)
    }

    requestIudDevice = async (data: IIudDeviceItem, onSuccess?: () => void, onError?: () => void) => {
        try {
            const res = await instance().close().iudDevice(data)
            onSuccess && onSuccess()
        } catch (e) {
            checkError(e)
            isError(e)
            onError && onError()
        }
    }

    requestMarksList = async (data: TGetMarksList) => {
        runInAction(() => this.marksListLoading = true)
        try {
            const res = await instance().close().getMarksList(data)
            this.marksList = {
                count: res.data?.data?.count,
                list: res.data?.data?.value
            }
        } catch (e) {
            checkError(e)
            isError(e)
        }
        runInAction(() => this.marksListLoading = false)
    }

    requestIudMark = async (data: IIudMarkItem, onSuccess?: () => void, onError?: () => void) => {
        try {
            const res = await instance().close().iudMark(data)
            onSuccess && onSuccess()
        } catch (e) {
            checkError(e)
            isError(e)
            onError && onError()
        }
    }

    requestColors = async () => {
        runInAction(() => this.colorsLoading = true)
        try {
            const res = await instance().close().getColors()
            this.colors = res.data?.data?.value
            this.colorsList = res.data?.data?.value
        } catch (e) {
            checkError(e)
            isError(e)
        }
        runInAction(() => this.colorsLoading = false)
    }

    //праздничные дни
    requestCelebrationDays = async (data: TGetCelebrationDays) => {
        runInAction(() => this.celebrationDaysLoading = true)
        try {
            const res = await instance().close().getCelebrationDays(data)
            this.celebrationDaysList = {
                count: res.data?.data?.count || 0,
                list: res.data?.data?.value || []
            }
        } catch (e) {
            checkError(e)
            isError(e)
        }
        runInAction(() => this.celebrationDaysLoading = false)

    }

    requestIudCelebrationDay = async (data: TIudCelebrationDay, onSuccess?: () => void) => {
        try {
            const res = await instance().close().iudCelebrationDay(data)
            onSuccess && onSuccess()
        }
        catch (e) {
            checkError(e)
            isError(e)
        }
    }

    requestCelebrationDayTypes = async (data: TGetTCelebrationDayTypes) => {
        runInAction(() => this.celebrationDayTypesLoading = true)
        try {
            const res = await instance().close().getTypes(data)
            this.celebrationDayTypes = res.data?.data?.value || []
        } catch (e) {
            checkError(e)
            isError(e)
        }
        runInAction(() => this.celebrationDayTypesLoading = false)
    }

}

export const DirectoryStore = new Directory()