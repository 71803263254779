// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react'
import { ISimpleTreeSelectProps } from "./types";
import clsx from "clsx";
import { TreeSelect } from "antd";
import { ArrowIcon } from "../../../icons/icons";
import { ErrorText } from "../ErrorText/Error";
import { observer } from 'mobx-react-lite';
import { title } from 'process';


const classes = require('./selects.module.scss')
export const SimpleTreeSelect: React.FC<ISimpleTreeSelectProps> = ({
    width = '100%',
    defaultValue,
    isControlled = false,
    placeholder = 'Выберите',
    items,
    onChange,
    showSearch = false,
    size = 'lg',
    mode,
    treeCheckable,
    ...props
}) => {
    const { error, invalid, value } = props;
    const [findedItems, setFindedItems] = useState<any[]>([])
    let a: any = []
    const ref = useRef(null)
    const [searchVal, setSearchVal] = useState<string>('')
    useEffect(() => {
        //setFindedItems([...items])
        restructure()
    }, [items])
    const classNames = clsx(
        classes.simple_select,
        size === 'lg' && classes.lg,
        size === 'md' && classes.md,
        size === 'xs' && classes.xs
    )
    if (mode === 'view') {
        return <span>{items.find(item => item.id === value)?.name || ''}</span>
    }

    const restructure = () => {
        const map = new Map();
        const result = [];
        let arr = [...items]
        arr.forEach(item => {
            map.set(item.id, { value: item.name, title: item.title, id: item.value});
        });
        arr.forEach(item => {
            if (!item?.pId) {
                result.push(map.get(item.id));
            } else {
                const parent = map.get(item.pId);
                if (parent) {
                    if (!Object.keys(parent).includes('children')) {
                        parent.children = []
                    }
                    parent.children.push(map.get(item.id));
                }
            }
        });
        setFindedItems([...result])
    }

    const recursive = (item:any, arr:any[]) => {
        const newArray = [...arr]

        if(!newArray?.find(el => el?.id === item?.id)){
            newArray.push(item)
            if(item?.pId){
                return recursive(items.find(el => item.pId === el.id), newArray)
            }
        }
        return newArray
    }

    const findParentArray = (searchValue: string) => {
        let newArray = [...items]
        let findedElements = []
        newArray.map(item => {if(item?.title.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) {
            findedElements.push(item)
        }} )
        debugger
        const parents = []
        findedElements.map(item => {
            const parentsLocal = recursive(item, [])
            if(parentsLocal?.length){
                parentsLocal.map(item => {
                    if(!parents?.find(el => el.id === item.id)){
                        parents.push(item)
                    }
                })
            }
            // const result = recursive(item, parentsLocal)
            // parents.push(...result)
        })
        setSearchVal(searchValue)
        setFindedItems([...parents])
        
    }

    console.log(findedItems)
    
    return (
        <div style={{ width }}>
            <TreeSelect
                id='antdSelector'
                allowClear
                value={value}
                getPopupContainer={trigger => trigger.parentNode}
                treeData={findedItems}
                autoClearSearchValue={false}
                //treeDataSimpleMode
                // onClick={(val) => console.log(val)}
                // onDropdownVisibleChange={(val) => console.log(val)}
                className={classNames}
                treeCheckable={treeCheckable}
                searchValue={searchVal}
                // getPopupContainer={(val) => console.log(val)}
                popupClassName={classes.dropdown}
                //treeDefaultExpandAll
                // searchValue={searchVal}
                onSearch={(val) => setSearchVal(val)}
                //onSearch={(val) => findParentArray(val)}
                suffixIcon={!props?.loading
                    ? <ArrowIcon style={{ transform: 'rotate(90deg)', width: 6, height: 10 }} />
                    : undefined
                }
                showSearch={showSearch}
                defaultValue={defaultValue}
                style={{
                    width,
                    border: invalid ? '1px solid #DF0E0F' : '',
                    borderRadius: 4,
                }}
                maxTagCount={"responsive"}
                placeholder={placeholder}
                optionfilterprop="children"
                onChange={(value, option) => {
                    items.map(item => {
                        if (Array.isArray(value)) {
                            if (value.find(val => val === item.name)) {
                                a.push(item.id)
                            } else if (value.find(val => val === item.id)) {
                                a.push(item.id)
                            }
                        }
                    })
                    onChange(Array.isArray(value) ? a : value, option)
                }}
                loading={props?.loading}
                ref={ref}
                {...props}
            />
            {/* <ErrorText error={error} /> */}

        </div>
    )
}