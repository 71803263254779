import React, {ChangeEvent, memo} from 'react'
import clsx from "clsx";
import {Input} from "antd";
import {ErrorText} from "../ErrorText/Error";
import {TFormMode} from "./types";

const {TextArea} = Input;

const classes = require('./inputs.module.scss')

interface IBaseTextAreaProps {
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void
    rows?: number
    invalid?: boolean
    error?: string
    mode?: TFormMode
    width?: string | number

    [key: string]: any
}

export const SimpleTextArea: React.FC<IBaseTextAreaProps> = memo(({
                                                                      width,
                                                                      ...props
                                                                  }) => {
    const {error, invalid, rows = 4, value = '', mode} = props;
    const classNames = clsx(classes.root, invalid === true && props.className)
    if (mode === 'view') {
        return value
    }
    return (
        <div style={{marginBottom: error ? 10 : 0}}>
            <TextArea
                style={{
                    width: width ? `${width}px` : '100%',
                    overflow: 'hidden',
                }}
                rows={rows}
                {...props}
                className={classNames}/>
            <ErrorText error={error}/>
        </div>
    )
})

