import {IGetMyInfo, ISetMyInfo, ISetMyInfoLoading} from "./types";
import {GET_MY_INFO, SET_MY_INFO, SET_MY_INFO_LOADING} from "../types";

export const getMyInfo: IGetMyInfo = (payload) => ({
    type: GET_MY_INFO,
    payload
})
export const setMyInfo: ISetMyInfo = (payload) => ({
    type: SET_MY_INFO,
    payload
})
export const setMyInfoLoading: ISetMyInfoLoading = (payload) => ({
    type: SET_MY_INFO_LOADING,
    payload
})