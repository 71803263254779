import React, {useEffect, useState} from 'react'
import {observer} from "mobx-react-lite";
import {DirectoryStore} from "../../../store/directory/DirectoryStore";
import {Fallback} from "../../helpers/Fallback/Fallback";
import {MarksItem} from "./MarksItem";
import {MyPagination} from "../../helpers/MyPagination/MyPagination";

const s = require('./style.module.scss')

interface IMarksProps {

}

export const Marks: React.FC<IMarksProps> = observer(({}) => {

    const {requestMarksList, marksListLoading, marksList} = DirectoryStore
    const PAGE_SIZE = 15
    const [currentPage, setCurrentPage] = useState<number>(1)

    const searchHandler = () => {
        requestMarksList({
            offset: PAGE_SIZE * (currentPage - 1),
            count: PAGE_SIZE
        })
    }

    useEffect(() => {
        searchHandler()
    }, [currentPage])

    return <div>
        {marksListLoading
            ? <Fallback>Загрузка меток</Fallback>
            : marksList && marksList?.list?.length > 0 && marksList?.list?.map((mark) =>
            <MarksItem searchHandler={searchHandler} item={mark}/>)}
        <MyPagination
            onChange={setCurrentPage}
            currentPage={currentPage}
            pageSize={PAGE_SIZE}
            total={marksList?.count || 0}
        />
    </div>
})