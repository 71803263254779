import React, { FC } from "react";
import { Redirect, Route, Switch } from 'react-router-dom'
import { ROUTE_PATH } from "../paths.main";
import { DirectoryContainer } from "../../components/ExtraPage/Directory";
import { GroupsContainer } from "../../components/ExtraPage/Groups/Groups";
import { CategoriesContainer } from "../../components/ExtraPage/Categories/Categories";
import { TemplatesContainer } from "../../components/ExtraPage/Templates/Templates";
import { IudDirectoryItemContainer } from "../../components/ExtraPage/IudDirectoriItem/IudDirectoryItem";
import { RolesContainer } from "../../components/ExtraPage/Roles/Roles";
import { Devices } from "../../components/ExtraPage/Devices/Devices";
import { IudDevice } from "../../components/ExtraPage/Devices/IudDevice";
import { IudMark } from "../../components/ExtraPage/Marks/IudMark";
import { Marks } from "../../components/ExtraPage/Marks/Marks";
import { observer } from "mobx-react-lite";
import { DirectoryStore } from "../../store/directory/DirectoryStore";
import { CelebrationDays } from "../../components/ExtraPage/CelebrationDays/CelebrationDays";
import { IudCelebrationItem } from "../../components/ExtraPage/CelebrationDays/IudCelebrationDay";


export const ExtraPageSwitch: FC = observer(() => {

    const { iudFlag } = DirectoryStore

    return (
        <Switch>
            <Route
                exact
                path={ROUTE_PATH.extraPage.main}
                component={() => <Redirect to={ROUTE_PATH.extraPage.groups} />}
            />
            <Route
                exact
                path={ROUTE_PATH.extraPage.groups}
                component={() => <DirectoryContainer>
                    <GroupsContainer />
                </DirectoryContainer>}
            />
            <Route
                exact
                path={ROUTE_PATH.extraPage.categories}
                component={() => <DirectoryContainer>
                    <CategoriesContainer />
                </DirectoryContainer>}
            />
            <Route
                exact
                path={ROUTE_PATH.extraPage.templates}
                component={() => <DirectoryContainer>
                    <TemplatesContainer />
                </DirectoryContainer>}
            />
            <Route
                exact
                path={ROUTE_PATH.extraPage.roles}
                component={() => <DirectoryContainer>
                    <RolesContainer />
                </DirectoryContainer>}
            />
            <Route
                exact
                path={ROUTE_PATH.extraPage.iudDirectoryItem}
                component={() => <IudDirectoryItemContainer />}
            />
            <Route
                exact
                path={ROUTE_PATH.extraPage.devices}
                component={() => <DirectoryContainer>
                    <Devices />
                </DirectoryContainer>}
            />
            <Route
                exact
                path={ROUTE_PATH.extraPage.iudDevices}
                component={() => <IudDevice flag={iudFlag} />}
            />
            <Route
                exact
                path={ROUTE_PATH.extraPage.iudMarks}
                component={() => <IudMark />}
            />
            <Route
                exact
                path={ROUTE_PATH.extraPage.marks}
                component={() => <DirectoryContainer>
                    <Marks />
                </DirectoryContainer>}
            />
            <Route
                exact
                path={ROUTE_PATH.extraPage.celebrationDays}
                component={() => <DirectoryContainer>
                    <CelebrationDays />
                </DirectoryContainer>}
            />
            <Route
                exact
                path={ROUTE_PATH.extraPage.iudCelebrationDay}
                component={() => <IudCelebrationItem />}
            />

        </Switch>
    )
})