import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {connect} from "react-redux";
import {TRootState} from "../../../redux/store";
import {getDirectoryRoles, setIudFlag} from "../../../redux/reducers/directory/actions/actions";
import {IGetDirectoryRoles, ISetIudFlag} from "../../../redux/reducers/directory/actions/types";
import {Fallback} from "../../helpers/Fallback/Fallback";
import {DirectoryItem} from "../Groups/DirectoryItem/DirectoryItem";
import {IDirectoryRole, IDirectoryRolesList} from "../../../redux/reducers/directory/types.data";
import {MyPagination} from "../../helpers/MyPagination/MyPagination";

interface IRolesProps {
    roleList: IDirectoryRolesList | null
    loading: boolean
    getDirectoryRoles: IGetDirectoryRoles
    setIudFlag: ISetIudFlag
}

const Roles: React.FC<IRolesProps> = observer(({
                                                   loading,
                                                   setIudFlag,
                                                   roleList,
                                                   getDirectoryRoles
                                               }) => {

    const PAGE_SIZE: number = 15

    const [currentPage, setCurrentPage] = useState<number>(1)

    const searchHandler = () => {
        getDirectoryRoles({
            offset: PAGE_SIZE * (currentPage - 1),
            count: PAGE_SIZE
        })
    }

    useEffect(() => {
        searchHandler()
    }, [currentPage])

    const [loadingState, setLoadingState] = useState<boolean>(false)

    return <div>
        {loading || loadingState
            ? <Fallback/>
            : roleList && roleList?.roles?.length > 0 && roleList?.roles?.map((i) =>
            <DirectoryItem searchHandler={searchHandler} setLoadingState={setLoadingState} setIudFlag={setIudFlag}
                           flag={'R'} role={i}/>)}
        <MyPagination
            onChange={setCurrentPage}
            total={0}
            currentPage={currentPage}
            pageSize={PAGE_SIZE}
        />
    </div>

})

const MapStateToProps = (state: TRootState) => ({
    roleList: state.directoryReducer.rolesList,
    loading: state.directoryReducer.loadings.roleLoading
})

export const RolesContainer = connect(MapStateToProps, {getDirectoryRoles, setIudFlag})(Roles)