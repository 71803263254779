import React from "react";
import {observer} from "mobx-react-lite";
import {Dialog, ListItem} from "@material-ui/core";
import {SimpleInput} from "../../../helpers/Inputs/inputs";
import {IModal} from "../../../../store/sidebar/types.data";
import {SimpleSelect} from "../../../helpers/Selects/SimpleSelect";

const s = require('./Filtes.module.scss')

interface IFiltersProps extends IModal {
    flag: 'employees' | 'myApplications' | 'history'
}

export const Filters: React.FC<IFiltersProps> = observer(({
                                                              title,
                                                              flag,
                                                              open,
                                                              closeHandler
                                                          }) => {
    return <Dialog open={open} onClose={closeHandler}>
        <div className={s.container}>
            <div className={s.header}>
                {title}
            </div>
            <div className={s.body}>
                <div className={s.item}>
                    <div className={s.title}>
                        Дата
                    </div>
                    <SimpleSelect
                        items={[]}
                        showSearch
                        onChange={() => {
                        }}
                        width={356}
                        size={'md'}
                    />
                </div>
                <div className={s.item}>
                    <div className={s.title}>
                        Статус
                    </div>
                    <SimpleSelect
                        items={[]}
                        showSearch
                        onChange={() => {
                        }}
                        width={356}
                        size={'md'}
                    />
                </div>
            </div>
            <div className={s.footer}>
                <ListItem
                    button
                    onClick={() => {
                        switch (flag) {
                            case "employees":
                                return
                            case "history":
                                return
                            case "myApplications":
                                return
                        }
                    }}
                    style={{
                        width: 109,
                        height: 37,
                        background: 'black',
                        color: 'white',
                        borderRadius: '5px'
                    }}
                >
                    Применить
                </ListItem>
            </div>
        </div>
    </Dialog>
})