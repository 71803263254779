import {makeAutoObservable, runInAction} from "mobx";

class Launch {
    constructor() {
        makeAutoObservable(this)
    }

    appLoading: boolean = false

    setAppLoading = (loading: boolean) => {
        runInAction(() => {
            this.appLoading = loading
        })
    }
}

export const LaunchStore = new Launch()