import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import {TRootState} from "../../../redux/store";
import {Fallback} from "../../helpers/Fallback/Fallback";
import {observer} from "mobx-react-lite";
import {DevicesItem,} from "./DevicesItem";
import {DirectoryStore} from "../../../store/directory/DirectoryStore";
import {MyPagination} from "../../helpers/MyPagination/MyPagination";
import {ApplicationsStore} from "../../../store/applications/Applications";
import {useKeyPressed} from "../../helpers/useKeyPress/useKeyPress";

const s = require('./style.module.scss')

interface IDevicesProps {

}

export const Devices: React.FC<IDevicesProps> = observer(({}) => {

    //data
    const PAGE_SIZE = 15
    const [currentPage, setCurrentPage] = useState<number>(1)
    const {requestDevicesList, devicesListLoading, devicesList, searchValue, currentPageStore} = DirectoryStore
    const {requestSystems, systemsList, systemsLoading, requestSelPults, pults} = ApplicationsStore

    const reqHandler = () => {
        requestSystems({
            offset: PAGE_SIZE * (currentPage - 1),
            count: PAGE_SIZE,
            searchValue
        }, true)
    }

    //functions
    useEffect(() => {
        reqHandler()
        DirectoryStore.setCurrentPageStore(null)
    }, [currentPage, searchValue])

    useEffect(() => {
        requestSelPults()
    }, [])


    return <div className={s.container}>
        {systemsLoading
            ? <Fallback>Загрузка пультовых устройств</Fallback>
            : pults && pults?.length > 0 && pults?.map((system) =>
            <DevicesItem reqHandler={reqHandler} item={system}/>)}
        <MyPagination
            onChange={setCurrentPage}
            currentPage={currentPageStore || currentPage}
            pageSize={PAGE_SIZE}
            total={pults?.length || 0}
        />
    </div>
})