import {notification} from "antd";

export type TAntdNotificationType = 'success' | 'info' | 'warning' | 'error'
export type TAntdNotificationPayload = {
    type: TAntdNotificationType
    message: string
    description?: string
    duration?: number
}

export function openNotification(payload: TAntdNotificationPayload) {
    const {type, ...props} = payload
    notification[type]({
        ...props
    });
}