import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { Calendar, Badge } from 'antd';
import { observer } from "mobx-react-lite";
import { ApplicationsStore } from "../../../store/applications/Applications";
import { Fallback } from "../../helpers/Fallback/Fallback";
import { formatIsoToDate } from "../../helpers/utils";
import { FullTaskInfo } from "../FullTaskInfo/FullTaskInfo";
import 'moment/locale/ru';
import moment, { Moment } from "moment";
import { useDispatch } from "react-redux";
import { setTaskFilterDates } from "../../../redux/reducers/directory/actions/actions";
import { useHistory } from 'react-router-dom';

const s = require('./style.module.scss');

interface ICalendarComponentProps {
    date: Moment
    setDate: (value: Moment) => void


    [key: string]: any
}

export const CalendarComponent: React.FC<ICalendarComponentProps> = observer(({
    date,
    setDate
}) => {

    const { applications, applicationsLoading, requestApplications } = ApplicationsStore

    const [startDate, setStartDate] = useState<string | null>(null)
    const [endDate, setEndDate] = useState<string | null>(null)
    const [showFullTasksInfo, setShowFullTasksInfo] = useState<boolean>(false)
    const [mode, setMode] = useState<'month' | 'year'>('month')
    let canPush: boolean = true
    const [today, setToday] = useState<any>(new Date())

    const isMobile: boolean = window.outerWidth <= 425


    const [monthTasksList, setMonthList] = useState([])

    useEffect(() => {
        !isMobile && requestApplications({})
    }, [])

    useEffect(() => {
        startDate && endDate && requestApplications({})
    }, [startDate, endDate])


    const dispatch = useDispatch()

    function getListData(value: any) {


        //Распихиваются задачи по дням
        let listData: { type: string, content: string, background: string, color: string, date: string }[] = [];
        let a = formatIsoToDate(value.format())

        applications && applications?.value && applications?.value?.length > 0 &&
            applications?.value.map(i => {
                switch (a) {
                    case formatIsoToDate(i.date.value):
                        listData = [...listData, {
                            type: i.date.backgrund === '#FFF5F5' ? "error" : i.date.backgrund === '#E6C7A6' ? 'warning' : 'success',
                            content: i.name,
                            background: i.date.backgrund,
                            color: i.date.color,
                            date: formatIsoToDate(i.date.value)
                        }]
                        break;
                    default:
                }
            })
        return listData || [];
    }

    const router = useHistory()

    function dateCellRender(value: any) {

        const listData = getListData(value);
        return (
            <ul style={{
                padding: '0',
                margin: '0',
                listStyle: 'none',
                display: 'flex',
                flexDirection: 'column',
                height: '98%'
            }}>
                {monthTasksList.length > 0 ? monthTasksList.map((item: { background: string, color: string, content: string, date: string, type: string }, idx) => {
                    if (idx < 2) {
                        return <li key={item.content} style={{ width: 163 }}>
                            <Badge style={{
                                background: `${item.background}`,
                                color: `${item.color}`,
                                borderRadius: 15,
                                display: 'flex',
                                width: 'auto',
                                paddingLeft: 8,
                                height: 20,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                marginBottom: 4,
                            }} status={undefined} text={item.content}
                            />

                        </li>
                    }
                }
                ) : listData.map((item, idx) => {
                    if (idx < 2) {
                        return <li key={item.content} style={{ width: 163 }}>
                            <div style={{
                                background: `${item.background}`,
                                color: `${item.color}`,
                                borderRadius: 15,
                                display: 'flex',
                                width: 'auto',
                                paddingLeft: 8,
                                height: 20,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                marginBottom: 4
                            }}>
                                {item.content}
                            </div>

                        </li>
                    }
                }
                )}
                {listData.length > 2 && <div className={s.moreBtn}
                    onClick={() => setShowFullTasksInfo(true)}
                >
                    Ещё...
                    {
                        showFullTasksInfo &&
                        <FullTaskInfo
                            day={value.format('dddd')}
                            date={formatIsoToDate(value.format())}
                            closeHandler={() => setShowFullTasksInfo(false)}
                            listData={listData} />
                    }
                </div>
                }
            </ul>
        );
    }

    let a: { status: string, name: string, month: number }[] = []
    let taskMonthsList: number[] = []

    function getMonthData(value: any) {
        let chosenFullDate = moment(value, 'YYYY-MM-DD:mm:ss.SSS[Z]').format('DD.MM.YYYY')
        let futureDate = moment(value).add(1, 'M').format('DD.MM.YYYY')
        /*   requestApplications({
               startDate: chosenFullDate,
               endDate: futureDate
           })
   */
        applications?.value?.map(i => {
            let b = formatIsoToDate(i?.date?.value)[3] !== '0' ? formatIsoToDate(i?.date?.value)[3] + formatIsoToDate(i?.date?.value)[4] : formatIsoToDate(i?.date?.value)[4]
            let c: number = value.month()
            if (value.month() + 1 === +b) {
                if (a.length === 0) {
                    a = [{
                        status: i?.status?.background,
                        name: i?.name,
                        month: +b
                    }]
                } else if (a.find(itemA => itemA.name === i.name) === undefined) {
                    a = [...a, {
                        status: i?.status?.background,
                        name: i?.name,
                        month: +b
                    }]
                }
                if (taskMonthsList.length === 0) {
                    taskMonthsList = [+b]
                } else if (taskMonthsList.find(itemB => itemB === +b) === undefined) {
                    taskMonthsList = [...taskMonthsList, +b]
                }

            }
        })
        return {
            tasks: a,
            month: taskMonthsList
        }
    }

    /*function getMonthData(value: any) {
        let listData: { type: string, content: string, background: string, color: string, date: string }[] = [];
        let a = value.month()
        applications?.value.map(i => {
            let b = formatIsoToDate(i.date.value)[3] !== '0' ? formatIsoToDate(i.date.value)[3] + formatIsoToDate(i.date.value)[4] : formatIsoToDate(i.date.value)[4]
            
            switch (`${a}`) {
                case b:
                    listData = [...listData, {
                        type: i.date.backgrund === '#FFF5F5' ? "error" : i.date.backgrund === '#E6C7A6' ? 'warning' : 'success',
                        content: i.name,
                        background: i.date.backgrund,
                        color: i.date.color,
                        date: formatIsoToDate(i.date.value)
                    }]
                    break

                default:
                    return
            }
        })
        return listData || [];
    }*/

    function monthCellRender(value: any) {
        const num = getMonthData(value);

        return num.tasks && num.month.find(i => i === value.month() + 1) ? (
            <div className="notes-month"
                style={{
                    height: 64,
                    overflow: 'hidden'
                }}
                onClick={() => {
                    dispatch(setTaskFilterDates({
                        startDate: '01.' + `${value.month() + 1}.` + `${value.year()}`,
                        endDate: '01.' + `${value.month() === 11 ? '01' : value.month() + 2}.` + `${value.year()}`
                    }))
                }}
            >
                {num.tasks && num.tasks.length > 0 && num.tasks?.map((i, idx) => {
                    if (i.month === value.month() + 1) {
                        return <section>
                            <span style={{
                                width: 8,
                                height: 8,
                                borderRadius: 25,
                                background: `${i.status}`
                            }} />
                            <span style={{
                                borderRadius: 8,
                                width: '150px !important',
                                background: '#e2dcdc',
                                marginBottom: 2
                            }}>{i?.name}</span>
                        </section>
                    }
                })
                }

            </div>
        ) : null;
    }


    return (
        <div className={s.main_page_wrap}>
            {!isMobile && applicationsLoading
                ? <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%'
                }}>
                    <Fallback />
                </div>
                : <Calendar locale={{
                    lang: {
                        "locale": "ru_RU",
                        "placeholder": "Select date",
                        "rangePlaceholder": ["Start date", "End date"],
                        "today": "Today",
                        "now": "Now",
                        "backToToday": "Back to today",
                        "ok": "OK",
                        "clear": "Clear",
                        "month": "Месяц",
                        "year": "Год",
                        "timeSelect": "Select time",
                        "dateSelect": "Select date",
                        "monthSelect": "Choose a month",
                        "yearSelect": "Choose a year",
                        "decadeSelect": "Choose a decade",
                        "yearFormat": "YYYY",
                        "dateFormat": "M/D/YYYY",
                        "dayFormat": "D",
                        "dateTimeFormat": "M/D/YYYY HH:mm:ss",
                        "monthFormat": "MMMM",
                        "monthBeforeYear": true,
                        "previousMonth": "Previous month (PageUp)",
                        "nextMonth": "Next month (PageDown)",
                        "previousYear": "Last year (Control + left)",
                        "nextYear": "Next year (Control + right)",
                        "previousDecade": "Last decade",
                        "nextDecade": "Next decade",
                        "previousCentury": "Last century",
                        "nextCentury": "Next century"
                    },
                    "timePickerLocale": {
                        "placeholder": "Select time"
                    },
                    "dateFormat": "YYYY-MM-DD",
                    "dateTimeFormat": "YYYY-MM-DD HH:mm:ss",
                    "weekFormat": "YYYY-wo",
                    "monthFormat": "YYYY-MM"
                }}



                    onPanelChange={(date) => {

                        /*setMode('year')*/
                        let chosenDay = moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]').format('DD');
                        let chosenDate = moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]').format('DD.MM.YYYY');
                        let futureDate = moment(date).add(1, 'year').format('DD.MM.YYYY')
                        if (moment(today, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]').format('DD') === chosenDay) {
                            canPush = false
                        } else {
                            canPush = true
                        }

                    }}
                    onChange={(date) => {
                        if (!isMobile) {
                            let chosenFullDate = moment(date, 'YYYY-MM-DD:mm:ss.SSS[Z]').format('DD.MM.YYYY');
                            setStartDate(formatIsoToDate(date.toISOString()))
                            setEndDate(formatIsoToDate(moment(date).add(1, 'month').toISOString()))
                            dispatch(setTaskFilterDates({
                                startDate: chosenFullDate,
                                endDate: chosenFullDate
                            }))
                            setToday(date)
                            if (canPush) {
                                router.push('/applications')
                            }
                            setDate(date)
                        } else {
                            setDate(date)
                        }
                    }}
                    dateCellRender={(value) => !isMobile && dateCellRender(value)}
                    monthCellRender={(value) => !isMobile && monthCellRender(value)}
                    value={date}
                />}
        </div>);
})

/*
                            onSelect={(value) => {
                                let previousDate = moment(today, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]').format('DD.MM.YYYY');
                                let chosenDate = moment(value, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]').format('DD.MM.YYYY');

                                setMode('month')
                            }}


                            mode={mode}
*/