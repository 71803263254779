import React, { useEffect, useState } from 'react';
import { Header } from '../common/Header/Header';
import { HeadTitle } from '../common/HeadTitle/PageTitle';
import CustomCalendar from '../CustomCalendar/CustomCalendar';
import { key } from "../../store/api";
import { CalendarComponent } from "./CalendarComponent/CalendarComponent";
import { MyCalendar } from "./MyCalendar/MyCalendar";
import { ApplicationsStore } from "../../store/applications/Applications";
import { formatIsoToDate } from "../helpers/utils";
import { DateApplications } from './DateApplications/DateApllications';
import moment from 'moment';
import { observer } from 'mobx-react-lite';


const s = require('./style.module.scss');

interface ICalendarComponentProps {

    [key: string]: any
}

export const CalendarPage: React.FC<ICalendarComponentProps> = observer(({ }) => {
    // useEffect(() => {
    //     if (!localStorage.getItem(key.main)) window.location.href = '/authorization'
    // }, [])

    const [searchValue, setSearchValue] = useState<string>('')

    const { requestApplications, date, setDate } = ApplicationsStore

    const isMobile: boolean = window.outerWidth <= 425

    return (
        <div className={s.main_page_wrap}>
            <Header
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                request={() => {
                    let pastDate: Date = new Date()
                    pastDate.setDate(pastDate.getDate() - 15)
                    let futureDate: Date = new Date()
                    futureDate.setDate(futureDate.getDate() + 15)
                    requestApplications({
                        startDate: `${formatIsoToDate(pastDate.toISOString())}`,
                        endDate: `${formatIsoToDate(futureDate.toISOString())}`,
                        searchValue
                    })
                }} />
            <HeadTitle title={'Календарь'} />
            {/* <MyCalendar/>*/}
            <CalendarComponent date={date} setDate={setDate} />
            {/*<CustomCalendar/>*/}
            {isMobile && <DateApplications date={moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]').format('DD.MM.YYYY')} />}
        </div>
    );
})

