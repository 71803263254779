import React, { useEffect, useState } from "react";
import { IModal } from "../../../store/sidebar/types.data";
import { observer } from "mobx-react-lite";
import { Dialog, ListItem } from "@material-ui/core";
import { ModalHeader } from "../../common/Header/ModalHeader/ModalHeader";
import { SimpleSelect } from "../../helpers/Selects/SimpleSelect";
import WorkersPageStore from "../../../store/WorkersPage/WorkersStore";
import { openNotification } from "../../helpers/notification";
import { useStyles } from "../../helpers/Styles/CommonStyles";

const s = require('./VerifyDeleteWorkersModal.module.scss')

interface IAddWorkersToGroupModalProps extends IModal {
    selectedWorkers: { workerId: number }[]
    requestHandler?: (value?: string) => void
}

export const VerifyDeleteWorkersModal: React.FC<IAddWorkersToGroupModalProps> = observer(({
    title,
    open,
    closeHandler,
    selectedWorkers,
    requestHandler
}) => {


    const { requestIudWorker } = WorkersPageStore

    const common = useStyles()

    const submitHandler = () => {
        const deleteUser = (element) => {
            requestIudWorker({
                flag: 'D',
                formviews: [],
                group: {},
                id: element.workerId
            })
        }
        Promise.all(selectedWorkers.map(deleteUser))
        .then(() => {
            openNotification({
                type: 'success',
                message: 'Сотрудники успешно удалены'
            })
            requestHandler && requestHandler()
            closeHandler()
        })
        .catch(() => {
            requestHandler && requestHandler()
            closeHandler()
        })
        console.log(selectedWorkers)

    }

    return <Dialog open={open} classes={{ paper: common.dialogWithoutOverflow }}>
        <ModalHeader title={title} closeHandler={closeHandler} />
        <div className={s.body}>
            <div className={s.item}>
                <ListItem
                    button
                    onClick={submitHandler}
                    style={{
                        background: 'black',
                        color: 'white',
                        borderRadius: 4,
                        display: 'flex',
                        justifyContent: 'center',
                        width: 140,
                        height: 50
                    }}
                >Удалить</ListItem>
                <ListItem
                    button
                    onClick={closeHandler}
                    style={{
                        background: 'white',
                        color: 'black',
                        border: '1px solid black',
                        borderRadius: 4,
                        display: 'flex',
                        justifyContent: 'center',
                        width: 140,
                        height: 50
                    }}
                >Отмена</ListItem>

            </div>
        </div>
        <div className={s.footer}>
            <div className={s.btns}>
                
                
            </div>
        </div>
    </Dialog>
})

