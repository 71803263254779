import { ROUTE_PATH } from "../../routers/paths.main";
import { IInitialPage } from "../../store/extraPage/types";

export const DirectoryNavigationList: IInitialPage[] = [
        {
            id: 0,
            isActive: true,
            name: 'Группы',
            path: ROUTE_PATH.extraPage.groups,
            iudPath: ROUTE_PATH.extraPage.iudDirectoryItem,
            formview: 'directory_groups'
        },
        {
            id: 1,
            isActive: false,
            name: 'Категории',
            path: ROUTE_PATH.extraPage.categories,
            iudPath: ROUTE_PATH.extraPage.iudDirectoryItem,
            formview: 'directory_categories'
        },
        {
            id: 2,
            isActive: false,
            name: 'Шаблоны',
            path: ROUTE_PATH.extraPage.templates,
            iudPath: ROUTE_PATH.newApplication,
            formview: 'directory_samples'
        },
        {
            id: 3,
            isActive: false,
            name: 'Роли',
            path: ROUTE_PATH.extraPage.roles,
            iudPath: ROUTE_PATH.extraPage.iudDirectoryItem,
            formview: 'directory_roles'
        },
        {
            id: 4,
            isActive: false,
            name: 'Пультовые системы',
            path: ROUTE_PATH.extraPage.devices,
            iudPath: ROUTE_PATH.extraPage.iudDevices,
            formview: 'directory_systems'
        },
        {
            id: 5,
            isActive: false,
            name: 'Метки',
            path: ROUTE_PATH.extraPage.marks,
            iudPath: ROUTE_PATH.extraPage.iudMarks,
            formview: 'directory_tags'
        },
        {
            id: 6,
            isActive: false,
            name: 'Праздничные дни',
            path: ROUTE_PATH.extraPage.celebrationDays,
            iudPath: ROUTE_PATH.extraPage.iudCelebrationDay,
            formview: 'celebration_days'
        }
    ]