import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { ApplicationsStore } from '../../../store/applications/Applications'
import { HeadTitle } from '../../common/HeadTitle/PageTitle'
import { NoData } from '../../common/Nodata/NoData'
import { Fallback } from '../../helpers/Fallback/Fallback'
import { Cards } from '../../MyApplication/Cards/Cards'

const s = require('./style.module.scss')

interface IDateApplicationsProps {
    date: string
}

export const DateApplications: React.FC<IDateApplicationsProps> = observer(({ date }) => {

    const pageSize: number = 20
    const { applications, searchValueStore, applicationsLoading, requestApplications } = ApplicationsStore

    useEffect(() => {
        date && requestApplications({
            id: null,
            searchValue: null,
            name: null,
            flag: null,
            filters: null,
            offset: 0,
            count: pageSize,
            startDate: date,
            endDate: date
        })
    }, [date])

    return <div className={s.page}>
        <HeadTitle title={`Заявки на ${date}`} />
        {applicationsLoading ? <Fallback /> : applications?.value && applications?.value?.length > 0
            ? applications?.value?.map((i) => <div
                className={s.item}>
                <Cards
                    key={i.id}
                    item={i} />
            </div>) : <NoData text={`Заявки на ${date}`} />}
    </div>
})