import {
    IClearCheckers,
    IFilterCheckers, IGetHistoryList, IGetTaskActions,
    ISetActiveCount,
    ISetChecked,
    ISetCurrentPage,
    ISetCurrentShowTaskMode, ISetHistoryList, ISetHistoryListLoading, ISetTaskActions, ISetTaskActionsLoading
} from "./types";
import {
    CLEAR_CHECKED,
    FILTER_CHECKED, REQUEST_TASK_ACTIONS, REQUEST_TASK_HISTORY,
    SET_ACTIVE_COUNT,
    SET_CHECKED,
    SET_CURRENT_PAGE,
    SET_CURRENT_SHOW_TASKS_MODE, SET_TASK_ACTIONS, SET_TASK_ACTIONS_LOADING, SET_TASK_HISTORY, SET_TASK_HISTORY_LOADING
} from "../types";

export const setActiveCount: ISetActiveCount = (payload) => ({
    type: SET_ACTIVE_COUNT,
    payload
})

export const setCurrentPage: ISetCurrentPage = (payload) => ({
    type: SET_CURRENT_PAGE,
    payload
})

export const setCheckers: ISetChecked = (payload) => ({
    type: SET_CHECKED,
    payload
})

export const clearCheckers: IClearCheckers = (payload) => ({
    type: CLEAR_CHECKED,
    payload
})

export const filterCheckers: IFilterCheckers = (payload) => ({
    type: FILTER_CHECKED,
    payload
})

export const setCurrentShowTaskMode: ISetCurrentShowTaskMode = (payload) => ({
    type: SET_CURRENT_SHOW_TASKS_MODE,
    payload
})

//История заявок
export const getTaskHistory: IGetHistoryList = (payload) => ({
    type: REQUEST_TASK_HISTORY,
    payload
})

export const setTaskHistoryList: ISetHistoryList = (payload) => ({
    type: SET_TASK_HISTORY,
    payload
})

export const setTaskHistoryListLoading: ISetHistoryListLoading = (payload) => ({
    type: SET_TASK_HISTORY_LOADING,
    payload
})

//События заявки

export const getTaskActions: IGetTaskActions = (payload) => ({
    type: REQUEST_TASK_ACTIONS,
    payload
})

export const setTaskActions: ISetTaskActions = (payload) => ({
    type: SET_TASK_ACTIONS,
    payload
})

export const setTaskActionsLoading: ISetTaskActionsLoading = (payload) => ({
    type: SET_TASK_ACTIONS_LOADING,
    payload
})