import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import {observer} from "mobx-react-lite";

interface ISimpleCheckBoxProps {
    onChange: () => void
    value: boolean
    disabled?: boolean
    onClick?: any
}

const s = require('./classes.module.scss')

export const SimpleCheckbox: React.FC<ISimpleCheckBoxProps> = observer(({
                                                                            value,
                                                                            onChange,
                                                                            disabled,
                                                                            onClick
                                                                        }) => {

    return (
        <div>
            <Checkbox
                onClick={onClick}
                disabled={disabled}
                className={s.checkbox}
                defaultChecked
                checked={value}
                onChange={onChange}
                color="primary"
                inputProps={{'aria-label': 'secondary checkbox'}}
            />
        </div>
    )
        ;
})