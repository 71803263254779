import React, { useState } from "react";
import { IDirectoryCategory, IDirectoryGroup, IDirectoryRole } from "../../../../redux/reducers/directory/types.data";
import { ArrowIcon, CrossIcon, PencilIcon } from "../../../../icons/icons";
import { DeleteModal } from "../../../helpers/Modals/DeleteModal";
import { Link } from "react-router-dom";
import { ROUTE_PATH } from "../../../../routers/paths.main";
import {
    getDirectoryCategories,
    getDirectoryGroups, getDirectoryRoles, getDirectoryTemplates,
    iudCategory, iudDirectoryRoles,
    iudGroup,
    setApplicationId,
    setAppOrTemplateFlag, setDirectoryRoleFullInfo, setSubGroupParentId
} from "../../../../redux/reducers/directory/actions/actions";
import { IApplicationItem } from "../../../../store/applications/types";
import { ISetChosenGroupId, ISetDirectoryRoles, ISetIudFlag } from "../../../../redux/reducers/directory/actions/types";
import { openNotification } from "../../../helpers/notification";
import { useDispatch, useSelector } from "react-redux";
import { observer } from "mobx-react-lite";
import { ApplicationsStore } from "../../../../store/applications/Applications";
import { SubgroupsItem } from "../SubgroupsItem/SubgroupsItem";
import { TRootState } from "../../../../redux/store";
import { formviewAvailabilityFunc } from "../../../helpers/fromviewHelpers/formviewAvailability";
import { PlusOneOutlined } from "@material-ui/icons";
import { PlusIcon } from "../../../../icons/PlusIconSvg";

const s = require('./style.module.scss')

interface IDirectoryItemProps {
    group?: IDirectoryGroup
    category?: IDirectoryCategory
    template?: IApplicationItem
    role?: IDirectoryRole
    setChosenGroupId?: ISetChosenGroupId
    setIudFlag: ISetIudFlag
    //флаг нужен для определения какую страницу отрисовывать, они схожи по дизайну и функционалу
    flag: 'G' | 'C' | 'T' | 'R'
    //G - группы, C - категории, T - шаблоны, R - роли

    setLoadingState?: any

    searchHandler: () => void
}

export const DirectoryItem: React.FC<IDirectoryItemProps> = observer(({
    group,
    flag,
    category,
    template,
    setChosenGroupId,
    setIudFlag,
    role,
    setLoadingState,
    searchHandler
}) => {

    const isMobile: boolean = window.outerWidth <= 425

    const [showSubgroups, setShowSubgroups] = React.useState<boolean>(false)

    const formviews = useSelector((state: TRootState) => state.authReducer.myInfo?.formviews)

    const { requestIudApplication } = ApplicationsStore

    const [deleteMode, setDeleteMode] = useState<boolean>(false)

    const dispatch = useDispatch()

    const checkEditAbility = () => {
        switch (flag) {
            case "R": {
                return formviewAvailabilityFunc(formviews || [], 'edit_roles')
            }
            case "T": {
                return formviewAvailabilityFunc(formviews || [], 'edit_samples')
            }
            case "C": {
                return formviewAvailabilityFunc(formviews || [], 'edit_categories')
            }
            case "G": {
                return formviewAvailabilityFunc(formviews || [], 'edit_groups')
            }
            default:
                return
        }
    }
    const checkDeleteAbility = () => {
        switch (flag) {
            case "R": {
                return formviewAvailabilityFunc(formviews || [], 'delete_roles')
            }
            case "T": {
                return formviewAvailabilityFunc(formviews || [], 'delete_samples')
            }
            case "C": {
                return formviewAvailabilityFunc(formviews || [], 'delete_categories')
            }
            case "G": {
                return formviewAvailabilityFunc(formviews || [], 'delete_groups')
            }
            default:
                return
        }
    }

    return <div>
        <div className={s.row}>
            {flag === 'G' && <div className={s.item} onClick={() => setShowSubgroups(!showSubgroups)}>
                {group?.name}
                {group?.subgroups && group?.subgroups?.length > 0 &&
                    <ArrowIcon
                        style={showSubgroups ? { transform: 'rotate(270deg)', cursor: 'pointer', width: 8 } : {
                            transform: 'rotate(90deg)',
                            cursor: 'pointer',
                            width: 8
                        }} />}
            </div>}
            {flag === 'C' && <div className={s.item} onClick={() => setShowSubgroups(!showSubgroups)}>
                {category?.name}
            </div>}
            {flag === 'T' && <div className={s.item}>
                {template?.name}
                <div className={s.circle} style={{ background: `${template?.status?.background}` }}>
                </div>
            </div>}
            {flag === 'R' && <div className={s.item}>
                {role?.name}
                <div className={s.circle} style={{ background: `${template?.status?.background}` }}>
                </div>
            </div>}
            <div className={s.item}>
                {flag === 'G' && formviewAvailabilityFunc(formviews || [], 'add_groups') &&
                    <Link to={ROUTE_PATH.extraPage.iudDirectoryItem}>
                        <div style={{
                            fontSize: 16,
                            color: '#0275D8',
                            cursor: 'pointer',
                            display: 'flex',
                            marginRight: 8
                        }}
                            onClick={() => {
                                setChosenGroupId && setChosenGroupId(group?.id || 0)
                                setIudFlag('addSubgroup')
                            }}
                        >
                            {isMobile ? <PlusIcon width={14} height={16} color={'#ADB5BD'} /> : 'Добавить группу'}
                        </div>
                    </Link>}
                {checkEditAbility() && <Link
                    to={flag !== 'T' ? ROUTE_PATH.extraPage.iudDirectoryItem : ROUTE_PATH.newApplication}
                    style={{
                        width: 14,
                        height: 16,
                        marginRight: 8
                    }}
                >
                    <PencilIcon
                        onClick={() => {
                            switch (flag) {
                                case "G": {
                                    setIudFlag('editGroup')
                                    setChosenGroupId && setChosenGroupId(group?.id || 0)
                                    dispatch(setSubGroupParentId(null))
                                    return
                                }
                                case "C": {
                                    setIudFlag('editCategory')
                                    setChosenGroupId && setChosenGroupId(category?.id || 0)
                                    return;
                                }
                                case "T": {
                                    dispatch(setAppOrTemplateFlag('editTemplate'))
                                    dispatch(setApplicationId(template ? +template?.id : 0))
                                    return;
                                }
                                case "R": {
                                    setIudFlag('editRole')
                                    setChosenGroupId && setChosenGroupId(role?.id || 0)
                                    role && dispatch(setDirectoryRoleFullInfo(role))
                                    return;
                                }
                                default:
                                    return;
                            }
                        }}
                        style={{ cursor: 'pointer' }} />
                </Link>}
                {checkDeleteAbility() && <CrossIcon onClick={() => {
                    setDeleteMode(true)
                }} style={{ cursor: 'pointer' }} />}
            </div>
        </div>
        <DeleteModal
            open={deleteMode}
            title={flag === 'G' ? 'группы' : flag === 'C' ? 'категории' : flag === 'R' ? 'роли' : 'шаблона'}
            closeHandler={() => setDeleteMode(false)}
            request={() => {
                switch (flag) {
                    case "G": {
                        dispatch(iudGroup({
                            flag: 'D',
                            id: group?.id
                        }, () => {
                            openNotification({
                                type: 'success',
                                message: 'Группа удалена'
                            })
                            searchHandler()
                            setDeleteMode(false)
                        }))
                        return
                    }
                    case "C": {
                        dispatch(iudCategory({
                            id: category?.id,
                            flag: 'D'
                        }, () => {
                            openNotification({
                                type: 'success',
                                message: 'Категория удалена'
                            })
                            searchHandler()
                            setDeleteMode(false)
                        }))
                        return;
                    }
                    case "T": {
                        template?.id &&
                            requestIudApplication({
                                flag: 'D',
                                id: template.id
                            }, () => {
                                openNotification({
                                    type: 'success',
                                    message: 'Шаблон удалён'
                                })
                                searchHandler()
                                setDeleteMode(false)
                            })
                        return;
                    }
                    case "R": {
                        dispatch(iudDirectoryRoles({
                            flag: 'D',
                            id: role?.id
                        }, () => {
                            openNotification({
                                type: 'success',
                                message: 'Роль успешно удалена'
                            })
                        }))
                        setLoadingState && setLoadingState(true)
                        setTimeout(() => {
                            searchHandler()
                            setLoadingState && setLoadingState(false)
                        }, 1000)
                        return;
                    }
                    default:
                        return;
                }
            }}
        />
        {showSubgroups && group?.subgroups
            && group?.subgroups?.length > 0
            && group?.subgroups?.map((i) => <SubgroupsItem
                searchHandler={searchHandler}
                group={group}
                setChosenGroupId={setChosenGroupId}
                setIudFlag={setIudFlag}
                item={i} />)}
    </div>

})