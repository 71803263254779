import {TEnumItem} from "./utils";
import {ISimpleTreeSelectItem} from "./Selects/types";

export const convertDataToSelect = (array?: any[]) => {

    let convertedArray: TEnumItem[] = []
    if (array) convertedArray = array?.map(item => {
        return {
            id: item?.id,
            name: item?.name,
            mnemo: item?.constructor?.mnemo,
            value: item?.id
        }
    })

    return convertedArray

}

export const convertDataToTreeSelect = (data: any[]): ISimpleTreeSelectItem[] => {

    let convertedArray = []

    convertedArray = data.map(item => ({
        ...item,
        value: item.id,
        pId: item.pId,
        title: item.name
    }))
    return convertedArray
}
