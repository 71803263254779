import React, {ChangeEvent, memo} from 'react'
import {Input} from 'antd';
import InputMask from 'react-input-mask'
import clsx from "clsx";
import {TFormMode} from "./types";
import {ErrorText} from "../ErrorText/Error";
import {AcceptIcon, ClearIcon} from "../../../icons/icons";

const classes = require('./inputs.module.scss');

interface IBaseInputProps {
    width?: string | number
    onChange: (e: ChangeEvent<HTMLInputElement>) => void | any
    suffix?: string | React.ReactNode
    invalid?: boolean
    disabled?: boolean
    error?: string
    mode?: TFormMode
    type?: string

    [key: string]: any
}

export const SimpleInput: React.FC<IBaseInputProps> = memo(({
                                                                width = '100%',
                                                                size = 'lg',
                                                                type,
                                                                mode,
                                                                disabled,
                                                                onChange,
                                                                ...props
                                                            }) => {
    const {error, invalid, value = ''} = props;
    const classNames = clsx(
        classes.root,
        (invalid || error) && classes.invalid,
        size === 'lg' && classes.lg,
        size === 'md' && classes.md,
        size === 'xs' && classes.xs,
        value !== '' && classes.completed,
        (props.disabled || props.empty && value === '') && classes.empty,
        props.className)
    // todo suffix переделатьт на свой, и перекрашивать при onBlur
    if (mode === 'view') {
        return value || '-'
    }
    return (
        <div className={classes.input_wrap} style={{width, marginBottom: error ? 18 : 0}}>
            <Input {...props} type={type} disabled={disabled} readOnly={props?.viewOnly} className={classNames}
                   onChange={onChange}/>
            <ErrorText style={{position: 'absolute'}} error={error}/>
            {props.createObject && (error
                ? <div className={classes.error_validation_icon}><ClearIcon/></div>
                : <div className={classes.success_validation_icon}><AcceptIcon/></div>)}
        </div>
    )
})


interface IMaskedInputProps extends IBaseInputProps {
    mask: string
    value: string
    maskProps?: {
        alwaysShowMask?: boolean
        maskPlaceholder?: string
    }
}

export const MaskedInput: React.FC<IMaskedInputProps> = memo(({
                                                                  width,
                                                                  mask,
                                                                  maskProps,
                                                                  ...props
                                                              }) => {
    return (
        <InputMask mask={mask} {...maskProps} value={props.value} onChange={props.onChange}>
            {(inputProps: any) => <SimpleInput {...inputProps} width={width}/>}
        </InputMask>
    )
})