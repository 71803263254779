import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { TRootState } from "../../../redux/store";
import {
    IDirectoryCategory,
    IDirectoryGroup, IDirectoryRole, IDirectoryRolesList
} from "../../../redux/reducers/directory/types.data";
import { HeadTitle } from "../../common/HeadTitle/PageTitle";
import { SimpleInput } from "../../helpers/Inputs/inputs";
import { SimpleTextArea } from "../../helpers/Inputs/Textarea";
import { MultipleSelect } from "../../helpers/Selects/MultipleSelect";
import { TEnumItem } from "../../helpers/utils";
import { ListItem } from "@material-ui/core";
import { SimpleSelect } from "../../helpers/Selects/SimpleSelect";
import { Link, useHistory } from "react-router-dom";
import { ROUTE_PATH } from "../../../routers/paths.main";
import { IInitialPage } from "../../../store/extraPage/types";
import { observer } from "mobx-react-lite";
import WorkersPageStore from "../../../store/WorkersPage/WorkersStore";
import {
    getFormviews,
    getPriority,
    iudCategory,
    iudDirectoryRoles,
    iudGroup,
    setDirectoryRoleFullInfo,
    setDirectoryRoles,
    setRoleGroup,
    clearSubgroupsRoles
} from "../../../redux/reducers/directory/actions/actions";
import {
    IClearSubgroupsRoles,
    IGetFormviews,
    IGetPriority,
    IIudCategory,
    IIudDirectoryRole,
    IIudGroup,
    ISetDirectoryRoleFullInfo,
    ISetDirectoryRoles,
    ISetRoleGroup
} from "../../../redux/reducers/directory/actions/types";
import { openNotification } from "../../helpers/notification";
import { Fallback } from "../../helpers/Fallback/Fallback";
import { FormViewsItem } from "../FormViewsItem/FormViewsItem";
import { SimpleTreeSelect } from "../../helpers/Selects/SimpleTreeSelect";
import { ISimpleTreeSelectItem } from "../../helpers/Selects/types";
import { convertDataToTreeSelect } from "../../helpers/converts";
import { SimpleCheckbox } from "../../common/CheckBox/CheckBox";
import { AreYouSureModal } from "./AreYouSureModal";
import { MultipleTreeSelect } from "../../helpers/Selects/MultipleTreeSelect";

const s = require('./style.module.scss')

interface IIudDirectoryItemProps {
    flag: 'addGroup' | 'editGroup' | 'addCategory' | 'editCategory' | 'addSubgroup' | 'addRole' | 'editRole'
    roleList: IDirectoryRolesList | null
    groupFullInfo: IDirectoryGroup | null
    categoryFullInfo: IDirectoryCategory | null
    roleFullInfo: IDirectoryRole | null
    groupLoading: boolean
    categoryLoading: boolean
    initialPages: IInitialPage[]
    iudGroup: IIudGroup
    groupId: number | null
    groups: IDirectoryGroup[] | null
    categories: IDirectoryCategory[] | null
    getPriority: IGetPriority
    priorityList: TEnumItem[]
    priorityLoading: boolean
    iudCategory: IIudCategory
    parentId: number | null
    formviews: TEnumItem[]
    formviewsLoading: boolean
    getFormviews: IGetFormviews
    iudDirectoryRoles: IIudDirectoryRole
    setDirectoryRoles: ISetDirectoryRoles
    setRoleGroup: ISetRoleGroup
    setDirectoryRoleFullInfo: ISetDirectoryRoleFullInfo
    visiblePages: ISimpleTreeSelectItem[]
    visiblePagesLoading: boolean
    clearSubgroupsRoles: IClearSubgroupsRoles
}

const IudDirectoryItem: React.FC<IIudDirectoryItemProps> = observer(({
    flag,
    categoryFullInfo,
    categoryLoading,
    groupFullInfo,
    groupLoading,
    initialPages,
    iudGroup,
    groupId,
    groups,
    categories,
    getPriority,
    children,
    priorityList,
    priorityLoading,
    iudCategory,
    parentId,
    roleFullInfo,
    formviews,
    formviewsLoading,
    getFormviews,
    roleList,
    iudDirectoryRoles,
    setDirectoryRoles,
    setRoleGroup,
    setDirectoryRoleFullInfo,
    visiblePages,
    visiblePagesLoading,
    clearSubgroupsRoles
}) => {

    const router = useHistory()

    //Общее
    const isMobile: boolean = window.outerWidth <= 425

    const [name, setName] = useState<string>('')

    const [pages, setPages] = useState<(string | number)[] | undefined>([])
    const [actions, setActions] = useState<(string | number)[] | undefined>([])
    const [itemsOfPages, setitemsOfPages] = useState<TEnumItem[] | undefined>(undefined)
    const [itemsOfActions, setitemsActions] = useState<TEnumItem[] | undefined>(undefined)




    //Общее для групп и категорий
    const [description, setDescription] = useState<string>('')
    const [disabledButton, setDisabledButton] = useState<boolean>(false)
    const [hours, setHours] = useState<string>('')
    //State для групп
    const [workersState, setWorkersState] = useState<(string | number)[]>([])
    const [chosenWorkers, setChosenWorkers] = useState<TEnumItem[]>([])
    const [workerList, setWorkerList] = useState<TEnumItem[]>([])
    const { workers, workersWithGroups, requestWorkersWithGroups } = WorkersPageStore
    //State для категорий
    const [priorities, setPriorities] = useState<TEnumItem[]>([])
    const [priorityState, setPriorityState] = useState<number | string>('')
    //State для ролей
    const [roleWorkersTree, setRoleWorkersTree] = useState<ISimpleTreeSelectItem[]>([])
    const [rolesFunctions, setRolesFunctions] = useState<{ id: number }[] | undefined>([])
    const [rolesFunctionsLoading, setRolesFunctionsLoading] = useState<boolean>(false)
    const [rolesGroupId, setRolesGroupId] = useState<number | undefined>(undefined)
    const [rolesGroupName, setRolesGroupName] = useState<string>('')
    const [giveToSubgroups, setGiveToSubgroups] = useState<boolean>(false)

    const [openSureModal, setOpenSureModal] = useState<boolean>(false)


    //Видимые вкладки
    const [visiblePageState, setVisiblePageState] = useState<number[]>([])

    useEffect(() => {
        if (flag === 'editRole' || flag === 'addRole') {
            requestWorkersWithGroups({
                flag: 'groups'
            }, (workersWithGroups: { id: number, pId: number | null, name: string, title: string, value: string | number }[]) => {
                workersWithGroups && workersWithGroups.length > 0 && setRoleWorkersTree(workersWithGroups.map((i) => {
                    return i
                }))
            })
        } else requestWorkersWithGroups({
            flag: 'withoutGroups'
        }, (workersWithGroups: { id: number, pId: number | null, name: string, title: string, value: string | number }[]) => {
            let array: TEnumItem[] = []
            workersWithGroups && workersWithGroups.length > 0 && workersWithGroups.map((i) => {
                array.push({
                    id: i.id,
                    name: i.name
                })
            })
            setWorkerList(array)
        })
        getPriority({
            offset: 0,
            count: 135131315
        }, (list: TEnumItem[]) => {
            setPriorities(list)
        })
        getFormviews({ typeId: 0, contractorId: groupId})
        switch (flag) {
            case "editCategory": {
                if (groupId && categories && categories.length > 0) {
                    setName(categories.find(category => category.id === groupId)?.name || '')
                    setDescription(categories.find(category => category.id === groupId)?.description || '')
                    setPriorityState(categories.find(category => category.id === groupId)?.priority?.id || '')
                    setHours(categories.find(category => category.id === groupId)?.days
                        ? `${categories.find(category => category.id === groupId)?.days}`
                        : '')
                }
                return
            }
            case "editGroup": {
                if (groupId && groups && groups.length > 0) {
                    if (groups.find((i) => i.id === groupId) !== undefined) {
                        setName(groups.find(group => group.id === groupId)?.name || '')
                        setDescription(groups.find(group => group.id === groupId)?.description || '')
                        setWorkersState(groups.find(group => group.id === groupId)?.participants
                            && groups.find(group => group.id === groupId)?.participants
                            && groups.find(group => group.id === groupId)?.participants.map(i => {
                                return i.id
                            }) || [])
                            setActions(groups.find(group => group.id === groupId)?.formviews
                            && groups.find(group => group.id === groupId)?.formviews
                            && groups.find(group => group.id === groupId)?.formviews.map(i => {
                                if (i?.type_id === 1 && i?.is_inherited === false) {
                                    return i.id
                                }
                            }) || [])
                            setPages(groups.find(group => group.id === groupId)?.formviews
                            && groups.find(group => group.id === groupId)?.formviews
                            && groups.find(group => group.id === groupId)?.formviews.map(i => {
                                if (i?.type_id === 2 && i?.is_inherited === false) {
                                    return i.id
                                }
                            }) || [])
                        setChosenWorkers(groups.find(group => group.id === groupId)?.participants || [])
                        
                    } else {
                        groups.map(i => {
                            if (i?.subgroups?.find(item => item?.id === groupId) !== undefined) {
                                setName(i?.subgroups?.find(group => group?.id === groupId)?.name || '')
                                setDescription(i?.subgroups?.find(group => group?.id === groupId)?.description || '')
                                setWorkersState(i?.subgroups?.find(group => group?.id === groupId)?.participants
                                    && i.subgroups?.find(group => group?.id === groupId)?.participants
                                    && i.subgroups?.find(group => group?.id === groupId)?.participants.map(i => {
                                        return i.id
                                    }) || [])

                                    setActions(i?.subgroups?.find(group => group?.id === groupId)?.formviews
                                    && i.subgroups?.find(group => group?.id === groupId)?.formviews
                                    && i.subgroups?.find(group => group?.id === groupId)?.formviews.filter(Boolean).map(i => {
                                        if (i?.type_id === 1 && i?.is_inherited === false) {
                                            return i.id !== undefined && i.id
                                        }
                                    }) || [])
                                    setPages(i?.subgroups?.find(group => group?.id === groupId)?.formviews
                                    && i.subgroups?.find(group => group?.id === groupId)?.formviews
                                    && i.subgroups?.find(group => group?.id === groupId)?.formviews.filter(Boolean).map(i => {
                                        if (i?.type_id === 2 && i?.is_inherited === false) {
                                            return i.id !== undefined && i.id
                                        }
                                    }) || [])
                                    
                                setChosenWorkers(i?.subgroups?.find(group => group?.id === groupId)?.participants || [])
                            }
                        })
                        
                    }
                }
                return;
            }
            case "editRole": {
                setName(roleFullInfo?.name || '')

                setRolesFunctions(roleFullInfo?.groups && roleFullInfo?.groups?.length > 0 && roleFullInfo?.groups[0]?.formviews && roleFullInfo?.groups[0]?.formviews.length > 0 ? roleFullInfo?.groups[0]?.formviews.map((i) => {
                    return {
                        id: +i.id
                    }
                }) : [])
                setRolesGroupId(roleFullInfo?.groups && roleFullInfo?.groups?.length > 0 && +roleFullInfo?.groups[0]?.id || undefined)
                return;
            }
            default:
                return;
        }
    }, [])

    useEffect(() => {
        setRolesFunctionsLoading(true)
        setRolesFunctions([])
        if (rolesGroupId !== undefined) {

            setRolesFunctions(roleFullInfo?.groups?.find(item => item.id === rolesGroupId)?.formviews?.map((i) => {
                return {
                    id: +i.id
                }
            }) || [])
        }
        setTimeout(() => setRolesFunctionsLoading(false), 1000)
    }, [rolesGroupId])


    useEffect(() => {
        giveToSubgroups && setOpenSureModal(true)
    }, [giveToSubgroups])

    useEffect(() => {

        console.log(rolesFunctions)

        let a: TEnumItem[] = []


        formviews.map(i => {
            rolesFunctions && rolesFunctions?.map(item => {
                if (item.id === i.id) {
                    a.push(i)
                }
            })
        })
        if (rolesGroupName !== '') if (rolesGroupId) {

            let parentId: string | number | null | undefined = roleWorkersTree?.find(i => i.id === +rolesGroupId)?.pId

            setRoleGroup({
                id: rolesGroupId,
                name: roleWorkersTree?.find(i => i.id === +rolesGroupId)?.name || '',
                formviews: a,
                giveToSubgroups,
                pId: parentId !== undefined
                    ? parentId
                        ? +parentId
                        : undefined
                    : undefined
            })
        }

    }, [rolesFunctions])
    useEffect(() => {
        //Происходит изменение функций роли
        if (flag === "editRole") {
            setName(roleFullInfo?.name || '')
            roleFullInfo && setVisiblePageState(roleFullInfo?.menuFormviews?.map(i => {
                return i.id
            }) || [])
        }
    }, [roleFullInfo])


    useEffect(() => {
        if (flag === 'addRole') {
            setDirectoryRoleFullInfo({
                name: name,
                groups: [],
            })
        }
    }, [name])

    useEffect(() => {
        const dataForPages = formviews.filter(element => element.type_id === 2).map(element => {
            if (element.is_inherited === true) {
                return {
                    ...element,
                    name: element.name + ' (Наследуется)'
                };
            } else {
                return element;
            }
        })
        setitemsOfPages(dataForPages)
        const dataForActions = formviews.filter(element => element.type_id === 1)
            .map(element => {
                if (element.is_inherited === true) {
                    return (
                        {
                            ...element, name: element.name + ' (Наследуется)'
                        }
                    )
                } else {
                    return ({ ...element })
                }
            })
        setitemsActions(dataForActions)
        
    }, [formviews])

    


    const giveToSubgroupsHandler = () => {
        //изменяем массив формвью для подгрупп данной группы
        if (giveToSubgroups) {
            let allChildren: ISimpleTreeSelectItem[] = roleWorkersTree?.filter(i => i?.pId === rolesGroupId)
            allChildren?.map(child => {
                setRoleGroup({
                    id: child?.id,
                    name: child?.name,
                    pId: child?.pId || undefined,
                    giveToSubgroups,
                    formviews: roleFullInfo?.groups?.find(group => group?.id === child?.pId)?.formviews || []
                })
            })

            roleFullInfo?.groups?.find(group => group?.id === rolesGroupId) !== undefined && setRoleGroup({
                formviews: roleFullInfo?.groups?.find(group => group?.id === rolesGroupId)?.formviews || [],
                id: roleFullInfo?.groups?.find(group => group?.id === rolesGroupId)?.id || 0,
                name: roleFullInfo?.groups?.find(group => group?.id === rolesGroupId)?.name || '',
                pId: roleFullInfo?.groups?.find(group => group?.id === rolesGroupId)?.pId || undefined,
                giveToSubgroups
            })
            setOpenSureModal(false)
        }
        //  Если решать отчищать все функции на снятие чекера  else {
        //     let currentPId: string | number | undefined = roleFullInfo?.groups?.find(group => group?.pId === rolesGroupId)?.id
        //     currentPId && clearSubgroupsRoles(+currentPId)
        // }
    }


    console.log(actions, actions.length)

    return <div className={s.container}>
        <HeadTitle title={(flag === 'editGroup')
            ? groupFullInfo?.name
            : (flag === 'editRole') ? roleFullInfo?.name : categoryFullInfo?.name} />
        <div className={s.container__mainInfo}>
            <div className={s.container__mainInfo__title}>
                Основная информация
            </div>
            <SimpleInput
                width={isMobile ? '100%' : 679}
                value={name}
                size={'md'}
                className={s.container__mainInfo__input}
                onChange={(e) => setName(e.currentTarget.value)}
            />
            {flag !== 'addRole' && flag !== 'editRole' && <SimpleTextArea
                value={description}
                onChange={(e) => setDescription(e.currentTarget.value)}
                width={isMobile ? '100%' : 679}
                rows={8}
            />}
        </div>
        {(flag === 'addGroup') || (flag === 'editGroup') || (flag === 'addSubgroup')
            ? <div className={s.container__workers}>
                 <div className={s.container__workers__title}>
                    Полномочия
                </div>
                <MultipleTreeSelect
                    onChange={setActions}
                    items={itemsOfActions ? convertDataToTreeSelect(itemsOfActions) : []}
                    width={isMobile ? '100%' : 679}
                    values={actions}
                    size={'md'}
                />
                <div className={s.container__workers__title}>
                    Страницы
                </div>
                <MultipleTreeSelect
                    onChange={setPages}
                    items={itemsOfPages ? convertDataToTreeSelect(itemsOfPages) : []}
                    width={isMobile ? '100%' : 679}
                    values={pages}
                    size={'md'}
                />
                <div className={s.container__workers__title}>
                    Участники
                </div>
                <MultipleSelect
                    values={workersState}
                    width={isMobile ? '100%' : 679}
                    items={workerList}
                    onChange={(value: (number | string)[], changedList: TEnumItem[]) => {
                        setWorkersState(value)
                        setChosenWorkers(changedList)
                    }}
                />
                {chosenWorkers && chosenWorkers.length > 0 && <div className={s.container__workers__list}>
                    {chosenWorkers.map(i => <div
                        className={s.container__workers__list__item}>
                        <div>
                            {i.name}
                        </div>
                        <div className={s.container__workers__list__item__deleteBlock} onClick={() => {
                            setWorkersState(workersState.filter(item => item !== i.id))
                            setChosenWorkers(chosenWorkers.filter(chosen => chosen.id !== i.id))
                        }}>
                            Удалить
                        </div>
                    </div>)}
                </div>}
            </div>
            : flag !== 'addRole' && flag !== 'editRole' && <div style={{ marginTop: 31 }}>

                <div className={s.container__workers__title}>
                    Приоритет
                </div>
                <SimpleSelect
                    items={priorities}
                    value={priorityState}
                    onChange={setPriorityState}
                    width={isMobile ? '100%' : 679}
                    loading={priorityLoading}
                />
                <div className={s.container__workers__title}>
                    Дней на исполнение
                </div>
                <SimpleInput
                    value={hours}
                    width={isMobile ? '100%' : 679}
                    onChange={(e) => {
                        if (!isNaN(+e.currentTarget.value)) {
                            setHours(e.currentTarget.value)
                        }
                    }}
                />
            </div>}
        {((flag === 'addRole') || (flag === 'editRole')) && <div className={s.container__role}>
            <div className={s.container__role__selector}>
                <div className={s.container__role__selector__title}>
                    Видимый функционал :
                </div>
                <SimpleTreeSelect
                    multiple
                    treeCheckable
                    placeholder={'Группы'}
                    items={convertDataToTreeSelect(visiblePages) || []}
                    value={visiblePageState}
                    onChange={(value, option) => {

                        setVisiblePageState(value)
                    }}
                    width={isMobile ? '100%' : 679}
                    loading={visiblePagesLoading}
                />
            </div>
            <div className={s.container__role__selector}>
                <div className={s.container__role__selector__title}>
                    Группа :
                </div>
                <SimpleTreeSelect
                    placeholder={'Группы'}
                    items={roleWorkersTree || []}
                    value={rolesGroupId}
                    onChange={(value, option) => {
                        setDirectoryRoles({
                            count: roleList?.count || 0,
                            roles: []
                        })
                        setRolesGroupId(value)
                        setRolesGroupName(option[0])

                    }}
                    width={isMobile ? '100%' : 679}
                    loading={priorityLoading}
                />
            </div>


            <div className={s.container__role__formviews}>
                <div className={s.container__role__formviews__title}>
                    Полномочия :
                </div>
                {formviewsLoading || rolesFunctionsLoading
                    ? <Fallback>Загрузка полномочий...</Fallback>
                    : formviews && formviews.length > 0 && formviews.map((i) => {

                        return <FormViewsItem
                            setRoleGroup={setRoleGroup}
                            isGroupChosen={rolesGroupId !== undefined}
                            rolesGroupId={rolesGroupId}
                            rolesGroupName={rolesGroupName}
                            roleFunctions={rolesFunctions || []}
                            setRoleFunctions={setRolesFunctions}
                            bol={!!rolesFunctions?.find(item => item.id === i.id)}
                            item={i}
                        />
                    })}
            </div>
            {!!roleWorkersTree?.find(i => i?.pId === rolesGroupId) && <div className={s.container__role__selector} style={{ display: 'flex', alignItems: 'center' }}>
                <div className={s.container__role__selector__title}>
                    Передать установленные полномочия всем дочерним группам :
                </div>
                {rolesFunctionsLoading ? <Fallback /> : <SimpleCheckbox
                    value={giveToSubgroups}
                    onChange={() => setGiveToSubgroups(!giveToSubgroups)}
                />}
            </div>}
        </div>}
        <div className={s.container__buttons}>
            <Link to={initialPages.find(page => page.isActive)?.path || ROUTE_PATH.extraPage.groups}>
                <ListItem
                    style={{
                        width: 99,
                        height: 37,
                        background: 'white',
                        border: '1px solid #212529',
                        borderRadius: 5,
                        display: 'flex',
                        justifyContent: 'center',
                        color: 'black'
                    }}
                    button
                    onClick={() => {
                    }}
                >
                    {disabledButton ? 'Выйти' : 'Отмена'}
                </ListItem>
            </Link>
            <ListItem
                style={{
                    width: 104,
                    height: 37,
                    background: 'black',
                    border: '1px solid #212529',
                    borderRadius: 5,
                    color: 'white',
                    marginLeft: 21,
                    display: 'flex',
                    justifyContent: 'center',
                    cursor: disabledButton ? 'not-allowed' : 'pointer'
                }}
                button
                onClick={() => {
                    setDisabledButton(true)
                    switch (flag) {
                        case "addGroup": {
                            iudGroup({
                                flag: 'I',
                                name,
                                description,
                                contractors: chosenWorkers.map(i => {
                                    return { id: +i.id }
                                })
                            }, () => {
                                openNotification({
                                    type: 'success',
                                    message: 'Группа создана!'
                                })
                                router.push('/directory/groups')
                            })
                            return
                        }
                        case "editGroup": {
                            groupId &&
                                iudGroup({
                                    flag: 'U',
                                    name,
                                    description,
                                    contractors: chosenWorkers.map(i => {
                                        return { id: +i.id }
                                    }),
                                    id: groupId,
                                    parent: parentId ? parentId : undefined,
                                    formviews : actions.concat(pages).map((element) => { return { id: element } })
                                }, () => {
                                    openNotification({
                                        type: 'success',
                                        message: 'Данные обновлены!'
                                    })
                                    router.push('/directory/groups')
                                })
                            return
                        }
                        case "addCategory": {
                            iudCategory({
                                flag: 'I',
                                name,
                                description,
                                id_priority: priorityState !== '' ? +priorityState : null,
                                days: hours !== '' ? +hours : undefined
                            }, () => {
                                openNotification({
                                    type: 'success',
                                    message: 'Категория создана'
                                })
                                router.push('/directory/categories')
                            })
                            return;
                        }
                        case "editCategory": {
                            groupId &&
                                iudCategory({
                                    flag: 'U',
                                    name,
                                    description,
                                    id_priority: priorityState !== '' ? +priorityState : null,
                                    id: groupId,
                                    days: hours !== '' ? +hours : undefined
                                }, () => {
                                    openNotification({
                                        type: 'success',
                                        message: 'Данные обновлены'
                                    })
                                    router.push('/directory/categories')
                                })
                            return;
                        }
                        case "addSubgroup": {
                            groupId &&
                                iudGroup({
                                    flag: 'I',
                                    name,
                                    description,
                                    contractors: chosenWorkers.map(i => {
                                        return { id: +i.id }
                                    }),
                                    parent: groupId
                                }, () => {
                                    openNotification({
                                        type: 'success',
                                        message: 'Подгруппа создана!'
                                    })
                                    router.push('/directory/groups')
                                })
                            return;
                        }
                        case "addRole": {
                            iudDirectoryRoles({
                                flag: 'I',
                                name,
                                groups: roleFullInfo?.groups,
                                menuFormviews: visiblePageState?.map(i => {
                                    return {
                                        id: i
                                    }
                                })
                            }, () => {
                                openNotification({
                                    type: 'success',
                                    message: 'Роль успешно добавлена'
                                })
                                router.push('/directory/roles')
                            })
                            return;
                        }
                        case "editRole": {
                            iudDirectoryRoles({
                                flag: 'U',
                                name,
                                id: roleFullInfo?.id,
                                groups: roleFullInfo?.groups,
                                menuFormviews: visiblePageState?.map(i => {
                                    return {
                                        id: i
                                    }
                                })
                            }, () => {
                                openNotification({
                                    type: 'success',
                                    message: 'Роль успешно изменена'
                                })
                                router.push('/directory/roles')

                            })
                            return;
                        }

                        default:
                            return
                    }
                }}
            >
                Сохранить
            </ListItem>
            <AreYouSureModal
                closeHandler={() => setOpenSureModal(false)}
                submitHandler={giveToSubgroupsHandler}
                open={openSureModal}
                title={'Вы уверены в передаче полномчий подгруппам данной группы?'}
            />
        </div>
    </div>
})

const MapStateToProps = (state: TRootState) => ({
    flag: state.directoryReducer.iudFLag,
    groupFullInfo: state.directoryReducer.groupFullInfo,
    categoryFullInfo: state.directoryReducer.categoryFullInfo,
    roleFullInfo: state.directoryReducer.roleFullInfo,
    groupLoading: state.directoryReducer.loadings.groupLoading,
    categoryLoading: state.directoryReducer.loadings.categoryLoading,
    initialPages: state.directoryReducer.initialPages,
    groupId: state.directoryReducer.chosenGroupId,
    groups: state.directoryReducer.groups,
    roleList: state.directoryReducer.rolesList,
    categories: state.directoryReducer.categories,
    priorityList: state.directoryReducer.priorityList,
    priorityLoading: state.directoryReducer.loadings.priorityLoading,
    parentId: state.directoryReducer.subGroupParentId,
    formviews: state.directoryReducer.formviews,
    formviewsLoading: state.directoryReducer.loadings.formviewsLoading,
    visiblePages: state.directoryReducer.visiblePages,
    visiblePagesLoading: state.directoryReducer.visiblePagesLoading,
})

export const IudDirectoryItemContainer = connect(MapStateToProps, {
    iudGroup,
    getPriority,
    iudCategory,
    getFormviews,
    iudDirectoryRoles,
    setDirectoryRoles,
    setRoleGroup,
    setDirectoryRoleFullInfo,
    clearSubgroupsRoles
})(IudDirectoryItem)