export const extraPagePaths = {
    main: '/directory',
    groups: '/directory/groups',
    categories: '/directory/categories',
    templates: '/directory/templates',
    roles: '/directory/roles',
    iudDirectoryItem: '/directory/iud_item',
    devices: '/directory/devices',
    iudDevices: '/directory/iudDevices',
    marks: '/directory/marks',
    iudMarks: '/directory/iudMarks',
    celebrationDays: '/directory/celebrationDays',
    iudCelebrationDay: '/directory/iudCelebrationDay'
}