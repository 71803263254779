import React from 'react';
import clsx from 'clsx';

const s = require('./style.module.scss');

interface IDividingLineComponentProps {
    marginTop?: string,
    [key: string]: any
}

export const DividingLine: React.FC<IDividingLineComponentProps> = ({
                                                                        marginTop,
                                                                    }) => {
    return (
        <div style={{ height: '1px', background: '#CED4DA', marginTop: `${marginTop}`}}></div>
    );
};

