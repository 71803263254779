import React, { useEffect, useState } from "react";
import { HistoryTableCardItem, HistoryTableItem } from "./HistoryTableItem";
import { IHistoryTableItem } from "./types";
import { MyPagination } from "../../helpers/MyPagination/MyPagination";
import { connect } from "react-redux";
import { TRootState } from "../../../redux/store";
import { getTaskHistory } from "../../../redux/reducers/applications/actions/actions";
import { IHistoryList } from "../../../redux/reducers/applications/types.data";
import { IGetHistoryList } from "../../../redux/reducers/applications/actions/types";
import { Simulate } from "react-dom/test-utils";
import { Fallback } from "../../helpers/Fallback/Fallback";

const s = require('./style.module.scss')

interface IHistoryTableProps {
    historyList: IHistoryList | null
    loading: boolean
    getTaskHistory: IGetHistoryList
    taskId: number
}

const HistoryTable: React.FC<IHistoryTableProps> = ({
    loading,
    historyList,
    getTaskHistory,
    taskId
}) => {

    const [currentPage, setCurrentPage] = useState<number>(1)
    const PAGE_SIZE = 10

    const isMobile: boolean = window.outerWidth <= 425

    useEffect(() => {
        getTaskHistory({
            taskId
        })
    }, [currentPage])

    return <div className={s.container}>
        {!isMobile && <div className={s.headers}>
            <div style={{ flex: '0 0 8%' }}>Дата</div>
            <div style={{ flex: '0 0 10%' }}>Автор</div>
            <div style={{ flex: '0 0 10%' }}>Где изменилось</div>
            <div style={{ flex: '0 0 60%' }}>Изменения</div>
        </div>}
        {loading ? <Fallback /> : <div className={s.body}>
            {/*{tableData && tableData.length > 0 && tableData.map((i: IHistoryTableItem) => <HistoryTableItem item={i}/>)}*/}
            {historyList?.history && historyList?.history?.length > 0 && historyList?.history?.map((i: IHistoryTableItem) => {
                if (isMobile) return <HistoryTableCardItem item={i} />
                else return <HistoryTableItem item={i} />
            })}
        </div>}
        <MyPagination
            currentPage={currentPage}
            onChange={setCurrentPage}
            total={historyList?.count || 0}
            pageSize={PAGE_SIZE}
        />
    </div>
}

const MapStateToProps = (state: TRootState) => ({
    historyList: state.applicationsReducer.taskHistory,
    loading: state.applicationsReducer.taskHistoryLoading
})

export const HistoryTableContainer = connect(MapStateToProps, { getTaskHistory })(HistoryTable)